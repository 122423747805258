import React from 'react'
import ReactLoading from "react-loading";
import theme from "../../theme";
import styled from "styled-components"

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: ${(props) => props.background ? props.background : `#000000bd`};
    cursor: not-allowed;
`


const ComponentLoader = ({ loading, children, background }) => {
    return (
        <div style={{ height: `100%`, width: `100%`, position: `relative` }}>
            {
                loading && (
                    <Wrapper background={background}>
                        <ReactLoading type="bars" color={theme.colors.accent} height={100} width={100} />
                    </Wrapper>

                )
            }
            <div style={{ height: `100%` }}>
                {children}
            </div>
        </div>
    )
}


ComponentLoader.propTypes = {};


export default ComponentLoader;