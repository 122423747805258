
export const pointTypes = [ 
    // {label: 'None', value: ''},
    {label: 'Metro stops', value: 'metro'},
    {label: 'Healthy corner stores', value: 'healthy_corner'},
    {label: 'Carshare stops', value: 'carshare'},
    {label: 'Bus stops', value: 'bus'},
    {label: 'Grocery stores', value: 'grocery'},
    {label: 'Homeless services', value: 'homeless_service'},
    {label: 'Human services', value: 'human_services'},
    {label: 'Primary care locations', value: 'primary_care'},
    {label: 'Mental health locations', value: 'mental_health'},
    {label: 'Group meal locations', value: 'group_meal'},
    {label: 'Hospitals', value: 'hospital'},
    {label: 'Child development centers', value: 'child_development'},
    {label: 'Streateries', value: 'streatery'},
    {label: 'High schools', value: 'school_high'},
    {label: 'Elementary schools', value: 'school_elementary'},
    {label: 'Middle schools', value: 'school_middle'},
    {label: 'Libraries', value: 'library'},
    {label: 'Bikeshare locations', value: 'bike_share'},
    {label: 'Community gardens', value: 'community_garden'},
    {label: 'Recreation centers', value: 'rec_center'},
    {label: 'Urban agriculture locations', value: 'urban_agriculture'},
    {label: 'Spray parks', value: 'spray_park'},
    {label: 'Bars', value: 'tavern_liquor'},
    {label: 'Restaurants with liquor licenses', value: 'restaurant_liquor'},
    {label: 'Community pools', value: 'pool_aquatic_center'},
]