import axios from "axios";
import * as Papa from "papaparse";

const serverUrl = "https://geocore-server.fingeocore.com/geodata/blob";
const containerName = "cityxgeo";

const _fetchAccessSignature = async (filename, token) => {
  const options = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  try {
    const requestBody = {
      container_name: containerName,
      blob_path: `${filename}`,
      expiry: 1800,
    };
    let res = await axios.post(serverUrl, requestBody, options);
    return res.data.sas;
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const fetchSecureData = async (filename, token) => {
  if (!token) return "error: no token";

  try {
    let signedUrl = await _fetchAccessSignature(filename, token);
    let storageResponse = await axios.get(signedUrl);
    return storageResponse.data;
  } catch (error) {
    console.log(error);
    return "error: failed fetch";
  }
};

export const fetchSecureCsv = async (filename, token) => {
  if (!token) return "error: no token";

  try {
    let signedUrl = await _fetchAccessSignature(filename, token);
    let json = new Promise((resolve, reject) => {
      Papa.parse(signedUrl, {
        download: true,
        complete: function (results) {
          console.log("csv res: ", results);
          resolve(results);
        },
      });
    });
    let result = await json;
    return result;
  } catch (error) {
    console.log(error);
    return "error: failed fetch";
  }
};
