export const dcAggregate = {
    Population: 673707,
    White: 242728,
    White_pct: '36.0%',
    Black: 328387,
    Black_pct: '48.7',
    Hispanic: 59379,
    Hispanic_pct: '8.8%',
    Asian: 25447,
    Asian_pct: '3.8%',
    Other: 17766,
    Other_pct: '2.6%',
    median_hh_income: 92266,
    pct_poverty: 0.166282334,
    pct_unemployed: 0.081,
}

export const pctAgg = { 
    pct_no_computer: 0.060599405837078645,
    pct_no_broadband: 0.1884915580391061,
    pct_no_computer_american_indian: 0.1359325998,
    pct_no_broadband_american_indian: 0.779932630309091,
    pct_no_computer_asian: 0.013888300567567567,
    pct_no_broadband_asian: 0.9551737010878377,
    pct_no_computer_black: 0.09354184184180786,
    pct_no_broadband_black: 0.7709073787005649,
    pct_no_computer_hawaiian: 0.06666666666666667,
    pct_no_broadband_hawaiian: 0.8,
    pct_no_computer_hispanic: 0.053294874362573096,
    pct_no_broadband_hispanic: 0.8572966643040936,
    pct_no_computer_white: 0.014387325341176474,
    pct_no_broadband_white: 0.9506190913294116,
    pct_commuting_more_45_min: 0.2350505376592179,
    pct_food_stamps_snap: 0.1520213046179776,
    pct_food_stamps_snap_american_indian: 0.19006060606666667,
    pct_food_stamps_snap_asian: 0.0263281765035461,
    pct_food_stamps_snap_black: 0.21525354223728815,
    pct_food_stamps_snap_hawaiian: 0,
    pct_food_stamps_snap_hispanic: 0.10910576739520961,
    pct_food_stamps_snap_white: 0.03665538816568047,
    social_vulnerability_index: 0.5000000000000003,
    pct_no_health_insurance: 0.08885955056179776,
    pct_high_blood_pressure: 0.2993370786516855,
    pct_high_cholesterol: 0.2850898876404494,
    pct_mental_health_issues: 0.13950000000000004,
    pct_obesity: 0.2646516853932585,
    pct_physical_health_issues: 0.09786516853932584,
    pct_poverty: 0.17106607488764045,
    pct_unemployed: 0.08325139664804469,
    pct_no_diploma: 0.07027780706145254,
    pct_low_food_access: 0.4929963217724525,
    pct_grocery_desert: 0.5334815824469274,
    pct_grocery_corner_desert: 0.26947830487150837,
 }

export const countAgg = { 
    child_pop: 158037,
    school_pop: 95979,
    cnt_bus: 3087,
    cnt_carshare: 78,
    cnt_child_development: 468,
    cnt_grocery: 79,
    cnt_group_meal: 49,
    cnt_healthy_corner: 71,
    cnt_homeless_service: 115,
    cnt_hospital: 17,
    cnt_human_services: 1461,
    cnt_mental_health: 13,
    cnt_metro: 40,
    cnt_primary_care: 49,
 }

export const footAgg = { 
    safegraph_devices_202001: 57743,
    safegraph_devices_2021_01: 30690,
    foot_traffic_accounting_2020: 1507,
    foot_traffic_auto_2020: 20338,
    foot_traffic_banking_2020: 33575,
    foot_traffic_bars_2020: 129135,
    foot_traffic_business_services_2020: 47036,
    foot_traffic_childcare_2020: 56105,
    foot_traffic_construction_2020: 23302,
    foot_traffic_education_2020: 173714,
    foot_traffic_entertainment_2020: 593063,
    foot_traffic_government_2020: 33023,
    foot_traffic_grocery_2020: 127161,
    foot_traffic_healthcare_2020: 180506,
    foot_traffic_human_services_2020: 14332,
    foot_traffic_manufacturing_2020: 15864,
    foot_traffic_nursing_care_2020: 14107,
    foot_traffic_personal_care_2020: 32149,
    foot_traffic_real_estate_2020: 148402,
    foot_traffic_religious_2020: 107100,
    foot_traffic_restaurants_2020: 858087,
    foot_traffic_retail_2020: 274721,
    foot_traffic_transportation_2020: 195127,
    foot_traffic_utilities_2020: 7238,
    foot_traffic_accounting_2021: 560,
    foot_traffic_auto_2021: 7249,
    foot_traffic_banking_2021: 5266,
    foot_traffic_bars_2021: 25886,
    foot_traffic_business_services_2021: 13773,
    foot_traffic_childcare_2021: 16779,
    foot_traffic_construction_2021: 8460,
    foot_traffic_education_2021: 36246,
    foot_traffic_entertainment_2021: 196139,
    foot_traffic_government_2021: 14239,
    foot_traffic_grocery_2021: 41227,
    foot_traffic_healthcare_2021: 63450,
    foot_traffic_human_services_2021: 3761,
    foot_traffic_manufacturing_2021: 4564,
    foot_traffic_nursing_care_2021: 4081,
    foot_traffic_personal_care_2021: 10635,
    foot_traffic_real_estate_2021: 39911,
    foot_traffic_religious_2021: 41707,
    foot_traffic_restaurants_2021: 196578,
    foot_traffic_retail_2021: 81960,
    foot_traffic_transportation_2021: 56163,
    foot_traffic_utilities_2021: 2199,
 }

 export const getSingleDcValue = met => {
    if (met === null) return null
    let key = met.value
    if (pctAgg.hasOwnProperty(key)) {
        if (key === 'social_vulnerability_index') {
            return pctAgg[key].toFixed(3)
        } else {
            return (pctAgg[key] * 100).toFixed(1) + '%'
        }
    }
    if (countAgg.hasOwnProperty(key)) {
        return countAgg[key].toLocaleString('en')
    }
    if (footAgg.hasOwnProperty(key)) {
        return footAgg[key].toLocaleString('en')
    }
    return null
}