import React, {useRef, useEffect} from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import uuid from "react-uuid"
import { blueColors } from '../../../helpers/colorInterpolation'
import { select } from 'd3'
import { metric_directionality_dict } from '../metricMappings'



const useStyles = makeStyles((theme) => ({
    container:  {
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    nameContainer:  {
        float: 'left'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        // borderBottom: '1px solid lightgrey',
    },
    metricContainer: {
        display: 'flex',
        borderBottom: '1px solid lightgrey',
        padding: '0 5px'
    },
    cityContainer: {
        width: '100%',
        paddingRight: '3rem',
        flexShrink: 0,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
    },
    outcomeContainer: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
        height: 40,
        backgroundColor: '#eee',
        padding: '0 5px'
    },
    headerContainer: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        fontSize: '1.2rem',
        height: 40,
        color: '#565656',
        alignItems: 'center'
    },
    metricValue: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        height: 40,
    },
    subtitle: {
        fontSize: '1.5em',
        paddingTop: '1em',
        paddingBottom: '1em',
        fontFamily: 'roboto',
        color: '#565656',
    },
    bar: {
        width: 350,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    ranking: {
        width: 150,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    bigContainer: {
        padding: 20
    },
    rankCircle: {
        background: 'blue',
        borderRadius: '50%',
        width: '30%',
        color: 'white',
        padding: 4,
        marginTop: 2,
        marginBottom: 2
    },
    svgContainer: {
        height: 40,
        width: 350,
        overflow: 'visible',
    },
    overviewTitle: {
        textAlign: 'left',
        fontSize: '2em',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'roboto',
        color: 'rgb(1, 42, 95)',
        margin: '1rem 0',
    },
    meanLine: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        borderLeft: '2px dashed rgba(255, 0, 0, 0.5)'
    },
    legend: {
        float: 'right',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center'
    },
    colorLegend: {
        margin: '0 4px',
        width: '60px',
        backgroundImage: `linear-gradient(to right, rgba(${blueColors[0].join(',')}), rgba(${blueColors[blueColors.length-1].join(',')}))`,
        borderRadius: '12px',
        height: '18px',
        display: 'inline-block',
        verticalAlign: 'middle'
    }
}))

const BarChart = ({ percent, mean, absVal }) => {
    const classes = useStyles()
    const svgRef = useRef()

    let myWidth = Math.floor(Number(percent) * 350)
    let meanPos = Math.floor(Number(mean) * 350)
    if (myWidth === 0) myWidth = 1

    if (absVal <= 1) {
        absVal = ((absVal * 100).toFixed(1)).toString()
    } else {
        absVal = absVal.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    useEffect(() => {
        const svg = select(svgRef.current)
    }, [])

    return (
        <div style={{position: 'relative'}}>
            <div className={classes.meanLine} style={{left: meanPos}}></div>
            <svg className={classes.svgContainer} ref={svgRef}>
                {/* <line /> */}
                <line x1="0" x2={myWidth} y1="20" y2="20" stroke="rgb(1, 42, 95)" strokeWidth="20"/>
                <text x={myWidth + 5} y="25" fontFamily="Roboto" fontSize="12" >{absVal}</text>
            </svg>
        </div>
    )
}

const calculateColor = (x) => {
    if (x > 9) x = 9
    let n = Math.floor(Math.random() * 100)
    if (x) n = x * 10
    let c = blueColors[n]
    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
}


const getPeerData = (cities, outcomeMetrics, newData) => {
    let dataMap = {}

    cities.forEach(city => {
        let cityArr = city.split(", ")
        let cityType = cityArr.pop()
        let cityName = cityArr.join(", ")
        dataMap[cityName] = {}
        dataMap[cityName]['fullCity'] = city
        outcomeMetrics.forEach(metric => {
            try {
                let targetRow = newData.find(row => {
                    return row[0] === cityName && row[2] === cityType && row[5] === metric && row[3] === 'Total'
                })
                let dataValue = Number(targetRow[9])

                dataMap[cityName][metric] = dataValue
            } catch (err) {
                // console.log('missing value. ignore.')
            }
        })
    })
    console.log('###DATAMAP!: ', dataMap)
    return dataMap
}

const calculateOutcomeData = (cities, outcome, excludeList, newData, metricMappings) => {
    let outcomeMetrics = metricMappings.outcomes[outcome]
    outcomeMetrics = outcomeMetrics.filter(met => {
        // remove excluded metrics!
        return excludeList.indexOf(met) === -1
    })
    return getPeerData(cities, outcomeMetrics, newData)
}


const PeerRow = ({ city, idx, peer, metVals }) => {
    const classes = useStyles()

    return (
        <div key={uuid()} className={classes.row}>
            <div className={classes.outcomeContainer}>
                <div className={classes.bar}>
                { isNaN(peer.score) && (
                        <div style={{color: 'red'}}>No Data Available</div>
                )}
                { !isNaN(peer.score) && (
                    <BarChart percent={(peer.score).toFixed(1)} mean={(peer.scoreMean).toFixed(1)} absVal={peer.score} />
                )}
                </div>
                <div className={classes.ranking}>
                    <div className={classes.rankCircle} style={{background: calculateColor(idx + 1)}}>{idx + 1}</div>
                </div>
            </div>
            <div className={classes.metricContainer}>
                {metVals.map(met => {
                    return (
                        <div key={uuid()} className={classes.metricValue}>
                            <div className={classes.bar}>
                                { isNaN(peer[met][1]) && (
                                    <div style={{color: 'red'}}>No Data Available</div>
                                )}
                                { !isNaN(peer[met][1]) && (
                                    <BarChart percent={peer[met][1]} mean={peer[met][2]} absVal={peer[met][0]} />
                                )}
                            </div>
                            <div className={classes.ranking}>
                                <div className={classes.rankCircle} style={{background: calculateColor()}}>{Math.floor(Math.random() * 938)}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const HeaderRow = ({ details, metVals }) => {
    const classes = useStyles()

    return (
        <div key={uuid()} className={classes.row}>
            <div style={{borderBottom: '1px solid lightgrey', padding: '0 5px', backgroundColor: '#eee'}} className={classes.headerContainer}>
                <div className={classes.bar}>
                    {details} (outcome)
                </div>
                <div className={classes.ranking}>
                    Rank
                </div>
            </div>
            <div className={classes.metricContainer}>
                {metVals.map(met => {
                    return (
                        <div key={uuid()} >
                            <div className={classes.headerContainer}>
                                <div className={classes.bar} style={{fontWeight: 300}}>
                                    {met} (metric)
                                </div>
                                <div className={classes.ranking} style={{fontWeight: 300}}>
                                    Rank
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const NameRow = ({ state, city, fullCity }) => {
    const classes = useStyles()
    let nameCom = null;

    if (state && city) {

        let cityArr = state.city.split(", ")
        let cityType = cityArr.pop()
        let cityName = cityArr.join(", ")
        let highlightColor = 'auto'
        if (cityName === city) highlightColor = '#0000FF'
        nameCom = <div className={classes.cityContainer} style={{color: highlightColor}}>{fullCity}</div>
    }
    else
        nameCom = <div className={classes.cityContainer}></div>

    return (
        <div key={uuid()} className={classes.row}>
            {nameCom}
        </div>
    )
}


const calcScore = (dataMap) => {
    let metrics = []
    let valueMap = {}
    
    // get list of metrics to score
    for (let city in dataMap) {
        valueMap[city] = {}
        valueMap[city]['fullCity'] = dataMap[city]['fullCity']
        for (let met in dataMap[city]) {
            if (metrics.indexOf(met) == -1 && met !== 'fullCity') {
                metrics.push(met)
            }
        }
    }
    
    //score by each metric
    metrics.forEach(met => {
        // get all vals
        let vals = []
        for (let city in dataMap) {
            vals.push(dataMap[city][met])
        }

        vals = vals.filter(val => val !== undefined)
        // calculate scoring
        let min = Math.min(...vals)
        let max = Math.max(...vals)
        let spread = max - min
        let mean = (vals.reduce((a,b) => {
            return a + b
        }, 0) / vals.length)
        // give each value a relative normalized ranking
        for (let city in dataMap) {
            // [rawValue, relativePeerRanking, peerMean]
            valueMap[city][met] = [dataMap[city][met], (dataMap[city][met] - min) / spread, (mean - min) / spread ]
        }
    })

    //calculate overall peer rank
    let scoreSums = []
    for (let city in valueMap) {
        let scoreSum = 0
        metrics.forEach(met => {
            // DIRECTIONALITY
            if (metric_directionality_dict[met]) {
                if (metric_directionality_dict[met] === -1) {
                    scoreSum += 1 + (valueMap[city][met][1] * -1)
                } else {
                    scoreSum += valueMap[city][met][1]
                }
            } else {
                scoreSum += valueMap[city][met][1]
            }
        })
        scoreSums.push(scoreSum)
        valueMap[city]['score'] = scoreSum
    }
    scoreSums = scoreSums.filter(sum => !isNaN(sum))
    let scoremin = Math.min(...scoreSums)
    let scoremax = Math.max(...scoreSums)
    let scorespread = scoremax - scoremin
    let scoreMean = (scoreSums.reduce((a,b) => {
        return a + b
    }, 0) / scoreSums.length)

    for (let city in valueMap) {
        valueMap[city]['score'] = (valueMap[city]['score'] - scoremin) / scorespread
        valueMap[city]['scoreMean'] = (scoreMean - scoremin) / scorespread
        // should only occur if the scores are tied
        if (isNaN(valueMap[city]['score'])) valueMap[city]['score'] = 1
        if (isNaN(valueMap[city]['scoreMean'])) valueMap[city]['scoreMean'] = 1
    }

    // push into sorted array and return
    let sortedByPeer = []
    for (let city in valueMap) {
        sortedByPeer.push({
            ...valueMap[city],
            city: city
        })
    }
    return sortedByPeer.sort((a, b) => {
        return b['score'] - a['score']
    })
}

const PeerDetails = ({ state, dispatch, newData, metricMappings }) => {
    const classes = useStyles()

    const renderRows = (state) => {
        let cities = [state.city, ...state.comparables]

        // get data 
        let peerData = calculateOutcomeData(cities, state.details, state.excludeList, newData, metricMappings)
        // returns the mapped [rawValue, relativePeerRanking, peerMean]
        let scoredByPeer = calcScore(peerData)
        let metVals = []
        for (let met in scoredByPeer[0]) {
            if (met !== 'score' && met !== 'scoreMean' && met !== 'city' && met !== 'fullCity') {
                if (metVals.indexOf(met) == -1) {
                    metVals.push(met)
                }
            }
        }

        let nameRows = scoredByPeer.map((obj, idx) => <NameRow key={uuid()} state={state} city={obj.city} fullCity={obj.fullCity} />)
        nameRows.unshift(<NameRow key={uuid()} />)
        let rows = scoredByPeer.map((obj, idx) => <PeerRow peer={obj} key={uuid()} city={obj.city} idx={idx} metVals={metVals} />)
        rows.unshift(<HeaderRow key={uuid()} details={state.details} metVals={metVals} />)
        return [nameRows, rows]
    }

    const [nameRows, rows] = renderRows(state)

    return (
        <div className={classes.bigContainer}>
            <div className={classes.legend}>
                <div style={{marginRight: '1rem'}}>
                    <span style={{verticalAlign: 'middle', fontSize: '12px'}}>Best</span>
                    <div className={classes.colorLegend}></div>
                    <span style={{verticalAlign: 'middle', fontSize: '12px'}}>Worst</span>
                </div>
                <div>
                    <div style={{display: 'flex', alignItems: 'center'}}><div style={{borderLeft: '2px dashed rgba(255, 0, 0, 0.5)', marginRight: '4px', height: '24px'}}></div> = mean</div>
                </div>
            </div>
            <h1 className={classes.overviewTitle}>Peer comparison</h1>
            <div className={classes.nameContainer}>
                {nameRows}
            </div>
            <div className={classes.container}>
                {rows}
            </div>
        </div>
    )
}
 
export default PeerDetails