import React, {useState, useContext, useCallback, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Store from "../../context/store";



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
    const classes = useStyles()
    const ctx = useContext(Store)
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [isError, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')


    const handleLogin = () => {
        try {
            fetch('https://geocore-server.fingeocore.com/api-token-auth/', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: user,
                    password: pass
                })
            })
            .then(res => {
                if (res.status === 400) {
                    setError(true)
                    setErrorMsg('error: status 400 - credentials invalid')
                    return 'error: status 400'
                } else {
                    return res.json()
                }
            })
            .then(data => {
                console.log(data)
                if (data === 'error: status 400 - credentials invalid') {
                    setError(true)
                    setErrorMsg(data)
                } else {
                    ctx.setAuthenticated(true)
                    ctx.setApiToken(data.token)
                    localStorage.setItem('auth-token', data.token)
                }
            })
        } catch (err) {
            console.log(err)
        }
        setUser('')
        setPass('')
    }

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Sign in
            </Typography>
            <TextField
                variant="outlined"
                margin="normal"
                value={user}
                onChange={e => setUser(e.target.value)}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                value={pass}
                onChange={e => setPass(e.target.value)}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <Button
                onClick={handleLogin}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Sign In
            </Button>
            {isError && <p>{errorMsg}</p>}
        </div>

        </Container>
    );
}