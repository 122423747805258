export const geoCategories = [ 
    // {highIsWorse: true, invert: true, label: '% lacking computer - American Indian', value: 'pct_no_computer_american_indian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - American Indian', value: 'pct_no_broadband_american_indian'},
    // {highIsWorse: true, invert: true, label: '% lacking computer - Hawaiian', value: 'pct_no_computer_hawaiian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - Hawaiian', value: 'pct_no_broadband_hawaiian'},
    // {highIsWorse: false, invert: true, label: '< %80 population with broadband', value: 'less_than_80_pct_over_200_kbps'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - American Indian', value: 'pct_food_stamps_snap_american_indian'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hawaiian', value: 'pct_food_stamps_snap_hawaiian'},
    // {highIsWorse: false, invert: true, label: 'Child population', value: 'child_pop'},
    // {highIsWorse: false, invert: true, label: 'School age population', value: 'school_pop'},
    // {highIsWorse: false, invert: true, label: 'Bus stops', value: 'cnt_bus'},
    // {highIsWorse: false, invert: true, label: 'Carshares', value: 'cnt_carshare'},
    // {highIsWorse: false, invert: true, label: 'Child development centers', value: 'cnt_child_development'},
    // {highIsWorse: false, invert: true, label: 'Grocery stores', value: 'cnt_grocery'},
    // {highIsWorse: false, invert: true, label: 'Group meal centers', value: 'cnt_group_meal'},
    // {highIsWorse: false, invert: true, label: 'Healthy corner stores', value: 'cnt_healthy_corner'},
    // {highIsWorse: false, invert: true, label: 'Homeless services', value: 'cnt_homeless_service'},
    // {highIsWorse: false, invert: true, label: 'Hospitals', value: 'cnt_hospital'},
    // {highIsWorse: false, invert: true, label: 'Human services', value: 'cnt_human_services'},
    // {highIsWorse: false, invert: true, label: 'Mental health', value: 'cnt_mental_health'},
    // {highIsWorse: false, invert: true, label: 'Metro stops', value: 'cnt_metro'},
    // {highIsWorse: false, invert: true, label: 'Primary care',  value: 'cnt_primary_care',},
    {category: 'no-option', highIsWorse: true, invert: true, label: 'None', value: null},
    {category: 'baseline', highIsWorse: false, invert: false, label: 'Median household income', value: 'median_hh_income'},
    {category: 'baseline', highIsWorse: true, invert: true, label: 'Social vulnerability index', value: 'social_vulnerability_index'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer', value: 'pct_no_computer'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband', value: 'pct_no_broadband'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Asian', value: 'pct_no_computer_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Asian', value: 'pct_no_broadband_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Black', value: 'pct_no_computer_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Black', value: 'pct_no_broadband_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Hispanic', value: 'pct_no_computer_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Hispanic', value: 'pct_no_broadband_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - White', value: 'pct_no_computer_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - White', value: 'pct_no_broadband_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% commute over 45 min.', value: 'pct_commuting_more_45_min'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits', value: 'pct_food_stamps_snap'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Asian', value: 'pct_food_stamps_snap_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Black', value: 'pct_food_stamps_snap_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hispanic', value: 'pct_food_stamps_snap_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - White', value: 'pct_food_stamps_snap_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking health insurance', value: 'pct_no_health_insurance'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high blood pressure', value: 'pct_high_blood_pressure'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high cholesterol', value: 'pct_high_cholesterol'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with mental health issues', value: 'pct_mental_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% obese', value: 'pct_obesity'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with physical health issues', value: 'pct_physical_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% poverty', value: 'pct_poverty'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% unemployed', value: 'pct_unemployed'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% no diploma', value: 'pct_no_diploma'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% low food access', value: 'pct_low_food_access'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% grocery desert', value: 'pct_grocery_desert'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% corner store desert', value: 'pct_grocery_corner_desert'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic accounting_2020', value: 'foot_traffic_accounting_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic auto_2020', value: 'foot_traffic_auto_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic banking_2020', value: 'foot_traffic_banking_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic bars_2020', value: 'foot_traffic_bars_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic business_services_2020', value: 'foot_traffic_business_services_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic childcare_2020', value: 'foot_traffic_childcare_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic construction_2020', value: 'foot_traffic_construction_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic education_2020', value: 'foot_traffic_education_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic entertainment_2020', value: 'foot_traffic_entertainment_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic government_2020', value: 'foot_traffic_government_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic grocery_2020', value: 'foot_traffic_grocery_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic healthcare_2020', value: 'foot_traffic_healthcare_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic human_services_2020', value: 'foot_traffic_human_services_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic manufacturing_2020', value: 'foot_traffic_manufacturing_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic nursing_care_2020', value: 'foot_traffic_nursing_care_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic personal_care_2020', value: 'foot_traffic_personal_care_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic real_estate_2020', value: 'foot_traffic_real_estate_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic religious_2020', value: 'foot_traffic_religious_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic restaurants_2020', value: 'foot_traffic_restaurants_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic retail_2020', value: 'foot_traffic_retail_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic transportation_2020', value: 'foot_traffic_transportation_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic utilities_2020', value: 'foot_traffic_utilities_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic accounting_2021', value: 'foot_traffic_accounting_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic auto_2021', value: 'foot_traffic_auto_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic banking_2021', value: 'foot_traffic_banking_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic bars_2021', value: 'foot_traffic_bars_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic business_services_2021', value: 'foot_traffic_business_services_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic childcare_2021', value: 'foot_traffic_childcare_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic construction_2021', value: 'foot_traffic_construction_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic education_2021', value: 'foot_traffic_education_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic entertainment_2021', value: 'foot_traffic_entertainment_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic government_2021', value: 'foot_traffic_government_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic grocery_2021', value: 'foot_traffic_grocery_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic healthcare_2021', value: 'foot_traffic_healthcare_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic human_services_2021', value: 'foot_traffic_human_services_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic manufacturing_2021', value: 'foot_traffic_manufacturing_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic nursing_care_2021', value: 'foot_traffic_nursing_care_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic personal_care_2021', value: 'foot_traffic_personal_care_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic real_estate_2021', value: 'foot_traffic_real_estate_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic religious_2021', value: 'foot_traffic_religious_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic restaurants_2021', value: 'foot_traffic_restaurants_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic retail_2021', value: 'foot_traffic_retail_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic transportation_2021', value: 'foot_traffic_transportation_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic utilities_2021', value: 'foot_traffic_utilities_2021'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - White', value: 'P0020005'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Black', value: 'P0020006'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Hispanic', value: 'P0020002'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Asian', value: 'P0020008'},
]

export const filteredGeoCategories = [ 
    // {highIsWorse: true, invert: true, label: '% lacking computer - American Indian', value: 'pct_no_computer_american_indian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - American Indian', value: 'pct_no_broadband_american_indian'},
    // {highIsWorse: true, invert: true, label: '% lacking computer - Hawaiian', value: 'pct_no_computer_hawaiian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - Hawaiian', value: 'pct_no_broadband_hawaiian'},
    // {highIsWorse: false, invert: true, label: '< %80 population with broadband', value: 'less_than_80_pct_over_200_kbps'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - American Indian', value: 'pct_food_stamps_snap_american_indian'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hawaiian', value: 'pct_food_stamps_snap_hawaiian'},
    // {highIsWorse: false, invert: true, label: 'Child population', value: 'child_pop'},
    // {highIsWorse: false, invert: true, label: 'School age population', value: 'school_pop'},
    // {highIsWorse: false, invert: true, label: 'Bus stops', value: 'cnt_bus'},
    // {highIsWorse: false, invert: true, label: 'Carshares', value: 'cnt_carshare'},
    // {highIsWorse: false, invert: true, label: 'Child development centers', value: 'cnt_child_development'},
    // {highIsWorse: false, invert: true, label: 'Grocery stores', value: 'cnt_grocery'},
    // {highIsWorse: false, invert: true, label: 'Group meal centers', value: 'cnt_group_meal'},
    // {highIsWorse: false, invert: true, label: 'Healthy corner stores', value: 'cnt_healthy_corner'},
    // {highIsWorse: false, invert: true, label: 'Homeless services', value: 'cnt_homeless_service'},
    // {highIsWorse: false, invert: true, label: 'Hospitals', value: 'cnt_hospital'},
    // {highIsWorse: false, invert: true, label: 'Human services', value: 'cnt_human_services'},
    // {highIsWorse: false, invert: true, label: 'Mental health', value: 'cnt_mental_health'},
    // {highIsWorse: false, invert: true, label: 'Metro stops', value: 'cnt_metro'},
    // {highIsWorse: false, invert: true, label: 'Primary care',  value: 'cnt_primary_care',},
    {category: 'no-option', highIsWorse: true, invert: true, label: 'None', value: null},
    {category: 'baseline', highIsWorse: false, invert: false, label: 'Median household income', value: 'median_hh_income'},
    {category: 'baseline', highIsWorse: true, invert: true, label: 'Social vulnerability index', value: 'social_vulnerability_index'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer', value: 'pct_no_computer'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband', value: 'pct_no_broadband'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Asian', value: 'pct_no_computer_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Asian', value: 'pct_no_broadband_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Black', value: 'pct_no_computer_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Black', value: 'pct_no_broadband_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Hispanic', value: 'pct_no_computer_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Hispanic', value: 'pct_no_broadband_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - White', value: 'pct_no_computer_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - White', value: 'pct_no_broadband_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% commute over 45 min.', value: 'pct_commuting_more_45_min'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits', value: 'pct_food_stamps_snap'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Asian', value: 'pct_food_stamps_snap_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Black', value: 'pct_food_stamps_snap_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hispanic', value: 'pct_food_stamps_snap_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - White', value: 'pct_food_stamps_snap_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking health insurance', value: 'pct_no_health_insurance'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high blood pressure', value: 'pct_high_blood_pressure'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high cholesterol', value: 'pct_high_cholesterol'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with mental health issues', value: 'pct_mental_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% obese', value: 'pct_obesity'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with physical health issues', value: 'pct_physical_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% poverty', value: 'pct_poverty'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% unemployed', value: 'pct_unemployed'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% no diploma', value: 'pct_no_diploma'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% low food access', value: 'pct_low_food_access'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% grocery desert', value: 'pct_grocery_desert'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% corner store desert', value: 'pct_grocery_corner_desert'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic accounting', value: 'foot_traffic_accounting', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic auto', value: 'foot_traffic_auto', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic banking', value: 'foot_traffic_banking', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic bars', value: 'foot_traffic_bars', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic business_services', value: 'foot_traffic_business_services', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic childcare', value: 'foot_traffic_childcare', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic construction', value: 'foot_traffic_construction', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic education', value: 'foot_traffic_education', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic entertainment', value: 'foot_traffic_entertainment', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic government', value: 'foot_traffic_government', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic grocery', value: 'foot_traffic_grocery', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic healthcare', value: 'foot_traffic_healthcare', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic human_services', value: 'foot_traffic_human_services', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic manufacturing', value: 'foot_traffic_manufacturing', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic nursing_care', value: 'foot_traffic_nursing_care', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic personal_care', value: 'foot_traffic_personal_care', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic real_estate', value: 'foot_traffic_real_estate', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic religious', value: 'foot_traffic_religious', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic restaurants', value: 'foot_traffic_restaurants', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic retail', value: 'foot_traffic_retail', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic transportation', value: 'foot_traffic_transportation', years: [2020, 2021]},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic utilities', value: 'foot_traffic_utilities', years: [2020, 2021]},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - White', value: 'P0020005'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Black', value: 'P0020006'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Hispanic', value: 'P0020002'},
    {category: 'demographics', highIsWorse: true, invert: true, label: 'Population - Asian', value: 'P0020008'},
]

export const multiMetricCategories = [
    // {highIsWorse: true, invert: true, label: '% lacking computer - Hawaiian', value: 'pct_no_computer_hawaiian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - Hawaiian', value: 'pct_no_broadband_hawaiian'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - American Indian', value: 'pct_food_stamps_snap_american_indian'},
    // {highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hawaiian', value: 'pct_food_stamps_snap_hawaiian'},
    // {highIsWorse: true, invert: true, label: 'Social vulnerability index', value: 'social_vulnerability_index'},
    // {highIsWorse: true, invert: true, label: '% lacking computer - American Indian', value: 'pct_no_computer_american_indian'},
    // {highIsWorse: true, invert: true, label: '% lacking broadband - American Indian', value: 'pct_no_broadband_american_indian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer', value: 'pct_no_computer'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband', value: 'pct_no_broadband'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Asian', value: 'pct_no_computer_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Asian', value: 'pct_no_broadband_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Black', value: 'pct_no_computer_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Black', value: 'pct_no_broadband_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - Hispanic', value: 'pct_no_computer_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - Hispanic', value: 'pct_no_broadband_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking computer - White', value: 'pct_no_computer_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking broadband - White', value: 'pct_no_broadband_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits', value: 'pct_food_stamps_snap'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Asian', value: 'pct_food_stamps_snap_asian'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Black', value: 'pct_food_stamps_snap_black'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - Hispanic', value: 'pct_food_stamps_snap_hispanic'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% receiving SNAP benefits - White', value: 'pct_food_stamps_snap_white'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% lacking health insurance', value: 'pct_no_health_insurance'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high blood pressure', value: 'pct_high_blood_pressure'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with high cholesterol', value: 'pct_high_cholesterol'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with mental health issues', value: 'pct_mental_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% obese', value: 'pct_obesity'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% with physical health issues', value: 'pct_physical_health_issues'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% poverty', value: 'pct_poverty'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% unemployed', value: 'pct_unemployed'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% no diploma', value: 'pct_no_diploma'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% low food access', value: 'pct_low_food_access'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% grocery desert', value: 'pct_grocery_desert'},
    {category: 'disadvantage', highIsWorse: true, invert: true, label: '% corner store desert', value: 'pct_grocery_corner_desert'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic accounting_2020', value: 'foot_traffic_accounting_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic auto_2020', value: 'foot_traffic_auto_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic banking_2020', value: 'foot_traffic_banking_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic bars_2020', value: 'foot_traffic_bars_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic business_services_2020', value: 'foot_traffic_business_services_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic childcare_2020', value: 'foot_traffic_childcare_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic construction_2020', value: 'foot_traffic_construction_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic education_2020', value: 'foot_traffic_education_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic entertainment_2020', value: 'foot_traffic_entertainment_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic government_2020', value: 'foot_traffic_government_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic grocery_2020', value: 'foot_traffic_grocery_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic healthcare_2020', value: 'foot_traffic_healthcare_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic human_services_2020', value: 'foot_traffic_human_services_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic manufacturing_2020', value: 'foot_traffic_manufacturing_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic nursing_care_2020', value: 'foot_traffic_nursing_care_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic personal_care_2020', value: 'foot_traffic_personal_care_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic real_estate_2020', value: 'foot_traffic_real_estate_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic religious_2020', value: 'foot_traffic_religious_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic restaurants_2020', value: 'foot_traffic_restaurants_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic retail_2020', value: 'foot_traffic_retail_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic transportation_2020', value: 'foot_traffic_transportation_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic utilities_2020', value: 'foot_traffic_utilities_2020'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic accounting_2021', value: 'foot_traffic_accounting_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic auto_2021', value: 'foot_traffic_auto_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic banking_2021', value: 'foot_traffic_banking_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic bars_2021', value: 'foot_traffic_bars_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic business_services_2021', value: 'foot_traffic_business_services_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic childcare_2021', value: 'foot_traffic_childcare_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic construction_2021', value: 'foot_traffic_construction_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic education_2021', value: 'foot_traffic_education_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic entertainment_2021', value: 'foot_traffic_entertainment_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic government_2021', value: 'foot_traffic_government_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic grocery_2021', value: 'foot_traffic_grocery_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic healthcare_2021', value: 'foot_traffic_healthcare_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic human_services_2021', value: 'foot_traffic_human_services_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic manufacturing_2021', value: 'foot_traffic_manufacturing_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic nursing_care_2021', value: 'foot_traffic_nursing_care_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic personal_care_2021', value: 'foot_traffic_personal_care_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic real_estate_2021', value: 'foot_traffic_real_estate_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic religious_2021', value: 'foot_traffic_religious_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic restaurants_2021', value: 'foot_traffic_restaurants_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic retail_2021', value: 'foot_traffic_retail_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic transportation_2021', value: 'foot_traffic_transportation_2021'},
    {category: 'traffic', highIsWorse: false, invert: true, label: 'Foot Traffic utilities_2021', value: 'foot_traffic_utilities_2021'}, 
]

export const getLowHighValues = (selectedMetric, features, aggregate, percentChange) => {
    let low = 9999999999
    let high = -9999999999

    if (features) {
        let metrics = []
        if (aggregate) {
            let metric_filtered = selectedMetric.substr(0, selectedMetric.length-5)
            let years = filteredGeoCategories.find(obj => obj.value === metric_filtered).years
            years.forEach(year => {
                metrics.push(metric_filtered + "_" + year)
            })
        }
        else metrics = [selectedMetric]
        metrics.forEach((metric_val) => {
            if (metric_val.slice(0,3) === 'P00') {
                features.forEach((row) => {
                    try {
                        if (row) {
                            let metric =  (Number(row['properties'][metric_val]) / Number(row['properties']['P0010001']))
                            if (metric !== -1) {
                                if (metric < low) low = metric
                                if (metric > high) high = metric
                            }
                        }
                    } catch (err) {
                        console.log('label err: ', err)
                    }
                })
            } else {
                features.forEach((row) => {
                    try {
                        if (row) {
                            let metric
                            if (percentChange) {
                                const change = Number(row['properties'][metric_val[1]]) - Number(row['properties'][metric_val[0]])
                                metric = (change / Number(row['properties'][metric_val[0]])) * 100
                            } else metric = Number(row['properties'][metric_val])
                            if (metric !== -1) {
                                if (metric < low) low = metric
                                if (metric > high) high = metric
                            }
                        }
                    } catch (err) {
                        console.log('label err: ', err)
                    }
                })
            }
        })
    }

    return [low, high]
}