import React from 'react'
import uuid from "react-uuid"
import { geoCategories } from '../../helpers/geo_categories'
import { dcAggregate, getSingleDcValue } from '../../helpers/dc_aggregate'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
    labelText: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
    },
    labelCol: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
        textAlign: 'center',
        borderBottom: '1px solid lightgrey',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        margin: 0,
        display: 'flex', 
        alignItems: 'center'
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '0px 0px',
        gridTemplateAreas: ". . .",
    },
    gridCol: {
        textAlign: 'center'
    },
    gridStart: {
        textAlign: 'left'
    }
}))


const CalcInfo = ({dcGeo, aggregationLevel, activeTract, selectedMetric, multiMetric, deselectTract, idName}) => {
    const classes = useStyles()
    if (!dcGeo.features) return

    let tract = dcGeo.features.find(el => el.properties[idName] === activeTract)
    if (!tract) return (<></>)
    let prop = tract.properties
    let fullMetric = null
    if (selectedMetric) {
        fullMetric = geoCategories.find(cat => cat.value === selectedMetric)
    }

    let labelName = ''
    if (aggregationLevel === 'wards') labelName = prop['LABEL']
    if (aggregationLevel === 'neighborhoods') labelName = prop['NBH_NAMES'].split(',')[0]
    if (aggregationLevel === 'censusTracts') labelName = `Tract ${prop['TRACT']}`


    const countingCategories = [
      {indent: false, invert: true, label: 'Population', value: 'P0010001', pct: 100},
      {indent: true, invert: true, label: 'Asian', value: 'P0020008'},
      {indent: true, invert: true, label: 'Black', value: 'P0020006'},
      {indent: true, invert: true, label: 'Hispanic / Latino', value: 'P0020002'},
      {indent: true, invert: true, label: 'White', value: 'P0020005'},
    ]
    let sum = 0
    countingCategories.forEach(cat => {
        let labels = ['White', 'Black', 'Asian', 'Hispanic / Latino']
        if (labels.indexOf(cat.label) > -1) {
            sum += prop[cat.value]
            cat.pct = ((prop[cat.value] / prop['P0010001']) * 100).toFixed(1)
        }
    })
    let otherAmt = prop['P0010001'] - sum
    let otherPct = ((otherAmt / prop['P0010001']) * 100).toFixed(1)

    let isNotStandard = selectedMetric && selectedMetric !== 'median_hh_income' && selectedMetric !== 'pct_poverty' && selectedMetric !== 'pct_unemployed' && selectedMetric.slice(0, 4) !== 'P002'

    return(
      <div key={uuid()} style={{padding: '1em',}}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            {/* <p style={{padding: 4, fontSize: '1.5em', fontWeight: 600, marginBottom: '0.25em',}}>{labelName}</p> */}
            <CloseIcon style={{hover: 'cursor'}} onClick={() => deselectTract(prop[idName])} />
        </div>

        <div className={classes.gridContainer} style={{marginBottom: '0.5rem'}}>
            <p className={classes.labelCol} style={{textAlign: 'left', fontSize: '1.25em', color: 'black', margin: 0}}></p>
            <div>
                <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', borderBottom: aggregationLevel === 'censusTracts' ? 'none' : '1px solid lightgrey'}}>{labelName}</p>
                {aggregationLevel === 'censusTracts' && <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', paddingTop: 0}}>({tract.properties['neighborhood'].split(',')[0]})</p>}
            </div>
            <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'flex-end'}}>DC</p>
        </div>
        {countingCategories.map(cat => {
          // if (cat.label === 'Population' && aggregationLevel === 'neighborhoods') return null
          return (
            <div key={uuid()} className={classes.gridContainer} style={{minWidth: 400, padding: 0}}>
              <span className={classes.labelText} style={{paddingLeft: cat.indent ? 15 : 0, paddingTop: 4, paddingBottom: 4}}>{cat.label}: </span>
              <div style={{width: 100, paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, display: 'flex', justifyContent: 'space-between'}}>
                <span>{(parseInt(prop[cat.value])).toLocaleString('en')}</span>
                {cat.label !== 'Population' && <span>({cat.pct}%)</span>}
              </div>
              <div style={{borderLeft: '1px solid lightgrey',}}>
                <div style={{ width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}>
                    {cat.label !== 'Hispanic / Latino' && <span>{(parseInt(dcAggregate[cat.label])).toLocaleString('en')}</span>}
                    {cat.label === 'Hispanic / Latino' && <span>{(parseInt(dcAggregate['Hispanic'])).toLocaleString('en')}</span>}
                    {(cat.label !== 'Population' && cat.label !== 'Hispanic / Latino') && <span>({dcAggregate[cat.label + '_pct']})</span>}
                    {cat.label === 'Hispanic / Latino' && <span>({dcAggregate['Hispanic_pct']})</span>}
                </div>
              </div>
            </div>
          )
        })}
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{marginBottom: '0.5rem', padding: 0, paddingLeft: 15,}}>Other: </span>
            <div style={{width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(otherAmt).toLocaleString('en')}</span><span>({otherPct}%)</span></div>
            <div style={{borderLeft: '1px solid lightgrey', width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(dcAggregate['Other']).toLocaleString('en')}</span><span>({dcAggregate['Other_pct']})</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>Median income: </span>
            <div style={{width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >${(parseInt(prop['median_hh_income'])).toLocaleString('en')}</span></div>
            <div style={{borderLeft: '1px solid lightgrey', width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >${(parseInt(dcAggregate['median_hh_income'])).toLocaleString('en')}</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>% poverty: </span>
            <div style={{width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(prop['pct_poverty'] * 100).toFixed(1)}%</span></div>
            <div style={{borderLeft: '1px solid lightgrey', width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(dcAggregate['pct_poverty'] * 100).toFixed(1)}%</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0rem'}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>% unemployment: </span>
            <div style={{width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(prop['pct_unemployed'] * 100).toFixed(1)}%</span></div>
            <div style={{borderLeft: '1px solid lightgrey', width: 100, padding: 4, paddingLeft: 10, paddingRight: 10, paddingBottom: 15, display: 'flex', justifyContent: 'space-between'}}><span >{(dcAggregate['pct_unemployed'] * 100).toFixed(1)}%</span></div>
        </div>
        {isNotStandard && <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0}}>{fullMetric.label}: </span>
            {selectedMetric.slice(0,3) === 'pct' ? <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(prop[fullMetric.value] * 100).toFixed(1)}%</span></div> : null}
            {(selectedMetric.slice(0,4) === 'foot' || selectedMetric.slice(0,4) === 'P002') ? <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(Math.round(prop[fullMetric.value])).toLocaleString('en')}</span></div> : null}
            {(selectedMetric.slice(0,4) !== 'foot' && selectedMetric.slice(0,4) !== 'P002' && selectedMetric.slice(0,3) !== 'pct') ? <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{(prop[fullMetric.value]).toLocaleString('en')}</span></div> : null}
            <span style={{borderLeft: '1px solid lightgrey', padding: '4px 10px'}}>{getSingleDcValue(fullMetric)}</span>
        </div>}
        {multiMetric && multiMetric.length > 0 && multiMetric.map(met => {
            if (!met.value) return null
            if (met.value === 'pct_poverty' || met.value === 'pct_unemployed' || met.value === 'median_hh_income') return null
            return (
                <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200,padding: 0}}>
                    <span className={classes.labelText} style={{paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>{met.label}: </span>
                    {met.value.slice(0,4) !== 'foot' ? <div style={{width: 100, paddingLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span >{(prop[met.value] * 100).toFixed(1)}%</span></div> : null}
                    {(met.value.slice(0,4) === 'foot' || met.value.slice(0,4) === 'P002') ? <div style={{width: 100, paddingLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span >{(Math.round(prop[met.value])).toLocaleString('en')}</span></div> : null}
                    {(met.value.slice(0,4) !== 'foot' && met.value.slice(0,4) !== 'P002' && met.value.slice(0,3) !== 'pct') ? <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span >{(prop[met.value]).toLocaleString('en')}</span></div> : null}
                    <span style={{borderLeft: '1px solid lightgrey', padding: '4px 10px'}}>{getSingleDcValue(met)}</span>
                </div> 
            )
        })}
        {/* {aggregationLevel === 'censusTracts' && (
            <div key={uuid()} className={classes.gridContainer}  style={{marginTop: '0.5rem',}}>
                <span className={classes.labelText} style={{paddingLeft: 4,}}>Tract: </span>
                <div style={{width: 100, paddingLeft: 10,}}><span style={{textAlign: 'left'}}>{labelName.split(' ')[1]}</span></div>
            </div>
        )} */}
        
      </div>
    )
}

export default CalcInfo