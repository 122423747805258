import React from 'react'
import Select from 'react-select'
import theme from "../../theme";

const colorMap = {
    'no-option': 'grey',
    'baseline': 'green',
    'disadvantage': 'orange',
    'traffic': 'blue',
}


const selectStyles = {
    option: (styles, { isSelected, isDisabled, ...args }) => {
    
        let textColor = args.data.category ? colorMap[args.data.category] : theme.colors.primary
        if (isDisabled) textColor = theme.colors.lightGray

        return {
            ...styles,
            backgroundColor: isDisabled ? theme.colors.lightGray : theme.colors.white,
            // color: isDisabled ? theme.colors.darkGray : theme.colors.primary,
            color: textColor,
            minWidth: `200px`,
            ":hover": {
                backgroundColor: isDisabled ? theme.colors.lightGray : theme.colors.accent,
                color: isDisabled ? theme.colors.black : theme.colors.white,
                cursor: isDisabled ? "not-allowed" : "pointer",
            },
        };
    },
};

const StyledSelect = (props) => {
    // console.log('SELECT PROPS: ', props)
    return (
        <div>
            <Select
                styles={selectStyles}
                {...props}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: theme.colors.primary,
                    },
                })}
            />
        </div>
    )
}


StyledSelect.propTypes = {};


export default StyledSelect;