import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PeerDetails from './PeerDetails'
import EquityDetails from './EquityDetails'
import './animations.scss'

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
    overviewTitle: {
        textAlign: 'center',
        fontSize: '2em',
        // marginTop: '1em',
        paddingTop: '1em',
        paddingBottom: '1em',
        fontFamily: 'roboto',
        color: 'rgb(1, 42, 95)',
    },
    detailHolder: {
        margin: 20,
        overflowX: 'scroll',
        boxShadow: '2px 2px 6px 5px rgba(0,0,0,0.2)',
        background: 'white',
    },
    detailContainer: {
        background: 'linear-gradient(143deg,rgba(223,219,216,0.8) 0%,rgba(233,234,238,0.8) 45%,rgba(217,217,217,0.6917542016806722) 100%)',
        // paddingTop: 20,
        // paddingBottom: 20,
    },
    sectionHeading: {
        color: 'white', background: 'rgb(1, 42, 95)', padding: '1rem', paddingLeft: 50, fontSize: '2em', fontFamily: 'Montserrat'
    }
}))



const Details = ({ state, dispatch, newData, metricMappings }) => {
    const classes = useStyles()

    if (state.city === '') return null
    return (
        <div id='details' className={classes.detailContainer} style={{paddingTop: state.details.length > 0 ? 20 : 0, paddingBottom: state.details.length > 0 ? 20 : 0}}>
            {/* <h1 className={classes.sectionHeading}>Details</h1> */}
            {state.details.length > 0 && <div className={classes.detailHolder} style={{marginTop: 0}}>
                <PeerDetails state={state} dispatch={dispatch} newData={newData} metricMappings={metricMappings} />
            </div>}
            {state.details.length > 0 && <div className={classes.detailHolder} style={{marginBottom: 0}}>
                <EquityDetails state={state} dispatch={dispatch} newData={newData} metricMappings={metricMappings} />
            </div>}
        </div>
    )
}
 
export default Details