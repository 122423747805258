import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { phillyComparables } from './dataHeaders'
import SystemSelect from './SystemSelect';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './Details/animations.scss'

const useStyles = makeStyles((theme) => ({
    innerMenu: {
        // background: 'linear-gradient(143deg,rgba(223,219,216,1) 0%,rgba(233,234,238,1) 45%,rgba(217,217,217,0.7917542016806722) 100%)',
        // margin: 10,
        // minHeight: 420,
        height: 660,
        padding: 10,
        paddingTop: 20,
        overflowY: 'scroll',
        background: 'linear-gradient(143deg,rgba(223,219,216,1) 0%,rgba(233,234,238,1) 45%,rgba(217,217,217,0.7917542016806722) 100%)',
    },
    sub: {
        fontSize: '0.9rem',
        // fontWeight: 'bold',
        // color: '#565656',
        color: '#0000FF',
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#565656',
        marginBottom: '1em',
    },
    subtitle: {
        color: '#0000FF',
        fontSize: 16
    },
    systemContainer: {
        display: 'flex',
        width: 150,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectContainer: {
        fontSize: '14px !important'
    },
    overviewTitle: {
        textAlign: 'left',
        fontSize: '2em',
        // marginTop: '1em',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'roboto',
        color: 'rgb(1, 42, 95)',
    },
    titleIconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1rem', 
        marginBottom: '0.5rem',
    },
    textInput: {
        [`& fieldset`]: {
            borderRadius: 0,
          },
          background: 'white',
    },
    radio: {
        '&$checked': {
          color: 'rgb(1, 42, 95)'
        },
        "& .MuiSvgIcon-root": {
            height: 18,
            width: 18,
            padding: 0,
        },
    },
    checked: {},
    label: {
        fontSize: 14
    },
  }));

const GapMenu = ({ cityNames, state, dispatch, metricMappings}) => {
    const classes = useStyles()


    const filterCities = (citySet) => {
        // filter out the currently selected city
        // once we have a city/msa distinction, filter out the non-relevant city or msa types
        let selectedType = state.city.split(", ").pop()
        let filtered = citySet.filter(city => {
            let type = city.split(", ").pop()
            return city !== state.city && type == selectedType
        })
        return filtered
    }

    return (
        <div className={classes.innerMenu}>
            {/* <h1 className={classes.overviewTitle}>Gap analysis</h1> */}
            <p className={classes.title} >Matrix inputs</p>
            <div className={classes.titleIconContainer} style={{marginTop: 0}}>
                <p className={classes.sub} >Geographic focus</p>
                {/* {state.city?.length === 0 && <ArrowBackIcon className='animate__animated animate__fadeInRight'  style={{marginLeft: 10, color: 'rgb(1, 42, 95)'}} />} */}
            </div>
            <div className={classes.selectContainer}>
                <Autocomplete
                    classes={{input: classes.label}}
                    id="combo-box-demo"
                    options={cityNames}
                    style={{ width: 300 }}
                    onChange={(e, newValue) => { 
                        // let peers = newValue[42].split(', ')
                        let comps = [...state.comparables]
                        if (newValue === null) {
                            newValue = ''
                            comps = []
                        } else {
                            // let cityName = newValue[0]
                            // if (newValue.includes("Philadelphia")) {
                            //     comps = []
                            //     phillyComparables.forEach(comp => {
                            //         let tmpName = comp.split(',')[0]
                            //         let result = data.find(city => city[0].includes(tmpName))
                            //         comps.push(result)
                            //     })
                            // }
                        }
                        dispatch({
                            type: 'selectCityWithComps', 
                            payload: {
                                city: newValue,
                                comparables: comps
                            }
                        })
                        dispatch({type: 'selectComparable', payload: []})
                    }}
                    renderInput={(params) => <TextField {...params} className={classes.textInput} placeholder='Select...' size="small" variant="outlined" />}
                />
            </div>
            <div className={classes.titleIconContainer}>
                <p className={classes.sub}>Peer set</p>
                {/* <ArrowBackIcon /> */}
            </div>
            <div className={classes.selectContainer}>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={filterCities(cityNames)}
                    style={{ width: 300 }}
                    value={state.comparables}
                    onChange={(e, newValue) => dispatch({type: 'selectComparable', payload: newValue})}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.textInput}
                        variant="outlined"
                        placeholder="Select..."
                        size="small"
                    />
                    )}
                />
            </div>
            <div className={classes.titleIconContainer}>
                <p className={classes.sub}>Inequity ratio</p>
                {/* {(state.city.length > 0 && state.metric === '') &&  <ArrowBackIcon className='animate__animated animate__fadeInRight'  style={{marginLeft: 10, color: 'rgb(1, 42, 95)'}}  />} */}
            </div>
            <div className={classes.selectContainer}>
                <RadioGroup aria-label="demo" name="demo1"  onChange={(e, newValue) => dispatch({type: 'selectMetric', payload: newValue})}>
                    <FormControlLabel classes={{label: classes.label}} value="White / Black" control={<Radio  classes={{root: classes.radio, checked: classes.checked}} />} label="White / Black" />
                    <FormControlLabel classes={{label: classes.label}} value="White / Hispanic" control={<Radio classes={{root: classes.radio, checked: classes.checked}} />} label="White / Hispanic" />
                    {/* <FormControlLabel classes={{label: classes.label}} value="White / Asian" control={<Radio classes={{root: classes.radio, checked: classes.checked}} />} label="White / Asian" /> */}
                </RadioGroup>
            </div>
            <p className={classes.sub} style={{marginTop: '8px'}}>Metrics</p>
            <div className={classes.selectContainer}>
                <SystemSelect state={state} dispatch={dispatch} metricMappings={metricMappings} />
            </div>

        </div>
    )
}
 
export default GapMenu