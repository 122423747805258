import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"


const useViewStyles = makeStyles({
  root: {},
  label: {
    fontSize: 14
  },
})

const generateMappings = (metricMappings) => {
  if (!metricMappings.systems) return {}
  const mappings = {}
  Object.keys(metricMappings.metrics).forEach((metric) => {
    mappings[metric] = [[], []]
  })
  Object.keys(metricMappings.outcomes).forEach((outcome) => {
    let childParentArr = [[], []] // [[...children], [...parents]]
    metricMappings.outcomes[outcome].forEach((metric) => {
      childParentArr[0].push(metric)
      mappings[metric][1].push(outcome)
    })
    mappings[outcome] = childParentArr
  })
  Object.keys(metricMappings.systems).forEach((system) => {
    let childParentArr = [[], []] // [[...children], [...parents]]
    let nestedChildren = []
    metricMappings.systems[system].forEach((outcome) => {
      childParentArr[0].push(outcome)
      mappings[outcome][1].push(system)
      nestedChildren = nestedChildren.concat(mappings[outcome][0])
    })
    childParentArr[0] = childParentArr[0].concat(nestedChildren)
    mappings[system] = childParentArr
  })
  Object.keys(metricMappings.outcomes).forEach((outcome) => {
    metricMappings.outcomes[outcome].forEach((metric) => {
      mappings[metric][1] = mappings[metric][1].concat(mappings[outcome][1])
    })
  })
  console.log(mappings)
  return mappings
}

const generateLabel = (nodeId, excluded, setExcluded, classes, mappings) => {
  return (
    <FormControlLabel classes={{label: classes.label}}
      control={
        <Checkbox
          color="primary"
          checked={
            !excluded.includes(nodeId)
          }
          indeterminate={
            !excluded.includes(nodeId) &&
            mappings[nodeId][0].filter(child => excluded.includes(child)).length > 0
          }
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            if (e.target.checked) {
              setExcluded(excluded.filter(id => !mappings[nodeId][0].includes(id) && !mappings[nodeId][1].includes(id) && id !== nodeId))
            }
            else {
              let newExcluded = excluded.concat(mappings[nodeId][0], [nodeId])
              mappings[nodeId][1].forEach((parent) => {
                if (mappings[parent][0].every(child => newExcluded.includes(child)))
                newExcluded.push(parent)
              });
              setExcluded(newExcluded);
            }
          }}
        />
      }
      onClick={(e) => e.stopPropagation()}
      label={nodeId}
      key={nodeId}
    />
  )
}

const SystemSelect = ({state, dispatch, metricMappings}) => {
  const classesView = useViewStyles()

  const [excluded, setExcluded] = React.useState([])

  const mappings = generateMappings(metricMappings)

  useEffect(() => {
    dispatch({
      type: 'updateExcludeList', 
      payload: excluded
    })
  }, [dispatch, excluded]);

  return (
    <TreeView
      classes={classesView}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {metricMappings.systems && Object.keys(metricMappings.systems).map((system) => (
        <TreeItem key={system+'-s'} nodeId={system+'-s'} label={generateLabel(system, excluded, setExcluded, classesView, mappings)}>
          {metricMappings.systems[system].map((outcome) => (
            <TreeItem key={outcome+'-o'} nodeId={outcome+'-o'} label={generateLabel(outcome, excluded, setExcluded, classesView, mappings)}>
              {metricMappings.outcomes[outcome].map((metric) => (
                <TreeItem key={metric+'-m'} nodeId={metric+'-m'} label={generateLabel(metric, excluded, setExcluded, classesView, mappings)} />
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeView>
  )
}

export default SystemSelect