export const getNormalizedSingleVals = (met, maplen) => {
    let res = {...met}
    for (let i = 0; i < maplen; i++) {
      let high = -9999999999
      let low = 9999999999
      for (let prop in met) {
        if (met[prop][i] !== -1) {
          if (Number(met[prop][i]) > high) high = Number(met[prop][i])
          if (Number(met[prop][i]) < low) low = Number(met[prop][i])
        }
      }
      let spread = Math.abs(high - low)
      for (let prop in met) {
        if (met[prop[i]] !== -1) {
          res[prop][i] = (met[prop][i] - low) / spread
        }
        if (met[prop][i] < 0) {
          res[prop][i] = -1
        }
      }
    }
    return res
  }

  export const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length

  export const getAverageMultimetricVals = (met) => {
    let response = {}
    for (let prop in met) {
      // flag if there is a missing value by assigning -1
      if (met[prop].includes(-1)) {
        response[prop] = -1
      } else {
        response[prop] = arrAvg(met[prop])
      }
    }
    return response
  }

  export const getLowHigh = (averageValueMap) => {
    let high = -999999999999
    let low = 99999999999
    for (let prop in averageValueMap) {
      if (averageValueMap[prop] !== -1) {
        if (averageValueMap[prop] > high) high = averageValueMap[prop]
        if (averageValueMap[prop] < low) low = averageValueMap[prop]
      }
    }
    return [low, high]
  }

  export const getNormalValues = (averageValueMap, lowVal, spreadVal) => {
    let response = {}
    for (let prop in averageValueMap) {
      response[prop] = (averageValueMap[prop] - lowVal) / spreadVal
    }
    return response
  }

  export const setFeatureProperties = (normalizedValueMap, mapRef, aocVal, aocState, labelName) => {
    let newAocTracts = []
    for (let prop in normalizedValueMap) {
      let colorIndex = Math.round(normalizedValueMap[prop] * 100)
      let rowFeature = mapRef.current.data.getFeatureById(prop)

      if (rowFeature) {
        if (normalizedValueMap[prop] !== -1) {
          // negative aocVal indicates that we want the top perforing % - needs to be absolute value though to calculat the correct %
          let threshold = 1 - (Math.abs(aocVal) / 100)
          if (aocState === false) threshold = 999999
          rowFeature.setProperty('activeMetricIndex', colorIndex)
          if (aocVal > 0) {
            if (normalizedValueMap[prop] >= threshold)  {
              newAocTracts.push(rowFeature.getProperty(labelName))
              rowFeature.setProperty('areaOfConcern', 1)
            } else {
              rowFeature.setProperty('areaOfConcern', 0)
            }
          } else {
            let threshold = Math.abs(aocVal) / 100
            if (aocState === false) threshold = -999999
            if (normalizedValueMap[prop] <= threshold)  {
              newAocTracts.push(rowFeature.getProperty(labelName))
              rowFeature.setProperty('areaOfConcern', 1)
            } else {
              rowFeature.setProperty('areaOfConcern', 0)
            }
          }
        } else {
          rowFeature.setProperty('activeMetricIndex', -1)
          rowFeature.setProperty('areaOfConcern', 0)
        }
      } else {
        console.log('missing row feature??', prop)
      }
    }
    return newAocTracts
  }