import React, {useRef, useEffect} from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import uuid from "react-uuid"
import { blueColors } from '../../../helpers/colorInterpolation'
import { select } from 'd3'
import { metric_directionality_dict } from '../metricMappings'



const useStyles = makeStyles((theme) => ({
    container:  {
        overflowX: 'scroll',
        overflowY: 'hidden',
    },
    nameContainer:  {
        float: 'left'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        // borderBottom: '1px solid lightgrey',
    },
    metricContainer: {
        display: 'flex',
        borderBottom: '1px solid lightgrey',
        padding: '0 5px'
    },
    cityContainer: {
        width: '100%',
        paddingRight: '3rem',
        flexShrink: 0,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
    },
    outcomeContainer: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
        height: 40,
        backgroundColor: '#eee',
        padding: '0 5px'
    },
    headerContainer: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        fontSize: '1.2rem',
        height: 40,
        color: '#565656',
        alignItems: 'center'
    },
    metricValue: {
        width: 500,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        height: 40,
    },
    subtitle: {
        fontSize: '1.5em',
        paddingTop: '1em',
        paddingBottom: '1em',
        fontFamily: 'roboto',
        color: '#565656',
    },
    bar: {
        width: '70%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    ranking: {
        width: '30%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    bigContainer: {
        padding: 20
    },
    rankCircle: {
        background: 'blue',
        borderRadius: '50%',
        width: '30%',
        color: 'white',
        padding: 4,
        marginTop: 2,
        marginBottom: 2
    },
    svgContainer: {
        height: 40,
        width: 350,
        overflow: 'visible',
    },
    overviewTitle: {
        textAlign: 'left',
        fontSize: '2em',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'roboto',
        color: 'rgb(1, 42, 95)',
        margin: '1rem 0',
    },
    spanCircle: {
        height: 10,
        width: 10,
        borderRadius: '50%',
        background: '#FFC0CB',
        marginRight: 8
    },
    legend: {
        float: 'right',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center'
    },
    colorLegend: {
        margin: '0 4px',
        width: '60px',
        backgroundImage: `linear-gradient(to right, rgba(${blueColors[0].join(',')}), rgba(${blueColors[blueColors.length-1].join(',')}))`,
        borderRadius: '12px',
        height: '18px',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    errDiv: {
        color: 'red',
        width: '350px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
    },
    meanLine: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        borderLeft: '2px dashed rgba(255, 0, 0, 0.5)'
    },
}))

const BarChart = ({ meanInequity, absVal, sortMaxInequity }) => {
    const classes = useStyles()
    const svgRef = useRef()

    let myWidth = Math.floor(Number(absVal / sortMaxInequity) * 350)
    let meanPos = Math.floor(Number(meanInequity / sortMaxInequity) * 350)
    if (myWidth === 0) myWidth = 1

    useEffect(() => {
        const svg = select(svgRef.current)
    }, [])

    return (
        <div style={{position: 'relative'}}>
            <div className={classes.meanLine} style={{left: meanPos}}></div>
            <svg className={classes.svgContainer} ref={svgRef}>
                {/* <line /> */}
                <line x1="0" x2={myWidth} y1="20" y2="20" stroke="rgb(1, 42, 95)" strokeWidth="20"/>
                <text x={myWidth + 5} y="25" fontFamily="Roboto" fontSize="12" >{absVal.toFixed(1)}</text>
            </svg>
        </div>
    )
}

const DumbbellChart = ({ firstVal, secondVal, firstOriginal, secondOriginal, minValueAllCities, maxValueAllCities }) => {
    const classes = useStyles()
    const svgRef = useRef()
    const firstCircle = useRef()
    const secondCircle = useRef()

    console.log(`min ${minValueAllCities} max ${maxValueAllCities}`)
    console.log(`first ${firstVal} second ${secondVal}`)
    let spread = maxValueAllCities - minValueAllCities

    let first = Math.floor(((Number(firstVal) - Number(minValueAllCities)) / spread) * 350)
    let second = Math.floor(((Number(secondVal) - Number(minValueAllCities)) / spread) * 350)
    let lineStart = first
    let lineEnd = second

    let firstAdjust = 0
    let secondAdjust = 0
    // spread out labels if they're close
    if (Math.abs(first - second) < 20) {
        if (first >= second) {
            firstAdjust = 10
            secondAdjust = -10
        } else {
            secondAdjust = 10
            firstAdjust = -10
        }
    }

    let firstLabel
    let secondLabel

    if (firstOriginal !== undefined && secondOriginal !== undefined) {
        if (firstOriginal > 1 || secondOriginal > 1) {
            firstLabel = firstOriginal.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            secondLabel = secondOriginal.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        } else {
            firstLabel = ((firstOriginal * 100).toFixed(1)).toString() + '%'
            secondLabel = ((secondOriginal * 100).toFixed(1)).toString() + '%'
        }
    } else {
        firstLabel = ((firstVal * 100).toFixed(1)).toString() + '%'
        secondLabel = ((secondVal * 100).toFixed(1)).toString() + '%'
    }

    useEffect(() => {
        const svg = select(svgRef.current)
        const fCircle = select(firstCircle.current)
        const sCircle = select(secondCircle.current)
        fCircle.raise()
        sCircle.raise()

    }, [])

    return (
        <>
            <svg className={classes.svgContainer} ref={svgRef}>
                <circle ref={firstCircle} className='firstCircle' cx={first} cy="20" r="6" stroke="#FFC0CB" fill="#FFC0CB" strokeWidth="1"/>
                <circle ref={secondCircle} className='secondCircle' cx={second} cy="20" r="6" stroke="rgb(1, 42, 95)" fill="rgb(1, 42, 95)" strokeWidth="1"/>
                <text x={first - 10 + firstAdjust} y="35" fontFamily="Roboto" fontSize="10" >{firstLabel}</text>
                <text x={second - 10 + secondAdjust} y="35" fontFamily="Roboto" fontSize="10" >{secondLabel}</text>
                <line x1={lineStart} x2={lineEnd} y1="20" y2="20" stroke="rgb(1, 42, 95)" strokeWidth="1"/>
            </svg>
        </>
    )
}

const calculateColor = (x) => {
    if (x > 9) x = 9
    let n = Math.floor(Math.random() * 100)
    if (x) n = x * 10
    let c = blueColors[n]
    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
}

const calculateMetVals = (cities, metrics, equityMetric, newData) => {
    let compMap = {
        'Black': 'Black or African American',
        'Hispanic': 'Hispanic or Latino',
    }
    let combined = [], scale = {}

    cities.forEach(city => {
        let metVals = []
        let cityArr = city.split(", ")
        let cityType = cityArr.pop()
        let cityName = cityArr.join(", ")
        metrics.forEach(metric => {
            let targetComp = compMap[equityMetric.split(' / ')[1]]
            let dataValue1, dataValue2

            try {
                let targetRow1 = newData.find(row => {
                    return row[0] === cityName && row[2] == cityType && row[5] === metric && row[3] === 'White Alone, Not Hispanic or Latino'
                })
                dataValue1 = Number(targetRow1[9])

                let targetRow2 = newData.find(row => {
                    return row[0] === cityName && row[2] == cityType && row[5] === metric && row[3] === targetComp
                })
                dataValue2 = Number(targetRow2[9])
            } catch (err) {
                console.log('missing value. ignore') 
            }

            if (dataValue1 > 1 || dataValue2 > 1) {
                const factor = Math.round(Math.max(dataValue1, dataValue2)).toString().length
                if (scale[metric]) scale[metric] = Math.max(scale[metric], Math.pow(10, factor))
                else scale[metric] = Math.pow(10, factor)
            }

            // calculate the equity ratio
            let ratio = null
            if (metric_directionality_dict[metric]) {
                if (metric_directionality_dict[metric] === -1) {
                    // negative association
                    ratio = dataValue2 / dataValue1
                } else {
                    // do regular
                    ratio = dataValue1 / dataValue2
                }
            } else {
                // do default
                ratio = dataValue1 / dataValue2
            }


            metVals.push({
                firstValue: dataValue1,
                firstOriginal: dataValue1,
                secondValue: dataValue2,
                secondOriginal: dataValue2,
                metricName: metric,
                metricEquityRatio: ratio
            })

        })
        combined.push({
            metVals,
            city
        })
    })

    
    combined.forEach((row) => {
        let inequitySum = 0
        row.metVals.forEach((metric) => {
            metric.firstValue /= (scale[metric.metricName] || 1)
            metric.secondValue /= (scale[metric.metricName] || 1)
            metric.equityGap = metric.firstValue - metric.secondValue
            inequitySum += metric.metricEquityRatio 
        })
        row.outcomeAverage = calculateOutcomeAverage(row.metVals)
        row.avgInequityRatio = inequitySum / row.metVals.length
    })
    console.log('final combined: ', combined)

    try {
        for (let i = 0; i < combined[0].metVals.length; i++) {
            let minSeen = 10000000000000
            let maxSeen = -1000000000000
            combined.forEach(city => {
                if (city.metVals[i].firstValue > maxSeen) maxSeen = city.metVals[i].firstValue
                if (city.metVals[i].secondValue > maxSeen) maxSeen = city.metVals[i].secondValue
                if (city.metVals[i].firstValue < minSeen) minSeen = city.metVals[i].firstValue
                if (city.metVals[i].secondValue < minSeen) minSeen = city.metVals[i].secondValue
            })
            combined.forEach(city => {
                city.metVals[i].minValueAllCities = minSeen
                city.metVals[i].maxValueAllCities = maxSeen
            })
        }

    } catch (err) {
        console.log('error: ', err)
    }
    console.log('final combined222222: ', combined)
    return combined
}

const calculateOutcomeAverage = (metVals) => {
    let firstValSum = 0
    let secondValSum = 0
    let equityGapSum = 0

    metVals.forEach(met => {
        firstValSum += met.firstValue
        secondValSum += met.secondValue
        equityGapSum += met.equityGap
    })

    return {
        firstValue: firstValSum / metVals.length,
        secondValue: secondValSum / metVals.length,
        equityGap: equityGapSum / metVals.length,
    }
}

const getAllDisplayValues = (cities, details, equityMetric, excludeList, newData, metricMappings) => {
    let metrics = metricMappings.outcomes[details]
    metrics = metrics.filter(met => {
        // remove excluded metrics!
        return excludeList.indexOf(met) === -1
    })

    let combined = calculateMetVals(cities, metrics, equityMetric, newData)

    return combined.sort((a, b) => {
        if (a.avgInequityRatio > b.avgInequityRatio) {
            return -1
          }
          if (a.avgInequityRatio < b.avgInequityRatio) {
            return 1
          }
          return 0
    })
}

const PeerRow = ({ data, idx, meanInequity, sortMaxInequity }) => {
    const classes = useStyles()

    return (
        <div key={uuid()} className={classes.row}>
            <div className={classes.outcomeContainer}>
                <div className={classes.bar}>
                    {!isNaN(data.outcomeAverage.firstValue) && !isNaN(data.outcomeAverage.secondValue) && (
                        // <DumbbellChart firstVal={data.outcomeAverage.firstValue} secondVal={data.outcomeAverage.secondValue} />
                        <BarChart absVal={data.avgInequityRatio} meanInequity={meanInequity} sortMaxInequity={sortMaxInequity} />
                    )}
                    {(isNaN(data.outcomeAverage.firstValue) || isNaN(data.outcomeAverage.secondValue)) && (
                        <div className={classes.errDiv}>No Data Available</div>
                    )}
                </div>
                <div className={classes.ranking}>
                    <div className={classes.rankCircle} style={{background: calculateColor(idx + 1)}}>{idx + 1}</div>
                </div>
            </div>
            <div className={classes.metricContainer}>
                {data.metVals.map(met => {
                    return (
                        <div key={uuid()} className={classes.metricValue}>
                            {!isNaN(met.firstValue) && !isNaN(met.secondValue) && (
                                <DumbbellChart 
                                firstVal={met.firstValue} 
                                firstOriginal={met.firstOriginal} 
                                secondVal={met.secondValue} 
                                secondOriginal={met.secondOriginal} 
                                minValueAllCities={met.minValueAllCities}
                                maxValueAllCities={met.maxValueAllCities} />
                            )}
                            {(isNaN(met.firstValue) || isNaN(met.secondValue)) && (
                                <div className={classes.errDiv}>No Data Available</div>
                            )}
                            <div className={classes.ranking}>
                                <div className={classes.rankCircle} style={{background: calculateColor()}}>{Math.floor(Math.random() * 938)}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}



const HeaderRow = ({ details, metVals }) => {
    const classes = useStyles()

    return (
        <div key={uuid()} className={classes.row}>
            <div className={classes.headerContainer} style={{borderBottom: '1px solid lightgrey', padding: '0 5px', backgroundColor: '#eee'}}>
                <div className={classes.bar}>
                    {details} (outcome)
                </div>
                <div className={classes.ranking}>
                    Rank
                </div>
            </div>
            <div className={classes.metricContainer}>
                {metVals.map(met => {
                    return (
                        <div key={uuid()} className={classes.metricValue}>
                            <div className={classes.headerContainer}>
                                <div className={classes.bar} style={{fontWeight: 300}}>
                                    {met.metricName} (metric)
                                </div>
                                <div className={classes.ranking} style={{fontWeight: 300}}>
                                    Rank
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const NameRow = ({ state, city }) => {
    const classes = useStyles()
    let nameCom = null;

    if (state && city) {
        let highlightColor = 'auto'
        if (state.city === city) highlightColor = '#0000FF'
        nameCom = <div className={classes.cityContainer} style={{color: highlightColor}}>{city}</div>
    }
    else
        nameCom = <div className={classes.cityContainer}></div>

    return (
        <div key={uuid()} className={classes.row}>
            {nameCom}
        </div>
    )
}

const EquityDetails = ({ state, dispatch, newData, metricMappings }) => {
    const classes = useStyles()

    const renderRows = (state) => {
        let cities = [state.city, ...state.comparables]
        // combine cities and percentiles to sort them for order of display
        let sorted = getAllDisplayValues(cities, state.details, state.metric, state.excludeList, newData, metricMappings)
        let sortMaxInequity = 0
        let sortInequitySum = 0
        sorted.forEach(city => {
            if (city.avgInequityRatio > sortMaxInequity) sortMaxInequity = city.avgInequityRatio
            sortInequitySum += city.avgInequityRatio
        })
        let meanInequity = (sortInequitySum / sorted.length).toFixed(1)

        let nameRows = sorted.map((obj, idx) => <NameRow key={uuid()} state={state} city={obj.city} />)
        nameRows.unshift(<NameRow key={uuid()} />)
        let rows = sorted.map((obj, idx) => <PeerRow key={uuid()} data={obj} idx={idx} meanInequity={meanInequity} sortMaxInequity={sortMaxInequity} />)
        rows.unshift(<HeaderRow key={uuid()} details={state.details} metVals={sorted[0].metVals} />)
        return [nameRows, rows]
    }

    const [nameRows, rows] = renderRows(state)

    return (
        <div className={classes.bigContainer} style={{paddingBottom: 40}}>
            <div className={classes.legend}>
                <div style={{marginRight: '1rem'}}>
                    <span style={{verticalAlign: 'middle', fontSize: '12px'}}>Best</span>
                    <div className={classes.colorLegend}></div>
                    <span style={{verticalAlign: 'middle', fontSize: '12px'}}>Worst</span>
                </div>
                <div>
                    <div style={{display: 'flex', alignItems: 'center'}}><div className={classes.spanCircle}></div> = {state.metric.split('/')[0]}</div>
                    <div style={{display: 'flex', alignItems: 'center'}}><div className={classes.spanCircle} style={{background: 'rgb(1, 42, 95)'}}></div> = {state.metric.split('/')[1]}</div>
                </div>
            </div>
            <h1 className={classes.overviewTitle}>Inequity ratio</h1>
            <div className={classes.nameContainer}>
                {nameRows}
            </div>
            <div className={classes.container}>
                {rows}
            </div>
        </div>
    )
}
 
export default EquityDetails