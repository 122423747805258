import React from "react";
import PropType from "prop-types";
import styled from "styled-components";

const StyledSidePanel = styled.div`
    width: "350px";
    display: flex;
    flex-direction: column;
    // background-color: ${({ theme }) => theme.colors.lightGray};
    background: linear-gradient(143deg, rgba(223,219,216,1) 0%, rgba(233,234,238,1) 45%, rgba(217,217,217,0.7917542016806722) 100%);
    border-right: 1px solid ${({ theme }) => theme.colors.mediumGray};
    padding: ${({ theme }) => theme.spacers.md};
    transition: 0.3s;
    overflow-y: scroll;
    // overflow: hidden;
    /* height: 100%; */

    .controls-container {
      display: flex;
      justify-content: flex-end;
      font-size: ${({ theme }) => theme.fonts.sizes.md};
      color: ${({ theme }) => theme.colors.darkGray};
      padding: ${({ theme }) => theme.spacers.md};
    }
  `;

const SidePanel = ({ children }) => {
  return <StyledSidePanel>{children}</StyledSidePanel>;
};

SidePanel.propTypes = {
  collapsed: PropType.bool,
};

export default SidePanel;
