import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
.pill {
	display: flex;
	align-items: center;
	padding: .5rem;
	background: #F4F4F4;
	border-radius: 5px;
	width: fit-content;
	color: #565656;
	margin: 3px;
	svg {
		margin-left: 5px;
	}
	.pill-flag {
		min-height: 15px;
		min-width: 15px;
		border-radius: 100%;
		margin-right: .5rem;
		border: 1px solid #F4F4F4;
	}
}

.pillHorizontal {
	align-items: center;
	padding: .5rem;
	background: #F4F4F4;
	border-radius: 5px;
	width: fit-content;
	color: #565656;
	margin: 3px;
	svg {
		margin-left: 5px;
	}
	.pill-flag {
		min-height: 15px;
		min-width: 15px;
		border-radius: 100%;
		margin-right: .5rem;
		border: 1px solid #F4F4F4;
	}
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: .75rem;
    height: .75rem;
    border: 1px solid #F4F4F4;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }

  * {
    p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    }
    h1,h2,h3,h4,h5,h6 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }
  }
`

const theme = {
  colors: {
    primary: `#051c2c`,
    accent: `#1f40e6`,
    white: `#fff`,
    lightGray: `#F4F4F4`,
    mediumGray: `#D8D8D8`,
    darkGray: `#565656`,
    black: `#000000`,
    error: `#b12f2f`,
    warning: `#f79e00`,
    success: `#9cc24a`,
  },
  fonts: {
    header: `Montserrat`,
    subHeader: `Montserrat`,
    body: `Roboto`,
    sizes: {
      xl: `3rem`,
      lg: `1.5rem`,
      md: `1rem`,
      sm: `.75rem`
    }
  },
  spacers: {
    xxl: `5rem`,
    xl: `3rem`,
    lg: `1.5rem`,
    md: `1rem`,
    sm: `.5rem`,
  }
}

export default theme