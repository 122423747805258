import React, { useState, useRef, useEffect, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import ComponentLoader from "../ComponentLoader/ComponentLoader";
import { processedColors, yellowPurpleColors, redGreenColors, orangeColors, purpleColors, blueColors, greenColors } from '../../helpers/colorInterpolation'
import { geoCategories, getLowHighValues } from '../../helpers/geo_categories'
import { getNormalizedSingleVals, getAverageMultimetricVals, getLowHigh, getNormalValues, setFeatureProperties} from '../../helpers/distribution_fns'
import {mapStyle} from './MapSettings'

const Map = ({
  markerPoints,
  zoomLevel,
  showTrafficLayer,
  setZoomLevel,
  startingRegion,
  geoJSONLayer,
  dcGeo,
  selectedMetric,
  aggregationLevel,
  wardBoundaries,
  setActiveTract,
  multiMetric,
  legend,
  aocState,
  aocVal,
  activeTract,
  mapMarkers,
  setMapMarkers,
  selectType,
  activeTracts,
  setActiveTracts,
  idName,
  setAocTracts,
  labelName,
  aggregate,
  percentChange
}) => {

  const mapRef = useRef()

  const defaults = {
    center: startingRegion || { lng: -96.0, lat: 38.0 },
    zoom: zoomLevel || 11.8,
  };

// eslint-disable-next-line
  const [bounds, setBounds] = useState(null);
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(defaults.zoom);
  // eslint-disable-next-line
  const [isMapLoading, setIsMapLoading] = useState(false);
  // const [shouldInvert, setShouldInvert] = useState(true)
  // eslint-disable-next-line
  const [mapApi, setMapApi] = useState(null)
  const [mapsApi, setMapsApi] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  // eslint-disable-next-line
  const [mapListeners, setMapListeners] = useState([])


  const mapOptions = (map) => {
    return {
      zoomControlOptions: {
        position: map.ControlPosition.RIGHT_TOP, // as long as this is not set it works
        style: map.ZoomControlStyle.SMALL,
      },
      styles: mapStyle
    };
  };


  const mapReady = ({ map, maps }) => {
    setZoomLevel(map.zoom)
    mapRef.current = map;
    setMapApi(map)
    setMapsApi(maps)
  }


  const styleActiveMetric = (feature) => {
    try {      
      let featureVal = feature.getProperty('activeMetricIndex')
      let areaOfConcern = feature.getProperty('areaOfConcern')

      if (featureVal === -1) {
        return {
          strokeWeight: 0,
          fillOpacity: 0,
          strokeOpacity: 0,
          clickable: false,
        }
      }

      if (featureVal === undefined || featureVal === null) {
        let isBoundaryWard = feature.getId().includes('Ward')
        if (isBoundaryWard) {
          return {
            strokeWeight: 2.5,
            fillOpacity: 0,
            strokeOpacity: 1,
            strokeColor: 'black',
            clickable: false,
            zIndex: 1000000,
          }
        }
      }

      let arrToUse = processedColors
      if (legend === 'yellowPurpleColors') arrToUse = yellowPurpleColors
      if (legend === 'redGreenColors') arrToUse = redGreenColors
      if (legend === 'blueGreenColors') arrToUse = processedColors
      if (legend === 'orangeColors') arrToUse = orangeColors
      if (legend === 'blueColors') arrToUse = blueColors
      if (legend === 'purpleColors') arrToUse = purpleColors
      if (legend === 'greenColors') arrToUse = greenColors


      let highWorse = geoCategories.find(el => el.value === (percentChange ? selectedMetric[0] : selectedMetric)).highIsWorse
      let colorArr = highWorse ? arrToUse.map(el => el).reverse() : arrToUse

      let fillArr = featureVal !== null && featureVal !== undefined ? colorArr[featureVal] : "#FFF7EC"
      let fillColor = `rgba(${fillArr[0]}, ${fillArr[1]}, ${fillArr[2]}, ${fillArr[3]})`

      if (areaOfConcern && areaOfConcern === 1) {
        return {
          strokeWeight: 2,
          strokeColor: "#09dbcd",
          zIndex: 1,
          position: `absolute`,
          fillOpacity: 0.6,
          fillColor: fillColor,
          strokeOpacity: 1,
          clickable: true,
          visible: true
        }
      } else {
          return {
            strokeWeight: 1.75,
            strokeColor: "#4b5769",
            zIndex: 1,
            position: `absolute`,
            fillOpacity: 0.6,
            fillColor: fillColor,
            strokeOpacity: 0.2,
            clickable: true,
            visible: true
          }
      }
    } catch (err) {
      // console.log('error???')
      return {
        strokeWeight: 0,
        fillOpacity: 0,
        strokeOpacity: 0,
        clickable: false,
      }
    }
  }

  const addQuintilesToGeo = (metricData, metricInvert, low, high) => {
    let spread = Math.abs(high - low)

    let newAocTracts = []
    metricData.forEach(geo => {
      let percentRange = (geo[1] - low) / spread
      let colorIndex = Math.round(percentRange * 100)
      let rowFeature = mapRef.current.data.getFeatureById(geo[0])
      if (rowFeature) {
        if (Number(geo[1] !== -1)) {
          rowFeature.setProperty('activeMetricIndex', colorIndex)
          // negative aocVal indicates that we want the top perforing % - needs to be absolute value though to calculat the correct %
          let threshold = 1 - (Math.abs(aocVal) / 100)
          let inverter = metricInvert ? 1 : -1
          if (aocState === false) threshold = 999999
          if ((aocVal * inverter) > 0) {
            if (percentRange >= threshold)  {
              newAocTracts.push(rowFeature.getProperty(labelName))
              rowFeature.setProperty('areaOfConcern', 1)
            } else {
              rowFeature.setProperty('areaOfConcern', 0)
            }
          } else {
            let threshold = Math.abs(aocVal) / 100
            if (aocState === false) threshold = -999999
            if (percentRange <= threshold)  {
              newAocTracts.push(rowFeature.getProperty(labelName))
              rowFeature.setProperty('areaOfConcern', 1)
            } else {
              rowFeature.setProperty('areaOfConcern', 0)
            }
          }
        } else {
          rowFeature.setProperty('activeMetricIndex', -1)
          rowFeature.setProperty('areaOfConcern', 0)
        }
      } else {
        console.log('missing row feature??', geo[0])
      }
    })

    mapRef.current.data.setStyle(styleActiveMetric)
    setAocTracts(newAocTracts)
  }


  const processGeoStyles = useCallback((selectedMetric, aggregate, percentChange) => {
    if (!mapRef.current) return false
    if (!selectedMetric) return false

    let metricInvert = geoCategories.find(el => {
      return el.value === (percentChange ? selectedMetric[0] : selectedMetric)
    }).invert

    let metricData = []

    dcGeo.features.forEach((row, index) => {
      try {
        let rowFeature = mapRef.current.data.getFeatureById(row.properties[idName])
        if (rowFeature) {
          let metric;
          if (percentChange) {
            const change = rowFeature.getProperty(selectedMetric[1]) - rowFeature.getProperty(selectedMetric[0])
            metric = (change / rowFeature.getProperty(selectedMetric[0])) * 100
          } else metric = rowFeature.getProperty(selectedMetric)
          metricData.push([row.properties[idName], metric])
        }
      } catch (err) {
        console.log('label err: ', err)
      }
    })
    const [low, high] = getLowHighValues(selectedMetric, dcGeo.features, aggregate, percentChange)
    addQuintilesToGeo(metricData, metricInvert, low, high)
  })



  const processMultiRanges = (multiMetric) => {
    if (!mapRef.current) return false
    let metricMap = {}

    dcGeo.features.forEach((row, index) => {
      try {
        let rowFeature = mapRef.current.data.getFeatureById(row.properties[idName])
        if (rowFeature) {
          multiMetric.forEach(met => {
            let metric = rowFeature.getProperty(met.value)
            if (!metricMap[row.properties[idName]]) metricMap[row.properties[idName]] = []
            metricMap[row.properties[idName]].push(metric)
          })
        }
      } catch (err) {
        console.log('label err: ', err)
      }
    })

    let maplen = 0
    for (let prop in metricMap) {
      maplen = metricMap[prop].length
    }

    let normalizedSingleVals  = getNormalizedSingleVals(metricMap, maplen)
    let averageValueMap = getAverageMultimetricVals(normalizedSingleVals)
    let [lowVal, highVal] = getLowHigh(averageValueMap)
    let spreadVal = Math.abs(highVal - lowVal)
    let normalizedValueMap = getNormalValues(averageValueMap, lowVal, spreadVal)
    let newAocTractValues = setFeatureProperties(normalizedValueMap, mapRef, aocVal, aocState, labelName)
    
    mapRef.current.data.setStyle(styleActiveMetric)
    setAocTracts(newAocTractValues)
  }


  const loadBoundaries = () => {
    if (!firstLoad) return
    mapRef.current.data.addGeoJson(wardBoundaries, {idPropertyName: 'LABEL'})
    
    wardBoundaries.features.forEach(ward => {
      let bounds = new mapsApi.LatLngBounds()
      let polyCoords = ward.geometry.coordinates[0]
      for (let i=0; i< polyCoords.length; i++) {
        bounds.extend({lat: polyCoords[i][1], lng: polyCoords[i][0]})
      }
      let centerLatLong = bounds.getCenter()
      // console.log(`my ward is ${ward.properties.LABEL} and bounds are: ${centerLatLong}`)
      if (ward.properties.LABEL === 'Ward 1') {
        // manually adjust ward 1 positioning
        let tmpLat = centerLatLong.lat()
        let tmpLong = centerLatLong.lng()
        tmpLat = tmpLat - 0.005
        let newLatLng = new mapsApi.LatLng(tmpLat, tmpLong)
        centerLatLong = newLatLng
      }
      new mapsApi.Marker({
        position: centerLatLong,
        label: {
          text: ward.properties['LABEL'].split(' ')[1],
          fontSize: "16px",
          fontWeight: "bold",
        },
        icon: 'not_a_real_picture_because_google_maps_sucks_and_i_need_to_hack_this.png',
        map: mapRef.current,
      })
    })
    setFirstLoad(false)
  }

  const cleanListenersAndFeatures = (mapRef, mapsApi) => {
      // Removing old layer as long as it's not the boundary layer
      mapRef.current.data.forEach(feat => {
        if (!feat.getId()) return
        if (!feat.getId().includes('Ward')) mapRef.current.data.remove(feat)
      })
      // debugger
      // remove old listener events
      mapsApi.event.clearInstanceListeners(mapRef.current)
      mapsApi.event.clearListeners(mapRef.current)
      mapsApi.event.clearInstanceListeners(mapRef.current.data)
      mapsApi.event.clearListeners(mapRef.current.data)
  }

  const addMapListeners = (selectType) => {
    const listeners = mapRef.current.data.addListener("click", (event) => {
      let id = event.feature.getProperty(idName)
      if (id !== undefined) {
        setActiveTract(id)
        if (selectType !== 'Inspect') {
          setActiveTracts((prev) => {
              let tmp = prev.slice()
              if (tmp.length > 4) {
                alert('A maximum of 5 tracts can be selected simultaneously.')
                tmp = tmp.slice(0,5)
                return tmp
              } else {
                if (tmp.indexOf(id) === -1) tmp.push(id)
                return tmp
              }
            })
          }
        }
        
    })
    setMapListeners(listeners)
  }

  const fixMarker = (tract, center) => {
    let tmp
    if (tract.properties.NAME === 'Cluster 45') {
      let tmpLat = center.lat()
      let tmpLong = center.lng()
      tmpLat = tmpLat + 0.025
      tmpLong = tmpLong + 0.015
      let newLatLng = new mapsApi.LatLng(tmpLat, tmpLong)
      tmp = newLatLng
    } else {
      let tmpLat = center.lat()
      let tmpLong = center.lng()
      tmpLat = tmpLat + 0.025
      tmpLong = tmpLong + 0.015
      let newLatLng = new mapsApi.LatLng(tmpLat, tmpLong)
      tmp = newLatLng
    }
    return tmp
  }

  const createMapMarkers = () => {
    if (selectType === 'Inspect') {
      let bounds = new mapsApi.LatLngBounds()

      let tract = dcGeo.features.find(el => el.properties[idName] === activeTract)
      console.log(tract)
      if (!tract) return
      let polyCoords = tract.geometry.coordinates[0]
      for (let i=0; i< polyCoords.length; i++) {
        bounds.extend({lat: polyCoords[i][1], lng: polyCoords[i][0]})
      }
      let centerLatLong = bounds.getCenter()
      if (tract.properties.NAME && tract.properties.NAME === 'Cluster 45') centerLatLong = fixMarker(tract, centerLatLong)
      if (tract.properties.NAME && tract.properties.NAME === 'Cluster 46') centerLatLong = fixMarker(tract, centerLatLong)
      const newMarker = new mapsApi.Marker({
        position: centerLatLong,
        map: mapRef.current,
      })
      setMapMarkers([newMarker])
    } else {
      let tmpMarkers = []
      let idx = 0
      activeTracts.forEach(activeTract => {
        idx++
        let bounds = new mapsApi.LatLngBounds()

        let tract = dcGeo.features.find(el => el.properties[idName] === activeTract)
        if (!tract) return
        let polyCoords = tract.geometry.coordinates[0]
        for (let i=0; i< polyCoords.length; i++) {
          bounds.extend({lat: polyCoords[i][1], lng: polyCoords[i][0]})
        }
        let centerLatLong = bounds.getCenter()
        if (tract.properties.NAME && tract.properties.NAME === 'Cluster 45') centerLatLong = fixMarker(tract, centerLatLong)
        if (tract.properties.NAME && tract.properties.NAME === 'Cluster 46') centerLatLong = fixMarker(tract, centerLatLong)
        const newMarker = new mapsApi.Marker({
          position: centerLatLong,
          map: mapRef.current,
          label: {
            text: `${idx}`
          }
        })
        tmpMarkers.push(newMarker)
      })
      setMapMarkers(tmpMarkers)
    }
  }

  const updateMain = () => {
    console.log('dc geo: ', dcGeo)

    if (mapRef.current && geoJSONLayer && mapsApi) {
      cleanListenersAndFeatures(mapRef, mapsApi)

      if (!dcGeo || !dcGeo.type) return

      // add data to the map
      if (aggregationLevel === 'wards') mapRef.current.data.addGeoJson(dcGeo, { idPropertyName: "WARD_ID" })
      if (aggregationLevel === 'neighborhoods') mapRef.current.data.addGeoJson(dcGeo, { idPropertyName: "NAME" })
      if (aggregationLevel === 'censusTracts') mapRef.current.data.addGeoJson(dcGeo, { idPropertyName: "GEOID" })

      loadBoundaries()
      addMapListeners(selectType)

      // style multi or single
      if (multiMetric === undefined || multiMetric === null || multiMetric.length === 0)  {
        processGeoStyles(selectedMetric, aggregate, percentChange)
      } else {
        processMultiRanges(multiMetric)
      }
    }
  }

  // main update function - adds loaded data, sets up ward boundaries, and handles even listeners
  useEffect(() => {
    updateMain()
  }, [mapRef, geoJSONLayer, dcGeo, selectedMetric, aggregate, multiMetric, legend, aocVal, aocState, selectType, mapsApi])



  useEffect(() => {
    //clear markers
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].setMap(null)
    }

    if (activeTract && dcGeo.type && aggregationLevel && mapsApi) {
      createMapMarkers()
    }
  }, [activeTract, selectType, activeTracts, selectType])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(zoomLevel)
      mapRef.current.setCenter(startingRegion)
    }
  }, [zoomLevel, startingRegion])


  if (!dcGeo.type) return null
  return (
    <div style={{ height: `100%`, position: `relative` }}>
      <ComponentLoader loading={isMapLoading}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_API_KEY,
          }}
          defaultCenter={defaults.center}
          defaultZoom={11.8}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals
          layerTypes={showTrafficLayer ? ['TrafficLayer'] : []}
          onGoogleApiLoaded={mapReady}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}>
          {markerPoints}
        </GoogleMapReact>
      </ComponentLoader>
    </div>
  )

}


export default Map

