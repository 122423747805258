import React, { useEffect, useState } from "react";
import Store from "./store";

const GlobalStore = ({ children }) => {

    const [loading, setLoading] = useState(false)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [apiToken, setApiToken] = useState(null)




    return (
        <Store.Provider
            value={{
                loading,
                setLoading,
                isAuthenticated,
                setAuthenticated,
                apiToken,
                setApiToken
            }}
        >
            {children}
        </Store.Provider>
    );
};

export default GlobalStore;