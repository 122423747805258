import React from 'react'
import uuid from "react-uuid"
import { geoCategories } from '../../helpers/geo_categories'
import { dcAggregate, getSingleDcValue } from '../../helpers/dc_aggregate'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
    labelText: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
    },
    labelCol: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
        textAlign: 'center',
        borderBottom: '1px solid lightgrey',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        margin: 0,
        display: 'flex', 
        alignItems: 'center',
        minWidth: 120,
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '12em auto auto auto auto auto auto',
        width: '100%',
        gridTemplateRows: '1fr',
        gap: '0px 0px',
        gridTemplateAreas: ". . . . . . .",
    },
    gridCol: {
        textAlign: 'center'
    },
    gridStart: {
        textAlign: 'left'
    },
    regularRow: {
        width: 100, paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    }
}))


const CompareInfo = ({dcGeo, aggregationLevel, selectedMetric, multiMetric, deselectTract, activeTracts, idName}) => {
    const classes = useStyles()
    if (!dcGeo.features) return
    if (activeTracts.length === 0) return null

    const counts = [
        {indent: false, invert: true, label: 'Population', value: 'P0010001', pct: 100},
        {indent: true, invert: true, label: 'Asian', value: 'P0020008'},
        {indent: true, invert: true, label: 'Black', value: 'P0020006'},
        {indent: true, invert: true, label: 'Hispanic', value: 'P0020002'},
        {indent: true, invert: true, label: 'White', value: 'P0020005'},
      ]

    let fullMetric = null
    if (selectedMetric) {
        fullMetric = geoCategories.find(cat => cat.value === selectedMetric)
    }

    let compareTractArr = []

    activeTracts.forEach(tractId => {
        let tract = dcGeo.features.find(el => el.properties[idName] === tractId)
        if (!tract) return
        let prop = tract.properties

        let labelName = ''
        if (aggregationLevel === 'wards') labelName = prop['LABEL']
        if (aggregationLevel === 'neighborhoods') labelName = prop['NBH_NAMES'].split(',')[0]
        if (aggregationLevel === 'censusTracts') labelName = `Tract ${prop['TRACT']}`

        const countingCategories = [
            {indent: false, invert: true, label: 'Population', value: 'P0010001', pct: 100},
            {indent: true, invert: true, label: 'Asian', value: 'P0020008'},
            {indent: true, invert: true, label: 'Black', value: 'P0020006'},
            {indent: true, invert: true, label: 'Hispanic', value: 'P0020002'},
            {indent: true, invert: true, label: 'White', value: 'P0020005'},
          ]
          let sum = 0
          let percents = {}
          countingCategories.forEach(cat => {
              let labels = ['White', 'Black', 'Asian', 'Hispanic']
              if (labels.indexOf(cat.label) > -1) {
                  sum += prop[cat.value]
                  percents[cat.label] = ((prop[cat.value] / prop['P0010001']) * 100).toFixed(1)
              }
          })
          let otherAmt = prop['P0010001'] - sum
          let otherPct = ((otherAmt / prop['P0010001']) * 100).toFixed(1)

          compareTractArr.push({
              name: labelName,
              properties: prop,
              percents: {...percents},
              otherAmt: otherAmt,
              otherPct: otherPct,
          })

    })

    let isNotStandard = selectedMetric && selectedMetric !== 'median_hh_income' && selectedMetric !== 'pct_poverty' && selectedMetric !== 'pct_unemployed' && selectedMetric.slice(0, 4) !== 'P002'

    return(
      <div key={uuid()} style={{padding: '1em',}}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <CloseIcon style={{hover: 'cursor'}} onClick={() => deselectTract()} />
        </div>

        <div className={classes.gridContainer} style={{marginBottom: '0.5rem'}}>
            <p className={classes.labelCol} style={{textAlign: 'left', fontSize: '1.25em', color: 'black', margin: 0}}></p>
            {compareTractArr.map(tract => {
                return(
                    <div key={uuid()}>
                        <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', borderBottom: aggregationLevel === 'censusTracts' ? 'none' : '1px solid lightgrey'}}>{tract.name}</p>
                        {aggregationLevel === 'censusTracts' && <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', paddingTop: 0}}>({tract.properties['neighborhood'].split(',')[0]})</p>}
                    </div>
                )
            })}
            <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'flex-end'}}>DC</p>
        </div>
        {counts.map(cat => {
          return (
            <div key={uuid()} className={classes.gridContainer} style={{minWidth: 400, padding: 0}}>
              <span className={classes.labelText} style={{paddingLeft: cat.indent ? 15 : 0, paddingTop: 4, paddingBottom: 4}}>{cat.label}: </span>
                {compareTractArr.map(tract => {
                    return (
                        <div key={uuid()} className={classes.regularRow}>
                            <span>{(parseInt(tract.properties[cat.value])).toLocaleString('en')}</span>
                            {cat.label !== 'Population' && <span>({tract.percents[cat.label]}%)</span>}
                        </div>
                    )
                })}
              <div style={{borderLeft: '1px solid lightgrey',}}>
                <div className={classes.regularRow}>
                    <span>{(parseInt(dcAggregate[cat.label])).toLocaleString('en')}</span>
                    {cat.label !== 'Population' && <span>({dcAggregate[cat.label + '_pct']})</span>}
                </div>
              </div>
            </div>
          )
        })}
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{marginBottom: '0rem', padding: 4, paddingLeft: 15,}}>Other: </span>
            {compareTractArr.map(tract => {
                return (
                    <div key={uuid()} className={classes.regularRow}><span >{(tract.otherAmt).toLocaleString('en')}</span><span>({tract.otherPct}%)</span></div>
                )
            })}
            <div className={classes.regularRow} style={{borderLeft: '1px solid lightgrey',}}><span >{(dcAggregate['Other']).toLocaleString('en')}</span><span>({dcAggregate['Other_pct']})</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4,}}>Median income: </span>
            {compareTractArr.map(tract => {
                return (
                    <div key={uuid()} className={classes.regularRow}><span >${(parseInt(tract.properties['median_hh_income'])).toLocaleString('en')}</span></div>
                )
            })}
            <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span >${(parseInt(dcAggregate['median_hh_income'])).toLocaleString('en')}</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>% poverty: </span>
            {compareTractArr.map(tract => {
                return (
                    <div key={uuid()} className={classes.regularRow}><span >{(tract.properties['pct_poverty'] * 100).toFixed(1)}%</span></div>
                )
            })}
            <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span >{(dcAggregate['pct_poverty'] * 100).toFixed(1)}%</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0rem'}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4,}}>% unemployment: </span>
            {compareTractArr.map(tract => {
                return (
                    <div key={uuid()} className={classes.regularRow}><span >{(tract.properties['pct_unemployed'] * 100).toFixed(1)}%</span></div>
                )
            })}
            <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span >{(dcAggregate['pct_unemployed'] * 100).toFixed(1)}%</span></div>
        </div>
        {(fullMetric && isNotStandard) && <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0rem'}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>{fullMetric.label}: </span>
            {compareTractArr.map(tract => {
                let pctFormat = fullMetric.label[0] === '%'
                return (
                    <div key={uuid()} className={classes.regularRow}><span >{ pctFormat ? ((tract.properties[fullMetric.value] * 100).toFixed(1)) + '%' : Math.round(tract.properties[fullMetric.value]).toLocaleString('en') }</span></div>
                )
            })}
            <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span>{getSingleDcValue(fullMetric)}</span></div>
        </div>}

        {multiMetric.map(multiMet => {
            // eslint-disable-next-line
            if (multiMet.value === 'pct_poverty' || multiMet.value === 'median_hh_income' || multiMet.value === 'pct_unemployed' && multiMet.value.slice(0, 4) !== 'P002') return null
            return (
                <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0rem'}}>
                <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>{multiMet.label}: </span>
                {compareTractArr.map(tract => {
                    let pctFormat = multiMet.label[0] === '%'
                    return (
                        <div key={uuid()} className={classes.regularRow}><span >{ pctFormat ? ((tract.properties[multiMet.value] * 100).toFixed(1)) + '%' : Math.round(tract.properties[multiMet.value]).toLocaleString('en') }</span></div>
                    )
                })}
                    <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span>{getSingleDcValue(multiMet)}</span></div>
                </div>
        )}
        )}
        {/* {aggregationLevel === 'censusTracts' && (
            <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0.5rem'}}>
                <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>Tract: </span>
                {compareTractArr.map(tract => {
                    return (
                        <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span >{ tract.name.split(' ')[1] }</span></div>
                    )
                })}
                <div style={{borderLeft: '1px solid lightgrey', width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between'}}><span ></span></div>
            </div>
        )} */}
        
      </div>
    )
}

export default CompareInfo