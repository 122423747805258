import React, {useEffect, useState, useReducer, useContext} from 'react'
import GapChart from './GapChart'
import { makeStyles } from '@material-ui/core/styles'
import GapMenu from './GapMenu'
import Details from './Details/index'
import { fetchSecureCsv } from '../../data-service'
import Store from '../../context/store'
import { generateMetricMappings } from './metricMappings'
import * as Papa from 'papaparse'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        // background: 'linear-gradient(143deg,rgba(223,219,216,0.8) 0%,rgba(233,234,238,0.8) 45%,rgba(217,217,217,0.6917542016806722) 100%)',
        // marginLeft: 20,
        // marginRight: 20,
        paddingTop: 0,
        paddingBottom: 0,
        // boxShadow: '2px 2px 6px 5px rgba(0,0,0,0.2)',
        background: 'white',
    },
    menu: {
        width: 350,
        padding: 0,
    },
    map: {
        width: 'calc(100vw - 350px)',
        padding: 20,
    },
    overviewTitle: {
        textAlign: 'center',
        fontSize: '2em',
        // marginTop: '1em',
        paddingTop: '1em',
        paddingBottom: '1em',
        fontFamily: 'roboto',
        color: '#565656',
    },
    gapAnalysis: {
        background: 'linear-gradient(143deg,rgba(223,219,216,0.8) 0%,rgba(233,234,238,0.8) 45%,rgba(217,217,217,0.6917542016806722) 100%)',
        borderBottom: '1px solid lightgrey',
        // padding: 20,
    },
    sectionHeading: {
        color: 'white', background: 'rgb(1, 42, 95)', padding: '1rem', paddingLeft: 50, fontSize: '2em', fontFamily: 'Roboto'
    },
    outcomeText: {
        textAlign: 'center',
        marginTop: '1em',
        color: 'rgb(1, 42, 95)',
        fontSize: 16,
        fontWeight: 'bold',
    }
  }))

const initialState = {
    city: '',
    comparables: [],
    metric: '',
    excludeList: [],
    details: '',
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'selectCity':
            // console.log('city!', action.payload)
            return {
                ...state,
                city: action.payload.city
            }
        case 'selectCityWithComps':
            // console.log('city!', action.payload)
            return {
                ...state,
                city: action.payload.city,
                comparables: [...action.payload.comparables]
            }
        case 'selectComparable':
            // console.log('comp payload!', action.payload)
            return {
                ...state,
                comparables: [...action.payload]
            }
        case 'selectMetric':
            return {
                ...state,
                metric: action.payload
            }
        case 'updateExcludeList':
            return {
                ...state,
                excludeList: action.payload
            }
        case 'selectDetails':
            setTimeout(() => document.querySelector('#details').scrollIntoView({behavior: 'smooth'}), 150)
            return {
                ...state,
                details: action.payload
            }
        default:
            return state
    }
}


const Overview = (props) => {
    const classes = useStyles()
    const ctx = useContext(Store)
    const [newData, setNewData] = useState([])
    const [metricMappings, setMetricMappings] = useState({})
    const [state, dispatch] = useReducer(reducer, initialState)
    const [cityNames, setCityNames] = useState([])

    useEffect(() => {
        console.log('state updated!', state)
    }, [state])

    useEffect(() => {
        console.log('new data updated: ', newData)
    }, [newData])

    const processCityData = (data) => {
        let cities = []
        data.forEach(row => {
                const cityName = row[0] + ", " + row[2]
                if (cities.indexOf(cityName) < 0) cities.push(cityName)
        })
        setNewData(data)
        setCityNames(cities)
    }

    const loadData = async () => {
        try {
            // let results = await fetchSecureCsv('mock_msa2.csv', ctx.apiToken)
            let mappingResults = await fetchSecureCsv('system_outcome_metric_mapping.csv', ctx.apiToken)
            let newDataResults = await fetchSecureCsv('city_x_data_20210609_new.csv', ctx.apiToken)
            setMetricMappings(generateMetricMappings(mappingResults))
            processCityData(newDataResults.data.slice(1))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        props.setShowNav(true)
        // load MSA data
        loadData()
    }, [])

    return (
        <div>
            <div className={classes.sectionHeading}><h1>Opportunity analysis</h1></div>
            <div className={classes.gapAnalysis}>
                {/* <h1 className={classes.overviewTitle}>Gap analysis</h1>  */}
                <div className={classes.container}>
                    <div className={classes.menu}>
                        <GapMenu state={state} dispatch={dispatch} cityNames={cityNames} metricMappings={metricMappings} />
                    </div>
                    <div className={classes.map}>
                        <GapChart state={state} dispatch={dispatch} newData={newData} metricMappings={metricMappings} />
                    </div>
                </div>
            </div>
            <div>
                <div className={classes.sectionHeading}><h1>Details</h1></div>
                {state.details.length === 0 && <p className={classes.outcomeText}>No outcome selected.</p>}
                <Details state={state} dispatch={dispatch} newData={newData} metricMappings={metricMappings} />
            </div>
        </div>
    )
}
 
export default Overview