import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import Store from "./context/store";
// Components
import Navigation from "./components/Navigation/Navigation";
// Pages
import ReactLoading from "react-loading";
import theme, { GlobalStyle } from "./theme";
import CityX from './pages/CityX'
import Overview from "./pages/Overview";
import Landing from './pages/Landing/Landing'
import Login from './pages/Login'

const App = () => {
  const [showNav, setShowNav] = useState(false)
  const routes = [
    {
      label: "Overview",
      route: "/overview",
    },
    {
      label: "Map",
      route: "/map",
    },
  ];

  const ctx = useContext(Store);

  const LoadingWrapper = styled.div`
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    pointer-events: none;
  `;

  useEffect(() => {
    try {
      let token = localStorage.getItem('auth-token')
      if (token) {
        ctx.setAuthenticated(true)
        ctx.setApiToken(token)
      }
    } catch (err) {

    }
  }, [])
  
  if (!ctx.isAuthenticated) return <Login />
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div className="App" style={{ position: `relative` }}>
        <BrowserRouter>
          {showNav && <Navigation
            routes={[...routes]}
          />}
          <main className="main-container">
            {ctx.loading && (
              <LoadingWrapper>
                <ReactLoading
                  type="bars"
                  color={theme.colors.accent}
                  height={200}
                  width={200}
                />
              </LoadingWrapper>
            )}
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/overview"
                  render={(props) => <Overview {...props} setShowNav={setShowNav} />}
                />
                <Route
                  exact
                  path="/map"
                  render={(props) => <CityX {...props} setShowNav={setShowNav} />}
                />
                  <Route
                  path="/"
                  render={(props) => <Landing {...props} setShowNav={setShowNav} />}
                />
              </Switch>
          </main>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
