import React, {useMemo} from 'react'
import uuid from "react-uuid"
import { geoCategories } from '../../helpers/geo_categories'
import { makeStyles } from '@material-ui/core/styles'
import { dcAggregate, getSingleDcValue } from '../../helpers/dc_aggregate'
import CloseIcon from '@material-ui/icons/Close'

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
    labelText: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
    },
    labelCol: {
        fontWeight: 600,
        fontSize: '1.05em',
        color: '#565656',
        textAlign: 'center',
        borderBottom: '1px solid lightgrey',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        margin: 0,
        display: 'flex', 
        alignItems: 'center'
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '0px 0px',
        gridTemplateAreas: ". . .",
    },
    gridCol: {
        textAlign: 'center'
    },
    gridStart: {
        textAlign: 'left'
    },
    regularRow: {
        width: 100, paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    dcRow: {
        display: 'flex', 
        alignItems: 'center',
    }
}))


const AggInfo = ({dcGeo, aggregationLevel, selectedMetric, multiMetric, deselectAggregate, activeTracts, idName}) => {
    const classes = useStyles()

    const getTracts = (activeTracts) => {
        let tmp = []
        activeTracts.forEach(tractId => {
            let tract = dcGeo.features.find(el => el.properties[idName] === tractId)
            if (tract) {
                tmp.push(tract.properties)
            }
        })
        return tmp
    }

    const tractArr = useMemo(() => {
       return getTracts(activeTracts)
    }, [activeTracts])


    const getFullMetric = (selectedMetric) => {
        let tmp = null
        if (selectedMetric) {
            tmp = geoCategories.find(cat => cat.value === selectedMetric)
        }
        return tmp
    }

    const fullMetric = useMemo(() => {
        return getFullMetric(selectedMetric)
    }, [selectedMetric])


    // definitions
    const countingCategories = [
      {indent: false, invert: true, label: 'Population', value: 'P0010001', pct: 100},
      {indent: true, invert: true, label: 'Asian', value: 'P0020008'},
      {indent: true, invert: true, label: 'Black', value: 'P0020006'},
      {indent: true, invert: true, label: 'Hispanic', value: 'P0020002'},
      {indent: true, invert: true, label: 'White', value: 'P0020005'},
    ]

    const calculateAggregate = (tractArr, selectedMetric, multiMetric) => {
        let stateValues = {
            sumDict: {
                Population: 0,
                White: 0,
                Black: 0,
                Hispanic: 0,
                Asian: 0,
            },
            tractNames: [],
            medianIncome:  0,
            poverty:  0,
            unemployment:  0,
            currentSelected:  0,
            multiMemo: {},
            multiValueArr: [],
            otherAmt: 0,
            otherPct: 0,
        }


        tractArr.forEach(tract => {
            let labelName = ''
            if (aggregationLevel === 'wards') labelName = tract['LABEL']
            if (aggregationLevel === 'neighborhoods') labelName = tract['NBH_NAMES'].split(',')[0]
            if (aggregationLevel === 'censusTracts') labelName = `Tract ${tract['TRACT']}`
            stateValues.tractNames.push({labelName: labelName, idName: tract[idName], neighborhoodName: tract['neighborhood']})

            stateValues.medianIncome += Number(tract['median_hh_income'])
            stateValues.poverty += Number(tract['pct_poverty'])
            stateValues.unemployment += Number(tract['pct_unemployed'])

            if (selectedMetric) stateValues.currentSelected += Number(tract[selectedMetric])

            if (multiMetric && multiMetric.length > 0) {
                // console.log('MULTI METRICS')
                multiMetric.forEach(met => {
                    if (!stateValues.multiMemo[met.label]) {
                        stateValues.multiMemo[met.label] = Number(tract[met.value])
                    } else {
                        stateValues.multiMemo[met.label] += Number(tract[met.value])
                    }
                    // console.log(met)
                })
            }

            countingCategories.forEach(cat => {
                let labels = ['Population', 'White', 'Black', 'Asian', 'Hispanic']
                if (labels.indexOf(cat.label) > -1) {
                    stateValues.sumDict[cat.label] += tract[cat.value]
                }
            })
        })

        stateValues.otherAmt = stateValues.sumDict['Population'] - stateValues.sumDict['White'] - stateValues.sumDict['Black'] - stateValues.sumDict['Hispanic'] - stateValues.sumDict['Asian']
        stateValues.otherPct = ((stateValues.otherAmt / stateValues.sumDict['Population']) * 100).toFixed(1)

        stateValues.medianIncome = stateValues.medianIncome / tractArr.length
        stateValues.poverty = stateValues.poverty / tractArr.length
        stateValues.unemployment = stateValues.unemployment / tractArr.length

        if (selectedMetric) {
            if (selectedMetric.slice(0,4) !== 'foot') {
                stateValues.currentSelected = stateValues.currentSelected / tractArr.length
            } 
        }


        if (multiMetric && multiMetric.length > 0) {
            for (let prop in stateValues.multiMemo) {
                let name = prop
                let amount = stateValues.multiMemo[prop] / tractArr.length
                let match = multiMetric.find(el => el.label === name)
                let value = match.value
                stateValues.multiValueArr.push({
                    name,
                    amount,
                    value,
                })
            }
        }

        return stateValues
    }

    const stateValues = useMemo(() => {
        return calculateAggregate(tractArr, selectedMetric, multiMetric)
    }, [tractArr, selectedMetric, multiMetric])



    let isNotStandard = selectedMetric && selectedMetric !== 'median_hh_income' && selectedMetric !== 'pct_poverty' && selectedMetric !== 'pct_unemployed'
    
    if (!dcGeo.features) return
    return(
      <div key={uuid()} style={{padding: '1em'}}>
        <div style={{display: 'flex', justifyContent: 'space-between',}}>
            <p style={{padding: 4, fontSize: '1.5em', fontWeight: 600, marginBottom: '0.75em', maxWidth: 360}}>
                {stateValues.tractNames.map((name, idx) => {
                    if (idx === stateValues.tractNames.length - 1) return <span style={{fontSize: '0.9em'}} key={uuid()} onClick={() => deselectAggregate(name.idName)}>{name.labelName} {aggregationLevel === 'censusTracts' && <span style={{color: '#565656'}}>({name.neighborhoodName})</span>}</span>
                    return (
                        <>
                        <span style={{fontSize: '0.9em'}} key={uuid()} onClick={() => deselectAggregate(name.idName)}>{name.labelName} {aggregationLevel === 'censusTracts' && <span style={{color: '#565656'}}>({name.neighborhoodName})</span>} </span>
                        <br/>
                        </>
                    )
                })}
            </p>
            <CloseIcon style={{hover: 'cursor'}} onClick={() => deselectAggregate()} />
        </div>
        <div className={classes.gridContainer} style={{marginBottom: '0.5rem'}}>
            <p className={classes.labelCol} style={{textAlign: 'left', fontSize: '1.25em', color: 'black', margin: 0}}></p>
            <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center'}}>Selected</p>
            {/* <div className={classes.labelCol} style={{flexDirection: 'column'}}>
                {tractNames.map(name => {
                    return (
                        <span style={{paddingTop: 2, paddingBottom: 2}}>{name}</span>
                    )
                })}
            </div> */}
            <p className={classes.labelCol} style={{textAlign: 'center', justifyContent: 'center'}}>DC</p>
        </div>
        {countingCategories.map(cat => {
          // if (cat.label === 'Population' && aggregationLevel === 'neighborhoods') return null
          return (
            <div key={uuid()} className={classes.gridContainer} style={{minWidth: 400, padding: 0}}>
              <span className={classes.labelText} style={{paddingLeft: cat.indent ? 15 : 0}}>{cat.label === 'Hispanic' ? cat.label + ' / Latino' : cat.label}: </span>
              <div style={{width: 100, paddingLeft: 10, paddingTop: 4, paddingBottom: 4,  display: 'flex', justifyContent: 'space-between'}}>
                <span>{(parseInt(stateValues.sumDict[cat.label])).toLocaleString('en')}</span>
                {cat.label !== 'Population' && <span>({((stateValues.sumDict[cat.label] / stateValues.sumDict['Population'] * 100)).toFixed(1)}%)</span>}
              </div>
              <div style={{borderLeft: '1px solid lightgrey'}}>
                <div style={{width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', paddingBottom: 4, paddingTop:4}}>
                    <span className={classes.dcRow}>{(parseInt(dcAggregate[cat.label])).toLocaleString('en')}</span>
                    {cat.label !== 'Population' && <span >({dcAggregate[cat.label + '_pct']})</span>}
                </div>
              </div>
            </div>
          )
        })}
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{marginBottom: '0rem', padding: 4, paddingLeft: 15, }}>Other: </span>
            <div className={classes.regularRow}><span >{(stateValues.otherAmt).toLocaleString('en')}</span><span>({stateValues.otherPct}%)</span></div>
            <div style={{borderLeft: '1px solid lightgrey'}} className={classes.regularRow}><span >{(dcAggregate['Other']).toLocaleString('en')}</span><span>({dcAggregate['Other_pct']})</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4, paddingTop: 10}}>Median income: </span>
            <div className={classes.regularRow}><span >${(Math.round(stateValues.medianIncome)).toLocaleString('en')}</span></div>
            <div style={{borderLeft: '1px solid lightgrey',}} className={classes.regularRow}><span >${(parseInt(dcAggregate['median_hh_income'])).toLocaleString('en')}</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4}}>% poverty: </span>
            <div className={classes.regularRow}><span >{(stateValues.poverty * 100).toFixed(1)}%</span></div>
            <div style={{borderLeft: '1px solid lightgrey',}} className={classes.regularRow}><span >{(dcAggregate['pct_poverty'] * 100).toFixed(1)}%</span></div>
        </div>
        <div key={uuid()} className={classes.gridContainer} style={{minWidth: 200, padding: 0, marginBottom: '0rem'}}>
            <span className={classes.labelText} style={{paddingLeft: 0, padding: 4, paddingBottom: 10}}>% unemployment: </span>
            <div className={classes.regularRow}><span >{(stateValues.unemployment * 100).toFixed(1)}%</span></div>
            <div style={{borderLeft: '1px solid lightgrey',}} className={classes.regularRow}><span >{(dcAggregate['pct_unemployed'] * 100).toFixed(1)}%</span></div>
        </div>
        {isNotStandard && <div key={uuid()} className={classes.gridContainer}  style={{minWidth: 200, padding: 0}}>
            <span className={classes.labelText} style={{paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>{fullMetric.label}: </span>
            {selectedMetric.slice(0,4) !== 'foot' ? <div className={classes.regularRow}><span >{(stateValues.currentSelected * 100).toFixed(1)}%</span></div> : null}
            {selectedMetric.slice(0,4) === 'foot' ? <div className={classes.regularRow}><span >{(Math.round(stateValues.currentSelected)).toLocaleString('en')}</span></div> : null}
            <span style={{borderLeft: '1px solid lightgrey', padding: '4px 10px'}}>{getSingleDcValue(fullMetric)}</span>
        </div>}
        {stateValues.multiValueArr.map(met => {
            if (met.name === '% poverty' || met.name === '% unemployed') return null
            return (
                <div key={uuid()} className={classes.gridContainer}  style={{minWidth: 200, padding: 0}}>
                    <span className={classes.labelText} style={{paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>{met.name}: </span>
                    {met.name.slice(0,4) !== 'Foot' ? <div className={classes.regularRow}><span >{(met.amount * 100).toFixed(1)}%</span></div> : null}
                    {met.name.slice(0,4) === 'Foot' ? <div className={classes.regularRow}><span >{(Math.round(met.amount)).toLocaleString('en')}</span></div> : null}
                    <span style={{borderLeft: '1px solid lightgrey', padding: '4px 10px'}}>{getSingleDcValue(met)}</span>
                </div> 
            )
        })}
      </div>
    )
}

export default AggInfo