import React from 'react'
import SidePanel from "../../components/SidePanel/SidePanel"
import { makeStyles } from '@material-ui/core/styles'
import FilterGroup from "../../components/FilterGroup"
import StyledSelect from "../../components/StyledSelect/StyledSelect"
import { filteredGeoCategories, multiMetricCategories } from '../../helpers/geo_categories'
import { pointTypes } from '../../helpers/pointTypes'
import Select from 'react-select'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '0',
    },
    marginTop: '1em'
  },
  radio: {
    "& .MuiSvgIcon-root": {
      height: 18,
      weight: 18,
      padding: 0,
    },
  },
  label: {
    fontSize: 14
  },
  rightPanel: {
    border: '1px solid lightgrey',
    background: '#F5F5F5',
    position: 'absolute',
    bottom: '1.5em',
    right: '1em',
    zIndex: '998',
    borderRadius: '4px',
    boxShadow: '3px 4px 2px -2px rgba(0,0,0,0.31)',
    padding: 8,
    height: 250,
    overflowY: 'scroll',
    minWidth: 200,
    maxWidth: 400,
  }
}));

const colorMap = {
  'no-option': 'grey',
  'baseline': 'green',
  'disadvantage': 'orange',
  'traffic': 'blue',
}

const selectStyles = {
  option: (styles, { isSelected, isDisabled, ...args }) => {
      let textColor = args.data.category ? colorMap[args.data.category] : theme.colors.primary
      if (isDisabled) textColor = theme.colors.lightGray

      return {
          ...styles,
          backgroundColor: isDisabled ? theme.colors.lightGray : theme.colors.white,
          // color: isDisabled ? theme.colors.darkGray : theme.colors.primary,
          color: textColor,
          minWidth: `200px`,
          ":hover": {
              backgroundColor: isDisabled ? theme.colors.lightGray : theme.colors.accent,
              color: isDisabled ? theme.colors.black : theme.colors.white,
              cursor: isDisabled ? "not-allowed" : "pointer",
          },
      };
  },
};


const FilterSidepanel = ({
  setActiveTract, 
  setActiveTracts, 
  setAggregationLevel,
  setIdName,
  setLabelName,
  setColorOptionValue,
  setLegend,
  aocState,
  aggregationLevel,
  setAocVal,
  setAocSelectLabel,
  aocSelectLabel,
  colorOptionValue,
  setMultiMetric,
  setAocState,
  setSelectedMetric,
  multiMetric,
  setSelectType,
  selectType,
  setPoiType,
  selectedMetric,
  setShowYearSlider,
  setAggregate,
  setPercentChange
}) => {
  const classes = useStyles()

    return (
        <SidePanel>
          <FilterGroup >
            <div className="single-filter">
              {/* <p className='title left' onClick={() => window.location.reload()} style={{fontSize: '2rem', marginTop: 0, marginBottom: 0, fontFamily: 'Orbitron', fontWeight: 700, cursor: 'pointer'}}>CityX</p> */}
              <p className='title left' style={{marginBottom: 0, marginTop: '1rem', fontSize: '1.1em'}}>Design</p>
              <p className="sub-title blue" style={{marginBottom: '0.5rem', marginTop: '1rem', fontSize: '0.9em'}}>Display level</p>
              <StyledSelect
                  onChange={(opt) => {
                    setActiveTract(null)
                    setActiveTracts([])
                    setAggregationLevel(opt.value)
                    let tmp = 'WARD_ID'
                    let tmpLabel = 'NAME'
                    if (opt.value === 'wards') {
                      tmp = 'WARD_ID'
                      tmpLabel = 'NAME'
                    } 
                    if (opt.value === 'neighborhoods') {
                      tmp = 'NAME'
                      tmpLabel = ['NBH_NAMES']
                    }
                    if (opt.value === 'censusTracts') {
                      tmp = 'GEOID'
                      tmpLabel = 'TRACT'
                    } 
                    setIdName(tmp)
                    setLabelName(tmpLabel)
                  }}
                  defaultValue={{ label: 'Wards', value: 'wards' }}
                  options={[
                    {label: 'Wards', value: 'wards'},
                    {label: 'Neighborhoods', value: 'neighborhoods'},
                    {label: 'Census tracts', value: 'censusTracts'},
                ]}
              />
              <p className='sub-title blue' style={{marginBottom: '0.5rem', marginTop: '1rem', fontSize: '0.9em'}}>Color palette</p>
              <StyledSelect
                  onChange={(opt) => {
                    setColorOptionValue(opt)
                    setLegend(opt.value)
                  }}
                  value={colorOptionValue}
                  // defaultValue={{ label: 'Yellow -> Purple', value: 'yellowPurpleColors' }}
                  options={[
                    {label: 'Yellow -> Purple', value: 'yellowPurpleColors'},
                    {label: 'Orange (intensity range)', value: 'orangeColors'},
                    {label: 'Purple (intensity range)', value: 'purpleColors'},
                    {label: 'Blue (intensity range)', value: 'blueColors'},
                    {label: 'Green (intensity range)', value: 'greenColors'},
                ]}
              />
              <p className='sub-title blue' style={{marginBottom: '0', marginTop: '1rem', fontSize: '0.9em'}}>Best / worst performing area contour</p>
              <FormControlLabel
                control={
                  <Switch
                    checked={aocState}
                    onChange={() => setAocState(!aocState)}
                    name="offon"
                    color="primary"
                  />
                }
                disabled={aggregationLevel === 'wards'}
                label="On / off"
              />
              {aocState && (
                  <StyledSelect
                    onChange={(opt) => {
                      setAocVal(opt.value)
                      setAocSelectLabel(opt)
                    }}
                    value={aocSelectLabel}
                    // defaultValue={{ label: 'Yellow -> Purple', value: 'yellowPurpleColors' }}
                    options={[
                      {
                        label: 'Worst performing areas',
                        options: [
                          {label: 'Bottom 10%', value: 10},
                          {label: 'Bottom 25%', value: 25},
                          {label: 'Bottom 50%', value: 50},
                        ]
                      },
                      {
                        label: 'Best performing areas',
                        options: [
                          {label: 'Top 50%', value: -50},
                          {label: 'Top 25%', value: -25},
                          {label: 'Top 10%', value: -10},
                        ]
                      },
                      // {label: 'Worst performing areas (bottom 10%)', value: 10},
                      // {label: 'Worst performing areas (bottom 25%)', value: 25},
                      // {label: 'Worst performing areas (bottom 50%)', value: 50},
                      // {label: 'Best performing areas (top 10%)', value: -10},
                      // {label: 'Best performing areas (top 25%)', value: -25},
                      // {label: 'Best performing areas (top 50%)', value: -50},
                  ]}
                />
              )}
              <hr style={{marginTop: '1rem'}}/>
              <p className='title left' style={{marginBottom: 0, marginTop: '1rem', fontSize: '1.1em'}}>Layers</p>
              <p className='sub-title blue' style={{marginBottom: '0.5rem', marginTop: '1rem', fontSize: '0.9em'}}>Single-metric index</p>
              <StyledSelect
                  onChange={(opt) => {
                    if (colorOptionValue === null) setColorOptionValue({label: 'Yellow -> Purple', value: 'yellowPurpleColors'})
                    if (colorOptionValue === null) setLegend('yellowPurpleColors')
                    setMultiMetric([])
                    setAggregate(false)
                    setPercentChange(false)
                    if (opt.years) {
                      setShowYearSlider(true)
                      setSelectedMetric(opt.value + "_" + opt.years[0])
                    } else {
                      setShowYearSlider(false)
                      setSelectedMetric(opt.value)
                    }
                  }}
                  // defaultValue={{ label: "pct_poverty", value: 'pct_poverty' }}
                  options={filteredGeoCategories}
                  value={filteredGeoCategories.find(el => el.value === selectedMetric)}
              />
              <p className='sub-title blue' style={{marginBottom: '0.5rem', marginTop: '0.5rem', fontSize: '0.9em'}}>Multiple-metric index*</p>
                <Select
                defaultValue={multiMetric}
                isMulti
                name="multi"
                options={multiMetricCategories}
                onChange={opt => {
                  setShowYearSlider(false)
                  setSelectedMetric(null)
                  setMultiMetric(opt)
                }}
                value={multiMetric}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={selectStyles}
              />
              <p style={{fontSize: '0.6em', marginTop: '0.5rem'}}>“The multi-metric index corresponds to the upper and lower bounds across the selected data. Color intensity is dictated by the co-occurrence of these variables. All variables are weighted equally.</p>
              <hr style={{marginTop: '1rem'}}/>
              <p className='title left' style={{marginBottom: 0, marginTop: '1rem', fontSize: '1.1em'}}>Overlays</p>
              <p className='sub-title blue' style={{marginBottom: '0.25rem', marginTop: '1rem', fontSize: '0.9em'}}>Points of interest</p>
                <Select
                  isMulti
                  name="multipoi"
                  options={pointTypes}
                  onChange={opt => {
                    setPoiType(opt)
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />

              <hr style={{marginTop: '1rem'}}/>
              <p className='title left' style={{marginBottom: '0.25rem', marginTop: '1rem', fontSize: '1.1em'}}>Selection tool</p>
              <FormControl component="fieldset">
                <RadioGroup aria-label="selectType" name="selectType" value={selectType} onChange={e => {
                  setActiveTract(null)
                  setActiveTracts([])
                  setSelectType(e.target.value)
                }}>
                  <FormControlLabel classes={{label: classes.label}} value="Inspect" control={<Radio className={classes.radio} color='primary' />} label="Inspect" />
                  <FormControlLabel classes={{label: classes.label}} value="Compare" control={<Radio className={classes.radio} color='primary'/>} label="Compare" />
                  <FormControlLabel classes={{label: classes.label}} value="Aggregate" control={<Radio className={classes.radio} color='primary'/>} label="Aggregate" />
                </RadioGroup>
              </FormControl>
            </div>
          </FilterGroup>
        </SidePanel>
    )
}

export default FilterSidepanel