import React from "react"
import { Switch, Route, BrowserRouter, Redirect, useHistory } from "react-router-dom";
import './landing.scss'


const Landing = (props) => {
  let history = useHistory();

  const handleRedirect = () => {
    props.setShowNav(true)
    history.push('/overview')
  }

  return (
    <>
    <div className='landing-page'>
      <section className="cd-intro">
        <div className="cd-intro-content mask-2">
          <div className="content-wrapper">
            <div>
              <h1>Welcome to CityX</h1>
              <p>Explore the data. Diagnose areas of opportunity. Foster inclusive growth.</p>
              <div className='container' >
                <div onClick={handleRedirect} class="btn btn-4">Begin</div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default Landing
