import React, { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import FilterSidepanel from './FilterSidepanel'
import ExtensibleMap from "../../components/ExtensibleMap/ExtensibleMap";
import uuid from "react-uuid";
import { geoCategories, filteredGeoCategories } from '../../helpers/geo_categories'
import { makeStyles } from '@material-ui/core/styles'
import CalcLabel from './CalcLabel'
import CalcInfo from './CalcInfo';
import AggInfo from './AggInfo';
import CompareInfo from './CompareInfo';
import { fetchSecureData } from '../../data-service'
import Store from '../../context/store'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';


// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '0',
    },
    marginTop: '1em'
  },
  radio: {
    "& .MuiSvgIcon-root": {
      height: 18,
      weight: 18,
      padding: 0,
    },
  },
  label: {
    fontSize: 14
  },
  rightPanel: {
    border: '1px solid lightgrey',
    background: '#F5F5F5',
    position: 'absolute',
    bottom: '1.5em',
    right: '1em',
    zIndex: '998',
    borderRadius: '4px',
    boxShadow: '3px 4px 2px -2px rgba(0,0,0,0.31)',
    padding: 8,
    height: 250,
    overflowY: 'scroll',
    minWidth: 200,
    maxWidth: 400,
  }
}));

let colorMap = ['blue', 'green', 'orange', 'grey', 'aqua']

let aggMap = {'wards': 'Ward', 'censusTracts': 'Census tract', 'neighborhoods': 'Neighborhood'}

const MapPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const UtilitySectionWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 5fr;
  height: calc(100vh - 4rem);
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGray};
  .filter-title {
    font-size: ${({ theme }) => theme.fonts.sizes.lg};
    margin: ${({ theme }) => theme.spacers.md} 0rem;
  }
`;


const LegendWrapperNew = styled.div`
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  border-right: 2px solid #F5F5F5;
  background: #F5F5F5;
  padding: 1em;
  border-radius: 0.25em;
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-botttom: 2em;
  }
  .sub-title {
      margin-top: 1rem;
  }

  -webkit-box-shadow: 3px 4px 2px -2px rgba(0,0,0,0.31); 
  box-shadow: 3px 4px 2px -2px rgba(0,0,0,0.31);

`;

const InfoWrapper = styled.div`
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  border: 1px solid lightgrey;
  background: #F5F5F5;
  position: absolute;
  bottom: 2em;
  left: 1em;
  z-index: 998;
  padding: 0;
  border-radius: 4px;
  .inner {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-botttom: 2em;
  }
  .sub-title {
      margin-top: 1rem;
  }

  -webkit-box-shadow: 3px 4px 2px -2px rgba(0,0,0,0.31); 
  box-shadow: 3px 4px 2px -2px rgba(0,0,0,0.31);
`;



const CityX = (props) => {
  const classes = useStyles()
  const ctx = useContext(Store)

  const [markerPoints, setMarkerPoints] = useState();
  const [zoomLevel, setZoomLevel] = useState(11.8);
  const [startingRegion, setStartingRegion] = useState({ lat: 38.884524867363136, lng: -77.01632013078692, });
  // eslint-disable-next-line
  const [geoJSONLayer, setGeoJSONLayer] = useState({});
  const [dcGeo, setDcGeo] = useState({});
  // eslint-disable-next-line
  const [showTrafficLayer, setShowTrafficLayer] = useState(false);
  const [dcPoints, setDcPoints] = useState({})
  const [aggregationLevel, setAggregationLevel] = useState('wards')
  const [selectedMetric, setSelectedMetric] = useState('')
  const [showYearSlider, setShowYearSlider] = useState(false)
  const [aggregate, setAggregate] = useState(false)
  const [percentChange, setPercentChange] = useState(false)
  const [poiType, setPoiType] = useState([])
  const [wardBoundaries, setWardBoundaries] = useState({})
  const [activeTract, setActiveTract] = useState(null)
  const [multiMetric, setMultiMetric] = useState([])
  const [legend, setLegend] = useState('yellowPurpleColors')
  const [aocState, setAocState] = useState(false)
  const [aocVal, setAocVal] = useState(10)
  const [aocTracts, setAocTracts] = useState([])
  const [selectType, setSelectType] = useState('Inspect')
  const [mapMarkers, setMapMarkers] = useState([])
  const [activeTracts, setActiveTracts] = useState([])
  const [idName, setIdName] = useState('WARD_ID')
  const [labelName, setLabelName] = useState('LABEL')
  const [colorOptionValue, setColorOptionValue] = useState(null)
  const [aocSelectLabel, setAocSelectLabel] = useState({label: 'Bottom 10%', value: 10})



  useEffect(() => {
    newTracts()
  }, [aggregationLevel])

  useEffect(() => {
    newPoints()
  }, [dcPoints])

  useEffect(() => {
    if (dcPoints.features) selectPoints(dcPoints.features)
  }, [poiType])

  useEffect(() => {
    getWardBoundaries()
    props.setShowNav(true)
  }, [])

  const newTracts = async () => {
      const levels = {
        censusTracts: 'new_tracts_5_4.json',
        neighborhoods: 'new_neighborhoods_5_4.json',
        wards: 'new_wards_5_4.json',
      }
      let data = await fetchSecureData(levels[aggregationLevel], ctx.apiToken)
      setDcGeo(data)
  }

  const getWardBoundaries = async () => {
      let data = await fetchSecureData('new_wards_5_4.json', ctx.apiToken)
      setWardBoundaries(data)
  }

  const newPoints = async () => {
    if (!dcPoints.type) {
      let data = await fetchSecureData('points_of_interest_3_31.json', ctx.apiToken)
      setDcPoints(data)
      renderDcPoints(data.features)
    }
  }

  const selectPoints = () => {
    if (dcGeo.features) renderDcPoints(dcPoints.features)
  }

  const renderDcPoints = (features) => {
    if (poiType === null) return
    let featureCopy = JSON.parse(JSON.stringify(features))
    let labels = []
    poiType.forEach(el => labels.push(el.value))

    try {
      let markersToRender = featureCopy.filter(markToRender => {
        if (labels.indexOf(markToRender.properties.poi) > -1) {
          return true
        } else {
          return false
        }
      }).map(markToRender => {
          return makeDcPointMarker(markToRender, labels)
      })
      setMarkerPoints(markersToRender)
    } catch (err) {
      setMarkerPoints([])
    }
  }

  const makeDcPointMarker = (markToRender, labels) => {
    let colorToUse = colorMap[labels.indexOf(markToRender.properties.poi)]
    const longitude = markToRender.geometry.coordinates[0]
    const latitude = markToRender.geometry.coordinates[1]
    return (
        <MarkerComponent key={uuid()} lat={latitude} lng={longitude} colorToUse={colorToUse} />
    )
  }

  const MarkerComponent = (props) => {
    return (
      <div style={{ background: props.colorToUse, width: 6, height: 6, zIndex: 1000, borderRadius: '50%' }}  lat={props.latitude} lng={props.longitude}></div>
    )
  }

  const deselectTract = () => {
    setActiveTract(null)
  }

  const deselectMultiTract = (id) => {
    let idx = activeTracts.indexOf(id)
    let tmp = [...activeTracts]
    tmp.splice(idx, 1)
    setActiveTracts(tmp)
  }

  const deselectAggregate = (id) => {
    if (id) {
      let tmp = [...activeTracts]
      let idx = tmp.indexOf(id.toString())
      tmp.splice(idx, 1)
      setActiveTracts(tmp)
    } else {
      setActiveTract(null)
      setActiveTracts([])
    }
  }


  const showWrapper = () => {
    let emptyPoi = poiType === null || poiType.length === 0
    let emptyMulti = multiMetric === null || multiMetric.length === 0 
    let emptySingle = selectedMetric === '' || selectedMetric === null
    return (!emptyMulti) || (!emptySingle) || (!emptyPoi)
  }

  let marks = []
  if (showYearSlider) {
    let metricVal = percentChange ? selectedMetric[0] : selectedMetric
    marks = filteredGeoCategories.find(obj => obj.value === metricVal.substr(0, metricVal.length-5)).years.map((year) => {
      return {
        value: year,
        label: year.toString()
      }
    })
  }


  return (
    <MapPageWrapper>
      <UtilitySectionWrapper>
        <FilterSidepanel
          setActiveTract={setActiveTract}
          setActiveTracts={setActiveTracts}
          setAggregationLevel={setAggregationLevel}
          setIdName={setIdName}
          setLabelName={setLabelName}
          setColorOptionValue={setColorOptionValue}
          setLegend={setLegend}
          aocState={aocState}
          aggregationLevel={aggregationLevel}
          setAocVal={setAocVal}
          setAocSelectLabel={setAocSelectLabel}
          aocSelectLabel={aocSelectLabel}
          colorOptionValue={colorOptionValue}
          setMultiMetric={setMultiMetric}
          setAocState={setAocState}
          setSelectedMetric={setSelectedMetric}
          multiMetric={multiMetric}
          setSelectType={setSelectType}
          selectType={selectType}
          setPoiType={setPoiType}
          selectedMetric={selectedMetric}
          setShowYearSlider={setShowYearSlider}
          setAggregate={setAggregate}
          setPercentChange={setPercentChange}
        />
        <div className="grid" style={{ display: `grid`, gridTemplateColumns: `2fr 0.0fr` }}>
          <div className="container" style={{ position: `relative` }}>
            <div style={{position:"absolute", top: "2em", left: "1em", zIndex: 998}}>
              {showYearSlider && (
                <LegendWrapperNew style={{marginBottom: "1em", minWidth: "400px"}}>
                  <div style={{fontSize: '1.4em', marginBottom: '0.75em', textAlign: 'right', paddingBottom: '0.25em', borderBottom: '1px solid lightgrey'}}>
                    <span style={{float: 'left', lineHeight: '38px'}}>Select year</span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={aggregate}
                          onChange={(ev) => {
                            if (ev.target.checked && percentChange) {
                              setSelectedMetric(selectedMetric[0].substr(0, selectedMetric[0].length-4)+marks[0].value)
                            }
                            setPercentChange(false)
                            setAggregate(ev.target.checked)
                          }}
                          name="aggregate-check"
                          color="primary"
                        />
                      }
                      label="Aggregate?"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={percentChange}
                          onChange={(ev) => {
                            if (ev.target.checked) {
                              const metricBase = selectedMetric.substr(0, selectedMetric.length-4)
                              setSelectedMetric([metricBase+marks[0].value, metricBase+marks[marks.length-1].value])
                            } else {
                              setSelectedMetric(selectedMetric[0].substr(0, selectedMetric[0].length-4)+marks[0].value)
                            }
                            setAggregate(false)
                            setPercentChange(ev.target.checked)
                          }}
                          name="percent-check"
                          color="primary"
                        />
                      }
                      label="% Change"
                    />
                  </div>
                  <div style={{padding: "0 20px"}}>
                    {!percentChange && (
                      <Slider
                        key={selectedMetric.substr(0, selectedMetric.length-5)}
                        defaultValue={marks[0].value}
                        getAriaValueText={(value) => ""+value}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        valueLabelDisplay="auto"
                        marks={marks}
                        min={marks[0].value}
                        max={marks[marks.length-1].value}
                        onChange={(ev, value) => {
                          setSelectedMetric(selectedMetric.substr(0, selectedMetric.length-4)+value)
                        }}
                      />
                    )}
                    {percentChange && (
                      <Slider
                        defaultValue={[marks[0].value, marks[marks.length-1].value]}
                        onChange={(ev, value) => {
                          const metricBase = selectedMetric[0].substr(0, selectedMetric[0].length-4)
                          setSelectedMetric([metricBase+value[0], metricBase+value[1]])
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={(value) => ""+value}
                        step={null}
                        marks={marks}
                        min={marks[0].value}
                        max={marks[marks.length-1].value}
                      />
                    )}
                  </div>
                </LegendWrapperNew>
              )}
              {showWrapper() && (
                <LegendWrapperNew>
                  <p style={{fontSize: '1.4em', marginBottom: '0.75em', textAlign: 'center', paddingBottom: '0.25em', borderBottom: '1px solid lightgrey'}}>{aggMap[aggregationLevel]} level</p>
                  {((multiMetric === null || multiMetric.length === 0) && (selectedMetric)) && <p style={{textAlign: 'center', marginBottom: '0.5em', fontWeight: 500}}>{function() {
                    let label = geoCategories.find(el => el.value === (percentChange ? selectedMetric[0] : selectedMetric)).label
                    return percentChange ? label.substr(0, label.length-5) + " (%change " + selectedMetric[0].slice(-4) + "-" + selectedMetric[1].slice(-4) + ")" : label
                  }()}</p>}
                  <div className="inner" style={{minWidth: multiMetric !== null && multiMetric.length > 0 ? 750 : 0}}>
                    {dcGeo.features && <CalcLabel multiMetric={multiMetric} dcGeo={dcGeo} selectedMetric={selectedMetric} aggregate={aggregate} percentChange={percentChange} legend={legend} />}
                  </div>
                  {poiType && poiType.map((poi, idx) => {
                    return (
                      <div key={uuid()} style={{margin: '0.5em', maxWidth: 600, display: 'flex', alignItems: 'center', marginLeft: 0, marginTop: '0.75em'}}>
                        <div style={{height: 6, width: 6, backgroundColor: colorMap[idx], borderRadius: '50%', marginRight: 5}}></div> <p>=  {poi.label}</p>
                      </div>
                    )
                  })}
                  {aocState && (
                    <div style={{margin: '0.5em', maxWidth: 600, display: 'flex', alignItems: 'center', marginLeft: 0, marginTop: '0.75em'}}>
                      <div style={{height: 10, width: 10, backgroundColor: "white", border: '2px solid #09dbcd',  marginRight: 5}}></div> <p>= {aocSelectLabel.label}</p>
                    </div>
                  )}
                </LegendWrapperNew>
              )}
            </div>
            {!percentChange && selectType === 'Inspect' && (
              <InfoWrapper>
                {activeTract && <CalcInfo idName={idName} deselectTract={deselectTract} dcGeo={dcGeo} aggregationLevel={aggregationLevel} activeTract={activeTract} selectedMetric={selectedMetric} multiMetric={multiMetric} percentChange={percentChange} />}
              </InfoWrapper>
            )}
            {!percentChange && selectType === 'Compare' && (
                <InfoWrapper key={uuid()} style={{left: '2vw'}}>
                  <CompareInfo idName={idName} deselectTract={deselectMultiTract} dcGeo={dcGeo} aggregationLevel={aggregationLevel} activeTract={activeTract} activeTracts={activeTracts} selectedMetric={selectedMetric} multiMetric={multiMetric}  />
                </InfoWrapper>
            )}
            {!percentChange && selectType === 'Aggregate' && (
              <InfoWrapper>
                {activeTract && <AggInfo idName={idName} deselectAggregate={deselectAggregate} dcGeo={dcGeo} aggregationLevel={aggregationLevel} activeTract={activeTract} activeTracts={activeTracts} selectedMetric={selectedMetric} multiMetric={multiMetric}  />}
              </InfoWrapper>
            )}
            {aocState && (
              <div className={classes.rightPanel}>
                <p style={{fontWeight: 600, color: '#565656', fontSize: '0.9em', borderBottom: '1px solid lightgrey', marginBottom: 5, paddingBottom: 5}}>{aocSelectLabel.label} - {aocTracts.length} locations</p>
                <ul style={{listStyleType: 'circle'}}>
                  {aocTracts.map(tract => {
                    let tractNeighborhood = ''
                    if (aggregationLevel === 'censusTracts') {
                      let match = dcGeo.features.find(el => el.properties.TRACT === tract)
                      if (match) tractNeighborhood = `(${match.properties.neighborhood})`
                    }
                    return (
                      <li key={uuid()} style={{padding: 5, listStylePosition: 'inside'}}>{tract} {tractNeighborhood}</li>
                    )
                  })}
                </ul>
              </div>
            )}
            <ExtensibleMap
              markerPoints={markerPoints}
              showTrafficLayer={showTrafficLayer}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              startingRegion={startingRegion}
              setStartingRegion={setStartingRegion}
              geoJSONLayer={geoJSONLayer}
              dcGeo={dcGeo}
              selectedMetric={selectedMetric}
              aggregationLevel={aggregationLevel}
              dcPoints={dcPoints}
              poiType={poiType}
              wardBoundaries={wardBoundaries}
              setActiveTract={setActiveTract}
              multiMetric={multiMetric}
              legend={legend}
              aocState={aocState}
              aocVal={aocVal}
              activeTract={activeTract}
              mapMarkers={mapMarkers}
              setMapMarkers={setMapMarkers}
              selectType={selectType}
              activeTracts={activeTracts}
              setActiveTracts={setActiveTracts}
              idName={idName}
              setAocTracts={setAocTracts}
              labelName={labelName}
              aggregate={aggregate}
              percentChange={percentChange}
            />
          </div>
        </div>
      </UtilitySectionWrapper>
    </MapPageWrapper>
  )
}



export default CityX;
