// const interpolateColor = (color1, color2, factor) => {

//     var result = color1.slice()
//     for (var i = 0; i < 3; i++) {
//         result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
//     }
//     return result
// };

// const interpolateColors = (color1, color2, steps) => {
//     var stepFactor = 1 / (steps - 1),
//         interpolatedColorArray = []

//     color1 = color1.match(/\d+/g).map(Number)
//     color2 = color2.match(/\d+/g).map(Number)

//     for(var i = 0; i < steps; i++) {
//         interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
//     }

//     return interpolatedColorArray;
// }


// const colorArray = interpolateColors("rgb(252, 3, 11)", "rgb(3, 252, 61)", 101)
// const colorArray = interpolateColors("rgb(99, 20, 130)", "rgb(247, 243, 0)", 101)

// const colorArray = interpolateColors("rgb(12, 204, 63)", "rgb(13, 122, 224)", 101)
// console.log(colorArray)
// console.log(colorArray[100])


// red and green
export const redGreenColors = [ 
    [ 252, 3, 11, 1 ],
    [ 250, 5, 12, 1 ],
    [ 247, 8, 12, 1 ],
    [ 245, 10, 13, 1 ],
    [ 242, 13, 13, 1 ],
    [ 240, 15, 14, 1 ],
    [ 237, 18, 14, 1 ],
    [ 235, 20, 15, 1 ],
    [ 232, 23, 15, 1 ],
    [ 230, 25, 16, 1 ],
    [ 227, 28, 16, 1 ],
    [ 225, 30, 17, 1 ],
    [ 222, 33, 17, 1 ],
    [ 220, 35, 18, 1 ],
    [ 217, 38, 18, 1 ],
    [ 215, 40, 19, 1 ],
    [ 212, 43, 19, 1 ],
    [ 210, 45, 20, 1 ],
    [ 207, 48, 20, 1 ],
    [ 205, 50, 21, 1 ],
    [ 202, 53, 21, 1 ],
    [ 200, 55, 22, 1 ],
    [ 197, 58, 22, 1 ],
    [ 195, 60, 23, 1 ],
    [ 192, 63, 23, 1 ],
    [ 190, 65, 24, 1 ],
    [ 187, 68, 24, 1 ],
    [ 185, 70, 25, 1 ],
    [ 182, 73, 25, 1 ],
    [ 180, 75, 26, 1 ],
    [ 177, 78, 26, 1 ],
    [ 175, 80, 27, 1 ],
    [ 172, 83, 27, 1 ],
    [ 170, 85, 28, 1 ],
    [ 167, 88, 28, 1 ],
    [ 165, 90, 29, 1 ],
    [ 162, 93, 29, 1 ],
    [ 160, 95, 30, 1 ],
    [ 157, 98, 30, 1 ],
    [ 155, 100, 31, 1 ],
    [ 152, 103, 31, 1 ],
    [ 150, 105, 32, 1 ],
    [ 147, 108, 32, 1 ],
    [ 145, 110, 33, 1 ],
    [ 142, 113, 33, 1 ],
    [ 140, 115, 34, 1 ],
    [ 137, 118, 34, 1 ],
    [ 135, 120, 35, 1 ],
    [ 132, 123, 35, 1 ],
    [ 130, 125, 36, 1 ],
    [ 128, 128, 36, 1 ],
    [ 125, 130, 37, 1 ],
    [ 123, 132, 37, 1 ],
    [ 120, 135, 38, 1 ],
    [ 118, 137, 38, 1 ],
    [ 115, 140, 39, 1 ],
    [ 113, 142, 39, 1 ],
    [ 110, 145, 40, 1 ],
    [ 108, 147, 40, 1 ],
    [ 105, 150, 41, 1 ],
    [ 103, 152, 41, 1 ],
    [ 100, 155, 42, 1 ],
    [ 98, 157, 42, 1 ],
    [ 95, 160, 43, 1 ],
    [ 93, 162, 43, 1 ],
    [ 90, 165, 44, 1 ],
    [ 88, 167, 44, 1 ],
    [ 85, 170, 45, 1 ],
    [ 83, 172, 45, 1 ],
    [ 80, 175, 46, 1 ],
    [ 78, 177, 46, 1 ],
    [ 75, 180, 47, 1 ],
    [ 73, 182, 47, 1 ],
    [ 70, 185, 48, 1 ],
    [ 68, 187, 48, 1 ],
    [ 65, 190, 49, 1 ],
    [ 63, 192, 49, 1 ],
    [ 60, 195, 50, 1 ],
    [ 58, 197, 50, 1 ],
    [ 55, 200, 51, 1 ],
    [ 53, 202, 51, 1 ],
    [ 50, 205, 52, 1 ],
    [ 48, 207, 52, 1 ],
    [ 45, 210, 53, 1 ],
    [ 43, 212, 53, 1 ],
    [ 40, 215, 54, 1 ],
    [ 38, 217, 54, 1 ],
    [ 35, 220, 55, 1 ],
    [ 33, 222, 55, 1 ],
    [ 30, 225, 56, 1 ],
    [ 28, 227, 56, 1 ],
    [ 25, 230, 57, 1 ],
    [ 23, 232, 57, 1 ],
    [ 20, 235, 58, 1 ],
    [ 18, 237, 58, 1 ],
    [ 15, 240, 59, 1 ],
    [ 13, 242, 59, 1 ],
    [ 10, 245, 60, 1 ],
    [ 8, 247, 60, 1 ],
    [ 5, 250, 61, 1 ],
    [ 3, 252, 61, 1 ]
]

//yellow and purple
export const yellowPurpleColors = [ 
[ 99, 20, 130, 1 ],
[ 100, 22, 129, 1 ],
[ 102, 24, 127, 1 ],
[ 103, 27, 126, 1 ],
[ 105, 29, 125, 1 ],
[ 106, 31, 124, 1 ],
[ 108, 33, 122, 1 ],
[ 109, 36, 121, 1 ],
[ 111, 38, 120, 1 ],
[ 112, 40, 118, 1 ],
[ 114, 42, 117, 1 ],
[ 115, 45, 116, 1 ],
[ 117, 47, 114, 1 ],
[ 118, 49, 113, 1 ],
[ 120, 51, 112, 1 ],
[ 121, 53, 111, 1 ],
[ 123, 56, 109, 1 ],
[ 124, 58, 108, 1 ],
[ 126, 60, 107, 1 ],
[ 127, 62, 105, 1 ],
[ 129, 65, 104, 1 ],
[ 130, 67, 103, 1 ],
[ 132, 69, 101, 1 ],
[ 133, 71, 100, 1 ],
[ 135, 74, 99, 1 ],
[ 136, 76, 98, 1 ],
[ 137, 78, 96, 1 ],
[ 139, 80, 95, 1 ],
[ 140, 82, 94, 1 ],
[ 142, 85, 92, 1 ],
[ 143, 87, 91, 1 ],
[ 145, 89, 90, 1 ],
[ 146, 91, 88, 1 ],
[ 148, 94, 87, 1 ],
[ 149, 96, 86, 1 ],
[ 151, 98, 85, 1 ],
[ 152, 100, 83, 1 ],
[ 154, 103, 82, 1 ],
[ 155, 105, 81, 1 ],
[ 157, 107, 79, 1 ],
[ 158, 109, 78, 1 ],
[ 160, 111, 77, 1 ],
[ 161, 114, 75, 1 ],
[ 163, 116, 74, 1 ],
[ 164, 118, 73, 1 ],
[ 166, 120, 72, 1 ],
[ 167, 123, 70, 1 ],
[ 169, 125, 69, 1 ],
[ 170, 127, 68, 1 ],
[ 172, 129, 66, 1 ],
[ 173, 132, 65, 1 ],
[ 174, 134, 64, 1 ],
[ 176, 136, 62, 1 ],
[ 177, 138, 61, 1 ],
[ 179, 140, 60, 1 ],
[ 180, 143, 59, 1 ],
[ 182, 145, 57, 1 ],
[ 183, 147, 56, 1 ],
[ 185, 149, 55, 1 ],
[ 186, 152, 53, 1 ],
[ 188, 154, 52, 1 ],
[ 189, 156, 51, 1 ],
[ 191, 158, 49, 1 ],
[ 192, 160, 48, 1 ],
[ 194, 163, 47, 1 ],
[ 195, 165, 46, 1 ],
[ 197, 167, 44, 1 ],
[ 198, 169, 43, 1 ],
[ 200, 172, 42, 1 ],
[ 201, 174, 40, 1 ],
[ 203, 176, 39, 1 ],
[ 204, 178, 38, 1 ],
[ 206, 181, 36, 1 ],
[ 207, 183, 35, 1 ],
[ 209, 185, 34, 1 ],
[ 210, 187, 33, 1 ],
[ 211, 189, 31, 1 ],
[ 213, 192, 30, 1 ],
[ 214, 194, 29, 1 ],
[ 216, 196, 27, 1 ],
[ 217, 198, 26, 1 ],
[ 219, 201, 25, 1 ],
[ 220, 203, 23, 1 ],
[ 222, 205, 22, 1 ],
[ 223, 207, 21, 1 ],
[ 225, 210, 20, 1 ],
[ 226, 212, 18, 1 ],
[ 228, 214, 17, 1 ],
[ 229, 216, 16, 1 ],
[ 231, 218, 14, 1 ],
[ 232, 221, 13, 1 ],
[ 234, 223, 12, 1 ],
[ 235, 225, 10, 1 ],
[ 237, 227, 9, 1 ],
[ 238, 230, 8, 1 ],
[ 240, 232, 6, 1 ],
[ 241, 234, 5, 1 ],
[ 243, 236, 4, 1 ],
[ 244, 239, 3, 1 ],
[ 246, 241, 1, 1 ],
[ 247, 243, 0, 1 ]
]

//green and blue
export const processedColors =[ 
  [ 12, 204, 63, 1 ],
  [ 12, 203, 65, 1 ],
  [ 12, 202, 66, 1 ],
  [ 12, 202, 68, 1 ],
  [ 12, 201, 69, 1 ],
  [ 12, 200, 71, 1 ],
  [ 12, 199, 73, 1 ],
  [ 12, 198, 74, 1 ],
  [ 12, 197, 76, 1 ],
  [ 12, 197, 77, 1 ],
  [ 12, 196, 79, 1 ],
  [ 12, 195, 81, 1 ],
  [ 12, 194, 82, 1 ],
  [ 12, 193, 84, 1 ],
  [ 12, 193, 86, 1 ],
  [ 12, 192, 87, 1 ],
  [ 12, 191, 89, 1 ],
  [ 12, 190, 90, 1 ],
  [ 12, 189, 92, 1 ],
  [ 12, 188, 94, 1 ],
  [ 12, 188, 95, 1 ],
  [ 12, 187, 97, 1 ],
  [ 12, 186, 98, 1 ],
  [ 12, 185, 100, 1 ],
  [ 12, 184, 102, 1 ],
  [ 12, 184, 103, 1 ],
  [ 12, 183, 105, 1 ],
  [ 12, 182, 106, 1 ],
  [ 12, 181, 108, 1 ],
  [ 12, 180, 110, 1 ],
  [ 12, 179, 111, 1 ],
  [ 12, 179, 113, 1 ],
  [ 12, 178, 115, 1 ],
  [ 12, 177, 116, 1 ],
  [ 12, 176, 118, 1 ],
  [ 12, 175, 119, 1 ],
  [ 12, 174, 121, 1 ],
  [ 12, 174, 123, 1 ],
  [ 12, 173, 124, 1 ],
  [ 12, 172, 126, 1 ],
  [ 12, 171, 127, 1 ],
  [ 12, 170, 129, 1 ],
  [ 12, 170, 131, 1 ],
  [ 12, 169, 132, 1 ],
  [ 12, 168, 134, 1 ],
  [ 12, 167, 135, 1 ],
  [ 12, 166, 137, 1 ],
  [ 12, 165, 139, 1 ],
  [ 12, 165, 140, 1 ],
  [ 12, 164, 142, 1 ],
  [ 13, 163, 144, 1 ],
  [ 13, 162, 145, 1 ],
  [ 13, 161, 147, 1 ],
  [ 13, 161, 148, 1 ],
  [ 13, 160, 150, 1 ],
  [ 13, 159, 152, 1 ],
  [ 13, 158, 153, 1 ],
  [ 13, 157, 155, 1 ],
  [ 13, 156, 156, 1 ],
  [ 13, 156, 158, 1 ],
  [ 13, 155, 160, 1 ],
  [ 13, 154, 161, 1 ],
  [ 13, 153, 163, 1 ],
  [ 13, 152, 164, 1 ],
  [ 13, 152, 166, 1 ],
  [ 13, 151, 168, 1 ],
  [ 13, 150, 169, 1 ],
  [ 13, 149, 171, 1 ],
  [ 13, 148, 172, 1 ],
  [ 13, 147, 174, 1 ],
  [ 13, 147, 176, 1 ],
  [ 13, 146, 177, 1 ],
  [ 13, 145, 179, 1 ],
  [ 13, 144, 181, 1 ],
  [ 13, 143, 182, 1 ],
  [ 13, 143, 184, 1 ],
  [ 13, 142, 185, 1 ],
  [ 13, 141, 187, 1 ],
  [ 13, 140, 189, 1 ],
  [ 13, 139, 190, 1 ],
  [ 13, 138, 192, 1 ],
  [ 13, 138, 193, 1 ],
  [ 13, 137, 195, 1 ],
  [ 13, 136, 197, 1 ],
  [ 13, 135, 198, 1 ],
  [ 13, 134, 200, 1 ],
  [ 13, 133, 201, 1 ],
  [ 13, 133, 203, 1 ],
  [ 13, 132, 205, 1 ],
  [ 13, 131, 206, 1 ],
  [ 13, 130, 208, 1 ],
  [ 13, 129, 210, 1 ],
  [ 13, 129, 211, 1 ],
  [ 13, 128, 213, 1 ],
  [ 13, 127, 214, 1 ],
  [ 13, 126, 216, 1 ],
  [ 13, 125, 218, 1 ],
  [ 13, 124, 219, 1 ],
  [ 13, 124, 221, 1 ],
  [ 13, 123, 222, 1 ],
  [ 13, 122, 224, 1 ]
]



// export const orangeColors =[ 
//     [ 255, 145, 0, .01 ],
//     [ 255, 145, 0, .02 ],
//     [ 255, 145, 0, .03 ],
//     [ 255, 145, 0, .04 ],
//     [ 255, 145, 0, .05 ],
//     [ 255, 145, 0, .06 ],
//     [ 255, 145, 0, .07 ],
//     [ 255, 145, 0, .08 ],
//     [ 255, 145, 0, .09 ],
//     [ 255, 145, 0, .10 ],
//     [ 255, 145, 0, .11 ],
//     [ 255, 145, 0, .12 ],
//     [ 255, 145, 0, .13 ],
//     [ 255, 145, 0, .14 ],
//     [ 255, 145, 0, .15 ],
//     [ 255, 145, 0, .16 ],
//     [ 255, 145, 0, .17 ],
//     [ 255, 145, 0, .18 ],
//     [ 255, 145, 0, .19 ],
//     [ 255, 145, 0, .20 ],
//     [ 255, 145, 0, .21 ],
//     [ 255, 145, 0, .22 ],
//     [ 255, 145, 0, .23 ],
//     [ 255, 145, 0, .24 ],
//     [ 255, 145, 0, .25 ],
//     [ 255, 145, 0, .26 ],
//     [ 255, 145, 0, .27 ],
//     [ 255, 145, 0, .28 ],
//     [ 255, 145, 0, .29 ],
//     [ 255, 145, 0, .30 ],
//     [ 255, 145, 0, .31 ],
//     [ 255, 145, 0, .32 ],
//     [ 255, 145, 0, .33 ],
//     [ 255, 145, 0, .34 ],
//     [ 255, 145, 0, .35 ],
//     [ 255, 145, 0, .36 ],
//     [ 255, 145, 0, .37 ],
//     [ 255, 145, 0, .38 ],
//     [ 255, 145, 0, .39 ],
//     [ 255, 145, 0, .40 ],
//     [ 255, 145, 0, .41 ],
//     [ 255, 145, 0, .42 ],
//     [ 255, 145, 0, .43 ],
//     [ 255, 145, 0, .44 ],
//     [ 255, 145, 0, .45 ],
//     [ 255, 145, 0, .46 ],
//     [ 255, 145, 0, .47 ],
//     [ 255, 145, 0, .48 ],
//     [ 255, 145, 0, .49 ],
//     [ 255, 145, 0, .50 ],
//     [ 255, 145, 0, .51 ],
//     [ 255, 145, 0, .52 ],
//     [ 255, 145, 0, .53 ],
//     [ 255, 145, 0, .54 ],
//     [ 255, 145, 0, .55 ],
//     [ 255, 145, 0, .56 ],
//     [ 255, 145, 0, .57 ],
//     [ 255, 145, 0, .58 ],
//     [ 255, 145, 0, .59 ],
//     [ 255, 145, 0, .60 ],
//     [ 255, 145, 0, .61 ],
//     [ 255, 145, 0, .62 ],
//     [ 255, 145, 0, .63 ],
//     [ 255, 145, 0, .64 ],
//     [ 255, 145, 0, .65 ],
//     [ 255, 145, 0, .66 ],
//     [ 255, 145, 0, .67 ],
//     [ 255, 145, 0, .68 ],
//     [ 255, 145, 0, .69 ],
//     [ 255, 145, 0, .70 ],
//     [ 255, 145, 0, .71 ],
//     [ 255, 145, 0, .72 ],
//     [ 255, 145, 0, .73 ],
//     [ 255, 145, 0, .74 ],
//     [ 255, 145, 0, .75 ],
//     [ 255, 145, 0, .76 ],
//     [ 255, 145, 0, .77 ],
//     [ 255, 145, 0, .78 ],
//     [ 255, 145, 0, .79 ],
//     [ 255, 145, 0, .80 ],
//     [ 255, 145, 0, .81 ],
//     [ 255, 145, 0, .82 ],
//     [ 255, 145, 0, .83 ],
//     [ 255, 145, 0, .84 ],
//     [ 255, 145, 0, .85 ],
//     [ 255, 145, 0, .86 ],
//     [ 255, 145, 0, .87 ],
//     [ 255, 145, 0, .88 ],
//     [ 255, 145, 0, .89 ],
//     [ 255, 145, 0, .90 ],
//     [ 255, 145, 0, .91 ],
//     [ 255, 145, 0, .92 ],
//     [ 255, 145, 0, .93 ],
//     [ 255, 145, 0, .94 ],
//     [ 255, 145, 0, .95 ],
//     [ 255, 145, 0, .96 ],
//     [ 255, 145, 0, .97 ],
//     [ 255, 145, 0, .98 ],
//     [ 255, 145, 0, .99 ],
//     [ 255, 145, 0, 1 ],
//     [ 255, 145, 0, 1 ]
//   ]

//   export const orangeColors = [ [ 255, 145, 0, 1 ],
//   [ 255, 145, 0, 1 ],
//   [ 255, 145, 0, 0.99 ],
//   [ 255, 145, 0, 0.98 ],
//   [ 255, 145, 0, 0.97 ],
//   [ 255, 145, 0, 0.96 ],
//   [ 255, 145, 0, 0.95 ],
//   [ 255, 145, 0, 0.94 ],
//   [ 255, 145, 0, 0.93 ],
//   [ 255, 145, 0, 0.92 ],
//   [ 255, 145, 0, 0.91 ],
//   [ 255, 145, 0, 0.9 ],
//   [ 255, 145, 0, 0.89 ],
//   [ 255, 145, 0, 0.88 ],
//   [ 255, 145, 0, 0.87 ],
//   [ 255, 145, 0, 0.86 ],
//   [ 255, 145, 0, 0.85 ],
//   [ 255, 145, 0, 0.84 ],
//   [ 255, 145, 0, 0.83 ],
//   [ 255, 145, 0, 0.82 ],
//   [ 255, 145, 0, 0.81 ],
//   [ 255, 145, 0, 0.8 ],
//   [ 255, 145, 0, 0.79 ],
//   [ 255, 145, 0, 0.78 ],
//   [ 255, 145, 0, 0.77 ],
//   [ 255, 145, 0, 0.76 ],
//   [ 255, 145, 0, 0.75 ],
//   [ 255, 145, 0, 0.74 ],
//   [ 255, 145, 0, 0.73 ],
//   [ 255, 145, 0, 0.72 ],
//   [ 255, 145, 0, 0.71 ],
//   [ 255, 145, 0, 0.7 ],
//   [ 255, 145, 0, 0.69 ],
//   [ 255, 145, 0, 0.68 ],
//   [ 255, 145, 0, 0.67 ],
//   [ 255, 145, 0, 0.66 ],
//   [ 255, 145, 0, 0.65 ],
//   [ 255, 145, 0, 0.64 ],
//   [ 255, 145, 0, 0.63 ],
//   [ 255, 145, 0, 0.62 ],
//   [ 255, 145, 0, 0.61 ],
//   [ 255, 145, 0, 0.6 ],
//   [ 255, 145, 0, 0.59 ],
//   [ 255, 145, 0, 0.58 ],
//   [ 255, 145, 0, 0.57 ],
//   [ 255, 145, 0, 0.56 ],
//   [ 255, 145, 0, 0.55 ],
//   [ 255, 145, 0, 0.54 ],
//   [ 255, 145, 0, 0.53 ],
//   [ 255, 145, 0, 0.52 ],
//   [ 255, 145, 0, 0.51 ],
//   [ 255, 145, 0, 0.5 ],
//   [ 255, 145, 0, 0.49 ],
//   [ 255, 145, 0, 0.48 ],
//   [ 255, 145, 0, 0.47 ],
//   [ 255, 145, 0, 0.46 ],
//   [ 255, 145, 0, 0.45 ],
//   [ 255, 145, 0, 0.44 ],
//   [ 255, 145, 0, 0.43 ],
//   [ 255, 145, 0, 0.42 ],
//   [ 255, 145, 0, 0.41 ],
//   [ 255, 145, 0, 0.4 ],
//   [ 255, 145, 0, 0.39 ],
//   [ 255, 145, 0, 0.38 ],
//   [ 255, 145, 0, 0.37 ],
//   [ 255, 145, 0, 0.36 ],
//   [ 255, 145, 0, 0.35 ],
//   [ 255, 145, 0, 0.34 ],
//   [ 255, 145, 0, 0.33 ],
//   [ 255, 145, 0, 0.32 ],
//   [ 255, 145, 0, 0.31 ],
//   [ 255, 145, 0, 0.3 ],
//   [ 255, 145, 0, 0.29 ],
//   [ 255, 145, 0, 0.28 ],
//   [ 255, 145, 0, 0.27 ],
//   [ 255, 145, 0, 0.26 ],
//   [ 255, 145, 0, 0.25 ],
//   [ 255, 145, 0, 0.24 ],
//   [ 255, 145, 0, 0.23 ],
//   [ 255, 145, 0, 0.22 ],
//   [ 255, 145, 0, 0.21 ],
//   [ 255, 145, 0, 0.2 ],
//   [ 255, 145, 0, 0.19 ],
//   [ 255, 145, 0, 0.18 ],
//   [ 255, 145, 0, 0.17 ],
//   [ 255, 145, 0, 0.16 ],
//   [ 255, 145, 0, 0.15 ],
//   [ 255, 145, 0, 0.14 ],
//   [ 255, 145, 0, 0.13 ],
//   [ 255, 145, 0, 0.12 ],
//   [ 255, 145, 0, 0.11 ],
//   [ 255, 145, 0, 0.1 ],
//   [ 255, 145, 0, 0.09 ],
//   [ 255, 145, 0, 0.08 ],
//   [ 255, 145, 0, 0.07 ],
//   [ 255, 145, 0, 0.06 ],
//   [ 255, 145, 0, 0.05 ],
//   [ 255, 145, 0, 0.04 ],
//   [ 255, 145, 0, 0.03 ],
//   [ 255, 145, 0, 0.02 ],
//   [ 255, 145, 0, 0.01],
//   ]

export const orangeColors = [ 
  [ 255, 145, 0, 1 ],
  [ 255, 145, 0, 0.992 ],
  [ 255, 145, 0, 0.984 ],
  [ 255, 145, 0, 0.976 ],
  [ 255, 145, 0, 0.968 ],
  [ 255, 145, 0, 0.96 ],
  [ 255, 145, 0, 0.952 ],
  [ 255, 145, 0, 0.944 ],
  [ 255, 145, 0, 0.9359999999999999 ],
  [ 255, 145, 0, 0.9279999999999999 ],
  [ 255, 145, 0, 0.9199999999999999 ],
  [ 255, 145, 0, 0.9119999999999999 ],
  [ 255, 145, 0, 0.9039999999999999 ],
  [ 255, 145, 0, 0.8959999999999999 ],
  [ 255, 145, 0, 0.8879999999999999 ],
  [ 255, 145, 0, 0.8799999999999999 ],
  [ 255, 145, 0, 0.8719999999999999 ],
  [ 255, 145, 0, 0.8639999999999999 ],
  [ 255, 145, 0, 0.8559999999999999 ],
  [ 255, 145, 0, 0.8479999999999999 ],
  [ 255, 145, 0, 0.8399999999999999 ],
  [ 255, 145, 0, 0.8319999999999999 ],
  [ 255, 145, 0, 0.8239999999999998 ],
  [ 255, 145, 0, 0.8159999999999998 ],
  [ 255, 145, 0, 0.8079999999999998 ],
  [ 255, 145, 0, 0.7999999999999998 ],
  [ 255, 145, 0, 0.7919999999999998 ],
  [ 255, 145, 0, 0.7839999999999998 ],
  [ 255, 145, 0, 0.7759999999999998 ],
  [ 255, 145, 0, 0.7679999999999998 ],
  [ 255, 145, 0, 0.7599999999999998 ],
  [ 255, 145, 0, 0.7519999999999998 ],
  [ 255, 145, 0, 0.7439999999999998 ],
  [ 255, 145, 0, 0.7359999999999998 ],
  [ 255, 145, 0, 0.7279999999999998 ],
  [ 255, 145, 0, 0.7199999999999998 ],
  [ 255, 145, 0, 0.7119999999999997 ],
  [ 255, 145, 0, 0.7039999999999997 ],
  [ 255, 145, 0, 0.6959999999999997 ],
  [ 255, 145, 0, 0.6879999999999997 ],
  [ 255, 145, 0, 0.6799999999999997 ],
  [ 255, 145, 0, 0.6719999999999997 ],
  [ 255, 145, 0, 0.6639999999999997 ],
  [ 255, 145, 0, 0.6559999999999997 ],
  [ 255, 145, 0, 0.6479999999999997 ],
  [ 255, 145, 0, 0.6399999999999997 ],
  [ 255, 145, 0, 0.6319999999999997 ],
  [ 255, 145, 0, 0.6239999999999997 ],
  [ 255, 145, 0, 0.6159999999999997 ],
  [ 255, 145, 0, 0.6079999999999997 ],
  [ 255, 145, 0, 0.5999999999999996 ],
  [ 255, 145, 0, 0.5919999999999996 ],
  [ 255, 145, 0, 0.5839999999999996 ],
  [ 255, 145, 0, 0.5759999999999996 ],
  [ 255, 145, 0, 0.5679999999999996 ],
  [ 255, 145, 0, 0.5599999999999996 ],
  [ 255, 145, 0, 0.5519999999999996 ],
  [ 255, 145, 0, 0.5439999999999996 ],
  [ 255, 145, 0, 0.5359999999999996 ],
  [ 255, 145, 0, 0.5279999999999996 ],
  [ 255, 145, 0, 0.5199999999999996 ],
  [ 255, 145, 0, 0.5119999999999996 ],
  [ 255, 145, 0, 0.5039999999999996 ],
  [ 255, 145, 0, 0.49599999999999955 ],
  [ 255, 145, 0, 0.48799999999999955 ],
  [ 255, 145, 0, 0.47999999999999954 ],
  [ 255, 145, 0, 0.47199999999999953 ],
  [ 255, 145, 0, 0.4639999999999995 ],
  [ 255, 145, 0, 0.4559999999999995 ],
  [ 255, 145, 0, 0.4479999999999995 ],
  [ 255, 145, 0, 0.4399999999999995 ],
  [ 255, 145, 0, 0.4319999999999995 ],
  [ 255, 145, 0, 0.4239999999999995 ],
  [ 255, 145, 0, 0.4159999999999995 ],
  [ 255, 145, 0, 0.4079999999999995 ],
  [ 255, 145, 0, 0.39999999999999947 ],
  [ 255, 145, 0, 0.39199999999999946 ],
  [ 255, 145, 0, 0.38399999999999945 ],
  [ 255, 145, 0, 0.37599999999999945 ],
  [ 255, 145, 0, 0.36799999999999944 ],
  [ 255, 145, 0, 0.35999999999999943 ],
  [ 255, 145, 0, 0.3519999999999994 ],
  [ 255, 145, 0, 0.3439999999999994 ],
  [ 255, 145, 0, 0.3359999999999994 ],
  [ 255, 145, 0, 0.3279999999999994 ],
  [ 255, 145, 0, 0.3199999999999994 ],
  [ 255, 145, 0, 0.3119999999999994 ],
  [ 255, 145, 0, 0.3039999999999994 ],
  [ 255, 145, 0, 0.2959999999999994 ],
  [ 255, 145, 0, 0.28799999999999937 ],
  [ 255, 145, 0, 0.27999999999999936 ],
  [ 255, 145, 0, 0.27199999999999935 ],
  [ 255, 145, 0, 0.26399999999999935 ],
  [ 255, 145, 0, 0.25599999999999934 ],
  [ 255, 145, 0, 0.24799999999999933 ],
  [ 255, 145, 0, 0.23999999999999932 ],
  [ 255, 145, 0, 0.23199999999999932 ],
  [ 255, 145, 0, 0.2239999999999993 ],
  [ 255, 145, 0, 0.2159999999999993 ],
  [ 255, 145, 0, 0.2079999999999993 ],
  [ 255, 145, 0, 0.20 ],
]

export const purpleColors = [ 
    [ 173, 3, 252, 1 ],
    [ 173, 3, 252, 0.992 ],
    [ 173, 3, 252, 0.984 ],
    [ 173, 3, 252, 0.976 ],
    [ 173, 3, 252, 0.968 ],
    [ 173, 3, 252, 0.96 ],
    [ 173, 3, 252, 0.952 ],
    [ 173, 3, 252, 0.944 ],
    [ 173, 3, 252, 0.9359999999999999 ],
    [ 173, 3, 252, 0.9279999999999999 ],
    [ 173, 3, 252, 0.9199999999999999 ],
    [ 173, 3, 252, 0.9119999999999999 ],
    [ 173, 3, 252, 0.9039999999999999 ],
    [ 173, 3, 252, 0.8959999999999999 ],
    [ 173, 3, 252, 0.8879999999999999 ],
    [ 173, 3, 252, 0.8799999999999999 ],
    [ 173, 3, 252, 0.8719999999999999 ],
    [ 173, 3, 252, 0.8639999999999999 ],
    [ 173, 3, 252, 0.8559999999999999 ],
    [ 173, 3, 252, 0.8479999999999999 ],
    [ 173, 3, 252, 0.8399999999999999 ],
    [ 173, 3, 252, 0.8319999999999999 ],
    [ 173, 3, 252, 0.8239999999999998 ],
    [ 173, 3, 252, 0.8159999999999998 ],
    [ 173, 3, 252, 0.8079999999999998 ],
    [ 173, 3, 252, 0.7999999999999998 ],
    [ 173, 3, 252, 0.7919999999999998 ],
    [ 173, 3, 252, 0.7839999999999998 ],
    [ 173, 3, 252, 0.7759999999999998 ],
    [ 173, 3, 252, 0.7679999999999998 ],
    [ 173, 3, 252, 0.7599999999999998 ],
    [ 173, 3, 252, 0.7519999999999998 ],
    [ 173, 3, 252, 0.7439999999999998 ],
    [ 173, 3, 252, 0.7359999999999998 ],
    [ 173, 3, 252, 0.7279999999999998 ],
    [ 173, 3, 252, 0.7199999999999998 ],
    [ 173, 3, 252, 0.7119999999999997 ],
    [ 173, 3, 252, 0.7039999999999997 ],
    [ 173, 3, 252, 0.6959999999999997 ],
    [ 173, 3, 252, 0.6879999999999997 ],
    [ 173, 3, 252, 0.6799999999999997 ],
    [ 173, 3, 252, 0.6719999999999997 ],
    [ 173, 3, 252, 0.6639999999999997 ],
    [ 173, 3, 252, 0.6559999999999997 ],
    [ 173, 3, 252, 0.6479999999999997 ],
    [ 173, 3, 252, 0.6399999999999997 ],
    [ 173, 3, 252, 0.6319999999999997 ],
    [ 173, 3, 252, 0.6239999999999997 ],
    [ 173, 3, 252, 0.6159999999999997 ],
    [ 173, 3, 252, 0.6079999999999997 ],
    [ 173, 3, 252, 0.5999999999999996 ],
    [ 173, 3, 252, 0.5919999999999996 ],
    [ 173, 3, 252, 0.5839999999999996 ],
    [ 173, 3, 252, 0.5759999999999996 ],
    [ 173, 3, 252, 0.5679999999999996 ],
    [ 173, 3, 252, 0.5599999999999996 ],
    [ 173, 3, 252, 0.5519999999999996 ],
    [ 173, 3, 252, 0.5439999999999996 ],
    [ 173, 3, 252, 0.5359999999999996 ],
    [ 173, 3, 252, 0.5279999999999996 ],
    [ 173, 3, 252, 0.5199999999999996 ],
    [ 173, 3, 252, 0.5119999999999996 ],
    [ 173, 3, 252, 0.5039999999999996 ],
    [ 173, 3, 252, 0.49599999999999955 ],
    [ 173, 3, 252, 0.48799999999999955 ],
    [ 173, 3, 252, 0.47999999999999954 ],
    [ 173, 3, 252, 0.47199999999999953 ],
    [ 173, 3, 252, 0.4639999999999995 ],
    [ 173, 3, 252, 0.4559999999999995 ],
    [ 173, 3, 252, 0.4479999999999995 ],
    [ 173, 3, 252, 0.4399999999999995 ],
    [ 173, 3, 252, 0.4319999999999995 ],
    [ 173, 3, 252, 0.4239999999999995 ],
    [ 173, 3, 252, 0.4159999999999995 ],
    [ 173, 3, 252, 0.4079999999999995 ],
    [ 173, 3, 252, 0.39999999999999947 ],
    [ 173, 3, 252, 0.39199999999999946 ],
    [ 173, 3, 252, 0.38399999999999945 ],
    [ 173, 3, 252, 0.37599999999999945 ],
    [ 173, 3, 252, 0.36799999999999944 ],
    [ 173, 3, 252, 0.35999999999999943 ],
    [ 173, 3, 252, 0.3519999999999994 ],
    [ 173, 3, 252, 0.3439999999999994 ],
    [ 173, 3, 252, 0.3359999999999994 ],
    [ 173, 3, 252, 0.3279999999999994 ],
    [ 173, 3, 252, 0.3199999999999994 ],
    [ 173, 3, 252, 0.3119999999999994 ],
    [ 173, 3, 252, 0.3039999999999994 ],
    [ 173, 3, 252, 0.2959999999999994 ],
    [ 173, 3, 252, 0.28799999999999937 ],
    [ 173, 3, 252, 0.27999999999999936 ],
    [ 173, 3, 252, 0.27199999999999935 ],
    [ 173, 3, 252, 0.26399999999999935 ],
    [ 173, 3, 252, 0.25599999999999934 ],
    [ 173, 3, 252, 0.24799999999999933 ],
    [ 173, 3, 252, 0.23999999999999932 ],
    [ 173, 3, 252, 0.23199999999999932 ],
    [ 173, 3, 252, 0.2239999999999993 ],
    [ 173, 3, 252, 0.2159999999999993 ],
    [ 173, 3, 252, 0.2079999999999993 ],
    [ 173, 3, 252, 0.20 ],
  ]

  export const blueColors = [ 
    [ 3, 136, 252, 1 ],
    [ 3, 136, 252, 0.992 ],
    [ 3, 136, 252, 0.984 ],
    [ 3, 136, 252, 0.976 ],
    [ 3, 136, 252, 0.968 ],
    [ 3, 136, 252, 0.96 ],
    [ 3, 136, 252, 0.952 ],
    [ 3, 136, 252, 0.944 ],
    [ 3, 136, 252, 0.9359999999999999 ],
    [ 3, 136, 252, 0.9279999999999999 ],
    [ 3, 136, 252, 0.9199999999999999 ],
    [ 3, 136, 252, 0.9119999999999999 ],
    [ 3, 136, 252, 0.9039999999999999 ],
    [ 3, 136, 252, 0.8959999999999999 ],
    [ 3, 136, 252, 0.8879999999999999 ],
    [ 3, 136, 252, 0.8799999999999999 ],
    [ 3, 136, 252, 0.8719999999999999 ],
    [ 3, 136, 252, 0.8639999999999999 ],
    [ 3, 136, 252, 0.8559999999999999 ],
    [ 3, 136, 252, 0.8479999999999999 ],
    [ 3, 136, 252, 0.8399999999999999 ],
    [ 3, 136, 252, 0.8319999999999999 ],
    [ 3, 136, 252, 0.8239999999999998 ],
    [ 3, 136, 252, 0.8159999999999998 ],
    [ 3, 136, 252, 0.8079999999999998 ],
    [ 3, 136, 252, 0.7999999999999998 ],
    [ 3, 136, 252, 0.7919999999999998 ],
    [ 3, 136, 252, 0.7839999999999998 ],
    [ 3, 136, 252, 0.7759999999999998 ],
    [ 3, 136, 252, 0.7679999999999998 ],
    [ 3, 136, 252, 0.7599999999999998 ],
    [ 3, 136, 252, 0.7519999999999998 ],
    [ 3, 136, 252, 0.7439999999999998 ],
    [ 3, 136, 252, 0.7359999999999998 ],
    [ 3, 136, 252, 0.7279999999999998 ],
    [ 3, 136, 252, 0.7199999999999998 ],
    [ 3, 136, 252, 0.7119999999999997 ],
    [ 3, 136, 252, 0.7039999999999997 ],
    [ 3, 136, 252, 0.6959999999999997 ],
    [ 3, 136, 252, 0.6879999999999997 ],
    [ 3, 136, 252, 0.6799999999999997 ],
    [ 3, 136, 252, 0.6719999999999997 ],
    [ 3, 136, 252, 0.6639999999999997 ],
    [ 3, 136, 252, 0.6559999999999997 ],
    [ 3, 136, 252, 0.6479999999999997 ],
    [ 3, 136, 252, 0.6399999999999997 ],
    [ 3, 136, 252, 0.6319999999999997 ],
    [ 3, 136, 252, 0.6239999999999997 ],
    [ 3, 136, 252, 0.6159999999999997 ],
    [ 3, 136, 252, 0.6079999999999997 ],
    [ 3, 136, 252, 0.5999999999999996 ],
    [ 3, 136, 252, 0.5919999999999996 ],
    [ 3, 136, 252, 0.5839999999999996 ],
    [ 3, 136, 252, 0.5759999999999996 ],
    [ 3, 136, 252, 0.5679999999999996 ],
    [ 3, 136, 252, 0.5599999999999996 ],
    [ 3, 136, 252, 0.5519999999999996 ],
    [ 3, 136, 252, 0.5439999999999996 ],
    [ 3, 136, 252, 0.5359999999999996 ],
    [ 3, 136, 252, 0.5279999999999996 ],
    [ 3, 136, 252, 0.5199999999999996 ],
    [ 3, 136, 252, 0.5119999999999996 ],
    [ 3, 136, 252, 0.5039999999999996 ],
    [ 3, 136, 252, 0.49599999999999955 ],
    [ 3, 136, 252, 0.48799999999999955 ],
    [ 3, 136, 252, 0.47999999999999954 ],
    [ 3, 136, 252, 0.47199999999999953 ],
    [ 3, 136, 252, 0.4639999999999995 ],
    [ 3, 136, 252, 0.4559999999999995 ],
    [ 3, 136, 252, 0.4479999999999995 ],
    [ 3, 136, 252, 0.4399999999999995 ],
    [ 3, 136, 252, 0.4319999999999995 ],
    [ 3, 136, 252, 0.4239999999999995 ],
    [ 3, 136, 252, 0.4159999999999995 ],
    [ 3, 136, 252, 0.4079999999999995 ],
    [ 3, 136, 252, 0.39999999999999947 ],
    [ 3, 136, 252, 0.39199999999999946 ],
    [ 3, 136, 252, 0.38399999999999945 ],
    [ 3, 136, 252, 0.37599999999999945 ],
    [ 3, 136, 252, 0.36799999999999944 ],
    [ 3, 136, 252, 0.35999999999999943 ],
    [ 3, 136, 252, 0.3519999999999994 ],
    [ 3, 136, 252, 0.3439999999999994 ],
    [ 3, 136, 252, 0.3359999999999994 ],
    [ 3, 136, 252, 0.3279999999999994 ],
    [ 3, 136, 252, 0.3199999999999994 ],
    [ 3, 136, 252, 0.3119999999999994 ],
    [ 3, 136, 252, 0.3039999999999994 ],
    [ 3, 136, 252, 0.2959999999999994 ],
    [ 3, 136, 252, 0.28799999999999937 ],
    [ 3, 136, 252, 0.27999999999999936 ],
    [ 3, 136, 252, 0.27199999999999935 ],
    [ 3, 136, 252, 0.26399999999999935 ],
    [ 3, 136, 252, 0.25599999999999934 ],
    [ 3, 136, 252, 0.24799999999999933 ],
    [ 3, 136, 252, 0.23999999999999932 ],
    [ 3, 136, 252, 0.23199999999999932 ],
    [ 3, 136, 252, 0.2239999999999993 ],
    [ 3, 136, 252, 0.2159999999999993 ],
    [ 3, 136, 252, 0.2079999999999993 ],
    [ 3, 136, 252, 0.20 ],
  ]

  export const greenColors = [ 
    [ 3, 252, 90, 1 ],
    [ 3, 252, 90, 0.992 ],
    [ 3, 252, 90, 0.984 ],
    [ 3, 252, 90, 0.976 ],
    [ 3, 252, 90, 0.968 ],
    [ 3, 252, 90, 0.96 ],
    [ 3, 252, 90, 0.952 ],
    [ 3, 252, 90, 0.944 ],
    [ 3, 252, 90, 0.9359999999999999 ],
    [ 3, 252, 90, 0.9279999999999999 ],
    [ 3, 252, 90, 0.9199999999999999 ],
    [ 3, 252, 90, 0.9119999999999999 ],
    [ 3, 252, 90, 0.9039999999999999 ],
    [ 3, 252, 90, 0.8959999999999999 ],
    [ 3, 252, 90, 0.8879999999999999 ],
    [ 3, 252, 90, 0.8799999999999999 ],
    [ 3, 252, 90, 0.8719999999999999 ],
    [ 3, 252, 90, 0.8639999999999999 ],
    [ 3, 252, 90, 0.8559999999999999 ],
    [ 3, 252, 90, 0.8479999999999999 ],
    [ 3, 252, 90, 0.8399999999999999 ],
    [ 3, 252, 90, 0.8319999999999999 ],
    [ 3, 252, 90, 0.8239999999999998 ],
    [ 3, 252, 90, 0.8159999999999998 ],
    [ 3, 252, 90, 0.8079999999999998 ],
    [ 3, 252, 90, 0.7999999999999998 ],
    [ 3, 252, 90, 0.7919999999999998 ],
    [ 3, 252, 90, 0.7839999999999998 ],
    [ 3, 252, 90, 0.7759999999999998 ],
    [ 3, 252, 90, 0.7679999999999998 ],
    [ 3, 252, 90, 0.7599999999999998 ],
    [ 3, 252, 90, 0.7519999999999998 ],
    [ 3, 252, 90, 0.7439999999999998 ],
    [ 3, 252, 90, 0.7359999999999998 ],
    [ 3, 252, 90, 0.7279999999999998 ],
    [ 3, 252, 90, 0.7199999999999998 ],
    [ 3, 252, 90, 0.7119999999999997 ],
    [ 3, 252, 90, 0.7039999999999997 ],
    [ 3, 252, 90, 0.6959999999999997 ],
    [ 3, 252, 90, 0.6879999999999997 ],
    [ 3, 252, 90, 0.6799999999999997 ],
    [ 3, 252, 90, 0.6719999999999997 ],
    [ 3, 252, 90, 0.6639999999999997 ],
    [ 3, 252, 90, 0.6559999999999997 ],
    [ 3, 252, 90, 0.6479999999999997 ],
    [ 3, 252, 90, 0.6399999999999997 ],
    [ 3, 252, 90, 0.6319999999999997 ],
    [ 3, 252, 90, 0.6239999999999997 ],
    [ 3, 252, 90, 0.6159999999999997 ],
    [ 3, 252, 90, 0.6079999999999997 ],
    [ 3, 252, 90, 0.5999999999999996 ],
    [ 3, 252, 90, 0.5919999999999996 ],
    [ 3, 252, 90, 0.5839999999999996 ],
    [ 3, 252, 90, 0.5759999999999996 ],
    [ 3, 252, 90, 0.5679999999999996 ],
    [ 3, 252, 90, 0.5599999999999996 ],
    [ 3, 252, 90, 0.5519999999999996 ],
    [ 3, 252, 90, 0.5439999999999996 ],
    [ 3, 252, 90, 0.5359999999999996 ],
    [ 3, 252, 90, 0.5279999999999996 ],
    [ 3, 252, 90, 0.5199999999999996 ],
    [ 3, 252, 90, 0.5119999999999996 ],
    [ 3, 252, 90, 0.5039999999999996 ],
    [ 3, 252, 90, 0.49599999999999955 ],
    [ 3, 252, 90, 0.48799999999999955 ],
    [ 3, 252, 90, 0.47999999999999954 ],
    [ 3, 252, 90, 0.47199999999999953 ],
    [ 3, 252, 90, 0.4639999999999995 ],
    [ 3, 252, 90, 0.4559999999999995 ],
    [ 3, 252, 90, 0.4479999999999995 ],
    [ 3, 252, 90, 0.4399999999999995 ],
    [ 3, 252, 90, 0.4319999999999995 ],
    [ 3, 252, 90, 0.4239999999999995 ],
    [ 3, 252, 90, 0.4159999999999995 ],
    [ 3, 252, 90, 0.4079999999999995 ],
    [ 3, 252, 90, 0.39999999999999947 ],
    [ 3, 252, 90, 0.39199999999999946 ],
    [ 3, 252, 90, 0.38399999999999945 ],
    [ 3, 252, 90, 0.37599999999999945 ],
    [ 3, 252, 90, 0.36799999999999944 ],
    [ 3, 252, 90, 0.35999999999999943 ],
    [ 3, 252, 90, 0.3519999999999994 ],
    [ 3, 252, 90, 0.3439999999999994 ],
    [ 3, 252, 90, 0.3359999999999994 ],
    [ 3, 252, 90, 0.3279999999999994 ],
    [ 3, 252, 90, 0.3199999999999994 ],
    [ 3, 252, 90, 0.3119999999999994 ],
    [ 3, 252, 90, 0.3039999999999994 ],
    [ 3, 252, 90, 0.2959999999999994 ],
    [ 3, 252, 90, 0.28799999999999937 ],
    [ 3, 252, 90, 0.27999999999999936 ],
    [ 3, 252, 90, 0.27199999999999935 ],
    [ 3, 252, 90, 0.26399999999999935 ],
    [ 3, 252, 90, 0.25599999999999934 ],
    [ 3, 252, 90, 0.24799999999999933 ],
    [ 3, 252, 90, 0.23999999999999932 ],
    [ 3, 252, 90, 0.23199999999999932 ],
    [ 3, 252, 90, 0.2239999999999993 ],
    [ 3, 252, 90, 0.2159999999999993 ],
    [ 3, 252, 90, 0.2079999999999993 ],
    [ 3, 252, 90, 0.20 ],
  ]