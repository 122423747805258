import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { makeStyles } from '@material-ui/core/styles'
import { metric_directionality_dict } from './metricMappings'
import './chart.scss'

// note - styled mode is active so you can use the scss in chart.scss to style

const useStyles = makeStyles((theme) => ({
    infoText: {
        fontSize: '0.8em',
        marginTop: '0.5rem',
        marginLeft: '2rem',
    }
  }))

const getNormalizedWholeMetrics = (valueMap) => {
    let dataMap = {}
    let percents = []
    for (let prop in valueMap) {
        let min = Math.min(...valueMap[prop])
        let max = Math.max(...valueMap[prop])
        let spread = Math.abs(max - min)
        valueMap[prop].forEach(dat => {
            let percentile = (dat - min) / spread
            percents.push(percentile)
        })
        dataMap[prop] = valueMap[prop].map(dat => {
            return (dat - min) / spread
        })
    }

    let scoreSums = []
    for (let met in dataMap) {

        dataMap[met].forEach((dat, idx) => {
            // DIRECTIONALITY
            if (metric_directionality_dict[met]) {
                if (metric_directionality_dict[met] === -1) {
                    if (!scoreSums[idx]) scoreSums[idx] = 0
                    scoreSums[idx] += 1 + (dat * -1)
                } else {
                    if (!scoreSums[idx]) scoreSums[idx] = 0
                    scoreSums[idx] += dat
                }
            } else {
                if (!scoreSums[idx]) scoreSums[idx] = 0
                scoreSums[idx] += dat
            }
        })
    }

    let scoremin = Math.min(...scoreSums)
    let scoremax = Math.max(...scoreSums)
    let scorespread = scoremax - scoremin
    let normalizedMetrics = scoreSums.map(dat => {
        return (dat - scoremin) / scorespread
    })

    return normalizedMetrics
}

const calculatePeerGap = (cities, outcomeMetrics, newData) => {
    let cityWholeValueMetrics = {}
    // console.log('cities: ', cities)
    // console.log('metrics: ', outcomeMetrics)

    cities.forEach(city => {
        let cityArr = city.split(", ")
        let cityType = cityArr.pop()
        let cityName = cityArr.join(", ")
        outcomeMetrics.forEach(metric => {
            try {
                let targetRow = newData.find(row => {
                    return row[0] === cityName && row[2] == cityType && row[5] === metric && row[3] === 'Total'
                })
                if (targetRow[9] !== "") {
                    let dataValue = Number(targetRow[9])
                    if (!cityWholeValueMetrics[metric]) cityWholeValueMetrics[metric] = []
                    cityWholeValueMetrics[metric].push(dataValue)
                }
            } catch (err) {
                // console.log('my err: ', err)
                // console.log('missing value. ignore.')
            }
        })
    })
    
    // normalize whole value metrics to a 0.0 - 1.0 scale and combine them with the percentages
    let normalizedVals = getNormalizedWholeMetrics(cityWholeValueMetrics)
    normalizedVals = normalizedVals.filter(val => !isNaN(val))

    return normalizedVals
}

const calculateEquityGap = (cities, outcomeMetrics, equityMetric, newData) => {
    let equityValues = []
    let compMap = {
        'Black': 'Black or African American',
        'Hispanic': 'Hispanic or Latino',
    }

    cities.forEach(city => {
        let cityMetrics = []
        let cityArr = city.split(", ")
        let cityType = cityArr.pop()
        let cityName = cityArr.join(", ")
        let tmpmets = []
        outcomeMetrics.forEach(metric => {
            let targetComp = compMap[equityMetric.split(' / ')[1]]
            

            try {
                let targetRow1 = newData.find(row => {
                    return row[0] === cityName && row[2] == cityType && row[5] === metric && row[3] === 'White Alone, Not Hispanic or Latino'
                })
                let dataValue1 = Number(targetRow1[9])

                let targetRow2 = newData.find(row => {
                    return row[0] === cityName && row[2] == cityType && row[5] === metric && row[3] === targetComp
                })
                let dataValue2 = Number(targetRow2[9])

                if (metric_directionality_dict[metric] && metric_directionality_dict[metric] == -1) {
                    cityMetrics.push(dataValue2 / dataValue1)
                } else {
                    cityMetrics.push(dataValue1 / dataValue2)
                }
                tmpmets.push(metric)
            } catch (err) {
                // console.log('missing value. ignore') 
            }
            // calulcate the ratio after grabbing the values from the data
        })

        let sum = cityMetrics.reduce((a, b) => {
            return a + b
        }, 0)
        let avg = sum / cityMetrics.length
        equityValues.push(avg)
    })
    return equityValues
}

const getOutcomesValuesPercentiles = (cities, outcome, excludeList, equityMetric, newData, metricMappings) => {

    // get all metric that comprise the outcome
    let outcomeMetrics = metricMappings.outcomes[outcome]
    try {
        outcomeMetrics = outcomeMetrics.filter(met => {
            // remove excluded metrics!
            return excludeList.indexOf(met) === -1
        })
    } catch (err) {
        console.log(err)
    }

    let peerGapPercentiles = calculatePeerGap(cities, outcomeMetrics, newData)
    // console.log('this outcome: ', outcome)
    // console.log('peer gap percentiles!: ', peerGapPercentiles)
    
    let equityGapValues = calculateEquityGap(cities, outcomeMetrics, equityMetric, newData)
    // console.log('equity gap values!: ', equityGapValues)


    let valsPercents = []
    valsPercents.push({
        x: peerGapPercentiles[0],
        y: equityGapValues[0],
        name: outcome
    })
    // console.log('vals percents????', valsPercents)
    return valsPercents
}

// will show the outcomes that comprise a given system
const getSystemsValuesPercentiles = (cities, equityMetric, excludeList, newData, metricMappings) => {
    // start with all systems (imported from definitions)
    let fullSystemsList = Object.keys(metricMappings.systems)
    fullSystemsList = fullSystemsList.filter(sys => {
        // remove excluded systems!
        return excludeList.indexOf(sys) === -1
    })
    
    // get outcome subitems for each system
    let systemOutcomes = {}
    fullSystemsList.forEach(sys => {
        let tmp = metricMappings.systems[sys]
        // remove excluded outcomes!
        tmp = tmp.filter(out => excludeList.indexOf(out) === -1)
        systemOutcomes[sys] = tmp
    })
    
    // calculate each outcome and then plot
    let allSystems = {}
    Object.keys(systemOutcomes).forEach((system) => {
        allSystems[system] = []
        systemOutcomes[system].forEach(outcome => {
            let outVal = getOutcomesValuesPercentiles(cities, outcome, excludeList, equityMetric, newData, metricMappings)
            allSystems[system].push(outVal[0])
        })
    })

    return allSystems
}

 
const GapChart = ({ state, dispatch, newData, metricMappings }) => {
    const classes = useStyles()
    const [options, updateChart] = useState({
        chart: {
            height: 580,
            backgroundColor: 'green'
        },
        tooltip: { 
            enabled: false, 	
            formatter: function() {
                      var tooltip = '<br>Peer percentile: ' + Math.floor(Number(this.x) * 100) +'<br>Equity gap ratio: ' + (Number(this.y)).toFixed(1)
    
                      return tooltip
                  },
               shared : true,
               positioner: function (labelWidth, labelHeight, point) {
                var tooltipX = point.plotX - 100;
                var tooltipY = point.plotY + 10;
                return {
                    x: tooltipX,
                    y: tooltipY
                };
                },
            
          },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '<div>{point.name}</div>',
                    useHTML: true
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            dispatch({
                                type: 'selectDetails',
                                payload: this.name
                            })
                        }
                    }
                }
            }
        },
        title: {
            text: '',
        },
        xAxis: {
            min: 0,
            lineWidth: 2,
            title: {
                text: 'Peer comparison',
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                }
            },
            showEmpty: true,
            labels: {
                formatter: function () {
                    return (this.value * 100).toFixed(0) + 'th'
                }
            }
        },
        yAxis: {
            min: 0,
            lineWidth: 2,
            title: {
                text: 'Inequity ratio',
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                }
            },
            gridLineWidth: 0,
            gridLineColor: 'transparent',
            minorGridLineWidth: 0,
            tickmarkPlacement: 'off',
            tickWidth: 0,
        },
        credits: {
            enabled: false
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            enabled: false,
            x: 0,
            y: 0,
            title: {
                text: '<span style="font-size: 16px; font-weight: bold;">Legend</span>'
            }
        },
        series: [{
            type: 'scatter',
            name: 'Observations',
            data: [],
            showEmpty: true,
            marker: {
                radius: 18,
                lineWidth: 6,
            },
            styledMode: true,
        }]
    })

    const processStateChange = (newState) => {
        // don't do anything if missing required values
        if (newState.city.length < 1 || newState.comparables.length < 1 || newState.metric.length < 1) return

        let oldState = {...options}

        let cities = [newState.city, ...newState.comparables]

        // basically, we plan to grab the data that's listed under each system, and then we
        // use the excludeList to find the exceptions to that rule. for system/outcome, we fail to plot.
        // for metric, we alter the outcome by taking out hte variable.

        let valuesAndPercentiles = getSystemsValuesPercentiles(cities, newState.metric, newState.excludeList, newData, metricMappings)
        // console.log('values and percentiles: ', valuesAndPercentiles)

        oldState.series = Object.keys(valuesAndPercentiles).map(sys => { 
            return {
                type: 'scatter',
                name: sys,
                dataLabels: {
                    y: -20,
                    style: {
                        fontSize: '20px'
                    }
                },
                data: valuesAndPercentiles[sys],
                marker: {
                    radius: 16,
                    lineWidth: 0,
                },
                styledMode: true,
            }
        })
        oldState.legend.enabled = true
        oldState.xAxis.max = 1
        //update state
        // console.log('updated: ', oldState)
        updateChart(oldState)
    }

    useEffect(() => {
        processStateChange(state)
    }, [state])

    return (
        <div>
            {state.city.length === 0 && <h1 className='animate__animated animate__pulse animate__infinite' style={{textAlign: 'center', color: 'rgb(1, 42, 95)', fontFamily: 'Roboto', height: 20}}>Select a geographic focus to explore its equity and peer gaps</h1>}
            {(state.city.length > 0 && state.comparables.length === 0) && <h1 className='animate__animated animate__pulse animate__infinite' style={{textAlign: 'center', color: 'rgb(1, 42, 95)', fontFamily: 'Roboto', height: 20}}>Select comparable jurisdictions to form a peer set</h1>}
            {(state.city.length > 0 && state.metric === '' && state.comparables.length > 0) && <h1 className='animate__animated animate__pulse animate__infinite' style={{textAlign: 'center', color: 'rgb(1, 42, 95)', fontFamily: 'Roboto', height: 20}}>Select demographic groups to compare their outcomes</h1>}
            {(state.city.length > 0 && state.metric !== '' && state.comparables.length > 0 && state.details === '') && <h1 className='animate__animated animate__pulse animate__infinite' style={{textAlign: 'center', color: 'rgb(1, 42, 95)', fontFamily: 'Roboto', height: 20}}>Click on an outcome bubble to see details</h1>}
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <p className={classes.infoText}>*The inequality ratio (Y axis) represents the difference in the magnitude of the selected variable between the two demographic groups selected.</p>
            <p className={classes.infoText}>*The peer comparison (X axis) represents how the selected geographic area performs relative to its selected peer set.</p>
        </div>
    )
}

export default GapChart