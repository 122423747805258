import React from 'react'
import uuid from 'react-uuid'
import { getLowHighValues } from '../../helpers/geo_categories'

const CalcLabel = ({multiMetric, dcGeo, selectedMetric, aggregate, percentChange, legend}) => {
  
    let gradients = ['rgb(247, 243, 0, 1)', 'rgb(99, 20, 130, 1)']
    if (legend === 'yellowPurpleColors') gradients = ['rgb(247, 243, 0, 1)', 'rgb(99, 20, 130, 1)']
    if (legend === 'redGreenColors') gradients = [ 'rgb(3, 252, 61, 1)', 'rgb(252, 3, 11, 1)',]
    if (legend === 'blueGreenColors') gradients = ['rgb(13, 122, 224, 1)', 'rgb(12, 204, 63, 1)', ]
    if (legend === 'orangeColors') gradients = ['rgba(255, 145, 0, .01)', 'rgba(255, 145, 100, 1)', ]
    if (legend === 'purpleColors') gradients = ['rgba(173, 3, 252, .01)', 'rgba(173, 3, 252, 1)', ]
    if (legend === 'blueColors') gradients = ['rgba(3, 136, 252, .01)', 'rgba(3, 136, 252, 1)', ]
    if (legend === 'greenColors') gradients = ['rgba(3, 252, 90, .01)', 'rgba(3, 252, 90, 1)', ]


    if (multiMetric && multiMetric.length === 0) {
      if (!selectedMetric) return null

      let [low, high] = getLowHighValues(selectedMetric, dcGeo.features, aggregate, percentChange)
      const metric = percentChange ? selectedMetric[0] : selectedMetric
      const percentString = percentChange ? "%" : ""

      if (metric.slice(0,3) === 'P00') {
        return (
          <>
            <p>{Number.parseFloat(low * 100).toFixed(2)}%</p>
            <span style={{height: 10, width: 300, marginLeft: '0.5em', marginRight: '0.5em', background: `linear-gradient(to right, ${gradients[0]}, ${gradients[1]} )`}}></span>
            <p>{Number.parseFloat(high * 100).toFixed(2)}%</p>
          </>
        )
      } else if (metric.slice(0,3) === 'pct') {
        return (
          <>
            <p>{Number.parseFloat(low * 100).toFixed(2)}%</p>
            <span style={{height: 10, width: 300, marginLeft: '0.5em', marginRight: '0.5em', background: `linear-gradient(to right, ${gradients[0]}, ${gradients[1]} )`}}></span>
            <p>{Number.parseFloat(high * 100).toFixed(2)}%</p>
          </>
        )
      } else if (metric === 'median_hh_income') {
          return (
            <>
              {/* <p>${Number.parseFloat(low).toFixed(0)}</p> */}
              <p>${Math.round(low).toLocaleString()}</p>
              <span style={{height: 10, width: 300, marginLeft: '0.5em', marginRight: '0.5em', background: `linear-gradient(to right, ${gradients[1]}, ${gradients[0]})`}}></span>
              <p>${Math.round(high).toLocaleString()}</p>
              {/* <p>${Number.parseFloat(high).toFixed(0)}</p> */}
            </>
          )
      } else if (metric.slice(0,4) === 'foot') {
        return (
          <>
            <p>{Math.round(low).toLocaleString('en') + percentString}</p>
            <span style={{height: 10, width: 300, marginLeft: '0.5em', marginRight: '0.5em', background: `linear-gradient(to right, ${gradients[1]}, ${gradients[0]})`}}></span>
            <p>{Math.round(high).toLocaleString('en') + percentString}</p>
          </>
        )
    } else {
        return(
          <>
            <p>{Number.parseFloat(low).toFixed(0)}</p>
            <span style={{height: 10, width: 300, marginLeft: '0.5em', marginRight: '0.5em', background: `linear-gradient(to right, ${gradients[0]}, ${gradients[1]})`}}></span>
            <p>{Number.parseFloat(high).toFixed(0)}</p>
          </>
        )
      }
    } else {
      if (multiMetric === null) return null
      let renderLabels = []
      // console.log('MULTIMETRIC: ', multiMetric)
      multiMetric.forEach(met => {
        let low = 9999999999
        let high = -9999999999
        if (dcGeo.features) {
          dcGeo.features.forEach((row, index) => {
            try {
              if (row) {
                let metric = Number(row['properties'][met.value])
                if (metric !== -1) {

                  if (metric < low) low = metric
                  if (metric > high) high = metric
                }
              }
            } catch (err) {
              console.log('label err: ', err)
            }
          })
        } 
        // console.log('the met: ', met)
        let highWorse = met.highIsWorse
        if (highWorse) {
          renderLabels.push({
            label: met.label,
            low: low,
            high: high,
            type: 'pct',
          })
        } else {
          renderLabels.push({
            label: met.label,
            low: high,
            high: low,
            type: 'count',
          })
        }
      })
      
      if (!renderLabels) return
      return (
        <>
          <div style={{padding: '0.5em'}}>
            <p style={{textAlign: 'left', fontWeight: 600, marginBottom: '0.5em'}}>Best performing</p>
            {renderLabels.map(rend => {
              if (rend.type === 'pct') return <p key={uuid()}>{rend.label}: <span style={{fontWeight: 600}}>{(rend.low * 100).toFixed(2)}%</span></p>
              if (rend.type === 'count') return <p key={uuid()}>{rend.label}: <span style={{fontWeight: 600}}>{Math.round(rend.low).toLocaleString('en')}</span></p>
            })}
          </div>
          <span style={{height: 10, width: 300, background: `linear-gradient(to right, ${gradients[0]}, ${gradients[1]})`}}></span>
          <div style={{padding: '0.5em'}}>
            <p style={{textAlign: 'left', fontWeight: 600, marginBottom: '0.5em'}}>Worst performing</p>
            {renderLabels.map(rend => {
              if (rend.type === 'pct') return <p key={uuid()}>{rend.label}: <span style={{fontWeight: 600}}>{(rend.high * 100).toFixed(2)}%</span></p>
              if (rend.type === 'count') return <p key={uuid()}>{rend.label}: <span style={{fontWeight: 600}}>{Math.round(rend.high).toLocaleString('en')}</span></p>
            })}
          </div>
        </>
      )
    }
}

export default CalcLabel