export const generateMetricMappings = (mappingResults) => {
    const metricMappings = {
        systems: {},
        outcomes: {},
        metrics: {}
    }
    mappingResults.data.forEach((mapping, ind) => {
        if (ind !== 0 && mapping[1] && mapping[2] && mapping[3]) {
            if (!metricMappings.systems[mapping[2]]) metricMappings.systems[mapping[2]] = new Set()
            metricMappings.systems[mapping[2]].add(mapping[3])
            if (!metricMappings.outcomes[mapping[3]]) metricMappings.outcomes[mapping[3]] = new Set()
            metricMappings.outcomes[mapping[3]].add(mapping[1])
            if (!metricMappings.metrics[mapping[1]]) metricMappings.metrics[mapping[1]] = []
        }
    });
    let systems = Object.keys(metricMappings.systems), outcomes = Object.keys(metricMappings.outcomes)
    systems.forEach((system) => {
        metricMappings.systems[system] = Array.from(metricMappings.systems[system])
    })
    outcomes.forEach((outcome) => {
        metricMappings.outcomes[outcome] = Array.from(metricMappings.outcomes[outcome])
    })
    return metricMappings
}

export const metric_directionality_dict = {
    'Pop within 1/2mile of park (%)': 1,
    'Median home value ($)': 1,
    'Home ownership (%)': 1,
    'Homes with a second mortgage, home equity loan, or both (%)': -1,
    'Vacancy (%)': -1,
    'Median household income ($)': 1,
    'Poverty (%)': -1,
    'Pop beyond 1mile of supermarket (%)': -1,
    'Pop beyond 1mile of supermarket (%): White': -1,
    'Pop beyond 1mile of supermarket (%): Black or African American': -1,
    'Pop beyond 1mile of supermarket (%): Asian': -1,
    'Pop beyond 1mile of supermarket (%): Hispanic or Latino': -1,
    'Households without a car (%)': -1,
    'Commuting 45+ min (%)': -1,
    'Overcrowded homes (% 1+ occupants/room)': -1,
    'Rent burden (% >30% income on rent)': -1,
    'Share without broadband (%)': -1,
    'Pre-K enrollment (%)': 1,
    'Pop w/ bachelors or above (%)': 1,
    'Pop w/o high school degree (%)': -1,
    'Uninsured population (%)': -1,
    'Binge drinking (% adults)': -1,
    'Mental health not good for >13 days (% adults)': -1,
    'Obesity (% adults)': -1,
    'Physical health not good for >13 days (% adults)': -1,
    'Current smokers (% adults)': -1,
    'Diabetes (% adults)': -1,
    'Mammography use (% women age 50-74)': 1,
    'SNAP recipients (% households)': -1,
    'Life expectancy': 1,
    'Workers in management roles (%)': 1,
    'Unemployment (%)': -1,
    'Workers in service occupations (%)': -1,
    'Labor Force participation (%)': 1,
    'Child poverty (%)': -1,
    'Share of income in top 5% (%)': -1,
    'Other crime incidents per 100k': -1,
    '"Serious" crime incidents per 100k': -1,
    'Other crime victims per 100k': -1,
    '"Serious" crime victims per 100k': -1,
    'Jail incarceration rate (%)': -1,
    'Prison incarceration rate (%)': -1,
    'Mortgage Rejection Rate (%)': -1,
    'Revenue per firm ($)': 1,
    'Small businesses per 1000': 1,
    '4th grade math below "basic" level (%)': -1,
    '4th grade reading below "basic" level (%)': -1,
    '8th grade math below "basic" level (%)': -1,
    '8th grade reading below "basic" level (%)': -1,
    'Homelessness per 10k': -1,
    'Covid cases per 10k': -1,
    'Police stops per 10k': -1,
    'Share of firms with employees (%)': 1,
    'Unbanked (%)': -1,
    'Incarceration rate (%)': -1,
    'Share beyond 1mile of higher education (%)': -1,
    'Pop beyond 1mile of library (%)': -1,
    'Bank branches per 1000': 1,
    'Children in single parent households (%)': -1,
    'Infant mortality rate': -1,
    'Adult income percentile if child household below 25th percentile': 1,
    'Adult income percentile if child household below 25th percentile: Black or African American': 1,
    'Adult income percentile if child household below 25th percentile: Hispanic or Latino': 1,
    'Adult income percentile if child household below 25th percentile: White': 1,
}