import React from 'react'
import PropType from 'prop-types';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import uuid from "react-uuid"
import finlabPNG from "../../assets/images/FinLab.png";

const Header = styled.header`
    background-color:  ${({ theme }) => theme.colors.white};
    padding: .25rem 1rem;
    border-bottom: 1px solid lightgrey;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
        height: 100%;
        width: fit-content;
    }
`

const Routes = styled.div`
  display: flex;
  justify-content: flex-end;
  .route-container {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 200px;
    width: fit-content;
    margin-right: 2rem;
  }
`;

const Route = styled.p`
    font-family: ${({ theme }) => theme.fonts.header};
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    border-bottom: 3px solid ${({ theme }) => theme.colors.white};
    font-size: 1.25rem;
    font-weight: 300;
    &:hover {
        color: ${({ theme }) => theme.colors.accent};
        border-bottom: 3px solid ${({ theme }) => theme.colors.accent};
    }`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  img {
    height: 60px;
    margin-right: 1em;
  }

  p {
    font-size: 24px;
    //font-family: Montserrat;
    line-height: 125%;
  }

  .small {
    font-size: 18px;
  }
`;


const Navigation = ({ routes }) => {

  return (
    <Header>
      <Logo onClick={() => window.location.reload()}>
        <img alt='finlab' src={finlabPNG} />
        <p style={{display: 'flex', jusifyContent: 'center', alignItems: 'center', fontSize: '2rem', marginTop: 0, marginBottom: 0, color: '#012a5f', fontFamily: 'Orbitron', fontWeight: 700, cursor: 'pointer'}}>CityX</p>
      </Logo>

      <div style={{ display: `flex` }}>
        <Routes>
          {
            routes.map(route => (
              <div key={uuid()} className="route-container">
                <NavLink to={route.route} style={{ textDecoration: `none` }}>
                  <Route>{route.label}</Route>
                </NavLink>
              </div>
            ))
          }
        </Routes>
      </div>
    </Header>
  )
}


Navigation.propTypes = {
  routes: PropType.arrayOf(PropType.object).isRequired
};

export default Navigation;
