import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledFilterGroup = styled.div`
        display: flex;
        flex-direction: column;
        color: ${({ theme }) => theme.colors.darkGray};
        margin-top: ${({ theme }) => theme.spacers.lg};
        margin-bottom: ${({ theme }) => theme.spacers.lg};
        .single-filter {
        margin-top: ${({ theme }) => theme.spacers.md};
        margin-bottom: ${({ theme }) => theme.spacers.md};
        &:first-child {
            margin-top: 0rem;
        }
        }
        &:first-child {
            margin-top: 0rem;
        }
        .collapsed {
            text-align: center;
            width: 100%;
            height: 30px;
            margin-top: ${({ theme }) => theme.spacers.lg};
            font-size: ${({ theme }) => theme.fonts.sizes.lg};
            cursor: pointer;
            &:hover {
                color: ${({ theme }) => theme.colors.accent};
            }
        }

        .filter-label {
            display: flex;
            font-size: ${({ theme }) => theme.fonts.sizes.lg};
            p {
                font-family: ${({ theme }) => theme.fonts.header};
                font-weight: 500;
                margin-left: ${({ theme }) => theme.spacers.md}
            }
        }

        .filter-group-container {
            // margin-top: ${({ theme }) => theme.spacers.md};
            margin-top: 0;
            display: flex;
            flex-direction: column;
            //padding-left: ${({ theme }) => theme.spacers.lg};
            //padding-top: ${({ theme }) => theme.spacers.md};
            .overrides {
                margin-bottom: 1.5rem;
            }
        }

        .title {
            margin-bottom:${({ theme }) => theme.spacers.md};
            font-weight: bold;
            font-size: ${({ theme }) => theme.fonts.sizes.md}
        }
        .sub-title { 
            margin-bottom: 2rem;
        }
        .heading {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .blue {
          color: #0000FF;
      }
    `
const FilterGroup = ({ icon, title, collapsed, children }) => {

    return (
        <StyledFilterGroup>
            <>
                <div className="filter-label">
                    {icon && <FontAwesomeIcon icon={icon ? icon : null} />} 
                    <p>{title}</p>
                </div>
                <div className="filter-group-container">
                    {children}
                </div>
            </>
        </StyledFilterGroup>
    )
}


FilterGroup.propTypes = {};


export default FilterGroup;