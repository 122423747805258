import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStore from "./context/GlobalStore"

import "./index.scss";
import "./assets/fonts/Bower-Bold.otf";
import "./assets/fonts/TheinhardtPan-Light.otf";
import "./assets/fonts/TheinhardtPan-MediumItalic.otf";
import "./assets/fonts/TheinhardtPan-Regular.otf";

const WrappedApp = () => (
  <GlobalStore>
    <App />
  </GlobalStore>
)

ReactDOM.render(
  <WrappedApp />
  ,
  document.getElementById('root')
);

